import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/style.scss";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SnackbarProvider } from "./context/SnackbarContext";
import AxiosInterceptor from "./network/AxiosInterceptor";
import { CustomDialogProvider } from "./context/CustomDialogContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <UserProvider>
    <Provider store={store}>
      <SnackbarProvider>
        <CustomDialogProvider>
          <AxiosInterceptor>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AxiosInterceptor>
        </CustomDialogProvider>
      </SnackbarProvider>
    </Provider>
  </UserProvider>
  // </React.StrictMode>
);
