import React, { FC, ReactNode } from 'react';

interface TabPanelProps {
  index: number;
  selectedTab: number;
  children: ReactNode;
}

const TabPanel = ({ index, selectedTab, children }: TabPanelProps) => {
  return selectedTab === index ? <div>{children}</div> : null;
};

export default React.memo(TabPanel);