import { AgreementType } from "../constants/case-constants";
import { CaseAgreement } from "../redux/slice/components/initCaseSliceState";
import { DetailedFacts } from "./caseDocument/detailedFacts";
import { Grounds } from "./caseDocument/grounds";
import { Prayer } from "./caseDocument/prayer";
import { Evidence, RejectStatus, SynopsisModel } from "./caseDocument/synopsis";

export interface Claimant {
  claimantType: string;
  name: string;
  orgRepresentative: string;
  designation: string;
  authorizationLetter: Evidence | null ;
  eAadhaar: Evidence | null ;
  mobileNo: string;
  email: string;
  address: string;
  isPrimary: number;
  hasChanges: boolean; //using to check scrutiny
  resolved: boolean; // resolving scrutiny rejection

  rejected: RejectStatus; // Approve or REJECTED
  reason: string;
  resolution: string;
  errors: ClaimantErrorModel;
}

export interface ClaimantErrorModel {
  claimantType: string;
  name: string;
  orgRepresentative: string;
  designation: string;
  authorizationLetter: string;
  eAadhaar: string;
  mobileNo: string;
  email: string;
  address: string;
}
interface RespondentErrorModel {
  respondentType: string;
  name: string;
  orgRepresentative: string;
  designation: string;
  authorizationLetter: string;
  eAadhaar: string;
  mobileNo: string;
  email: string;
  address: string;
}
export interface Respondent {
  respondentType: string;
  name: string;
  orgRepresentative: string;
  designation: string;
  authorizationLetter: string|null;
  eAadhaar: string|null;
  mobileNo: string;
  email: string;
  address: string;
  hasChanges: boolean;
  rejected: RejectStatus;
  reason: string;
  resolution: string;
  resolved: boolean;
  errors: RespondentErrorModel;
}


interface VakalatnamaType {
  vakalatnamaDoc:Evidence|null;
  verification:number;
  errors:null|string;
}


export interface AgreementDocument {
  docName: string;
  actualFileName: string;
  uploadedFileName: string;
  mimeType: string;
  reUpload: number;
  agreementType: number;
}


export interface CaseFile {
  uniqueId: string | null;
  caseId: string;
  caseTitle: string;
  caseCategory: string;
  caseType: string;
  caseJurisdiction: string;
  caseSubject: string;

  resolutionMethod: string;
  resolutionAgreementStatus: number;
  resolutionAgreementVerified: number;
  tempResolutionAgreementVerified: number;
  resolutionAgreementRemark: string;
  tempresolutionAgreementRemark: string;
  resolutionAgreement: Evidence | null;
  deletedFiles: string[];
  resolutionType: string;

  vakalatnama: VakalatnamaType | null;
  case_agreement: AgreementDocument[] | null;

  caseAgreementList: CaseAgreement[] | null;

  representationType: string;
  lawFirmName: string;
  advocateName: string;
  advocateMobileNo: string;
  advocateEmail: string;
  errors: CaseFileErrorModel;

  showReuploadDialog: boolean;

  finalVerdict: string | null;
  finalVerdictFinalized: number;
  status: string;
}

export type CaseFileErrorModel = {
  resolutionMethod: string;
  lawFirmName: string;
  representationType: string;
  advocateName: string;
  advocateMobileNo: string;
  advocateEmail: string;
  vakalatnamaError:string;
  affidavitDocError:string;
  concentFormDocError:string;

};

export interface ReasoningProps {
  id: string;
  reasoning: string;
}

export interface ActivityLogs {
  role: number;
  date: string;
  userName: string;
  visibility: string;
  uniqueId:string;
  title: string;
  details: string;
  activityType: string;
  profilePic:string;
  createdAt: string;
}

export interface CaseFileResponseDetail {
  synopsis: SynopsisModel[];
  grounds: Grounds[];
  detailedFacts: DetailedFacts;
  prayers: Prayer[];
  previousSynopsis: SynopsisModel[];
  previousGrounds: Grounds[];
  previousDetailedFacts: DetailedFacts;
}
export interface CaseFileResponse {
  uniqueId: string | null;
  representationType: string;
  lawFirmName: string;
  advocateName: string;
  advocateMobileNo: string;
  agreeResolutionStatus: number;
  resolutionMethodRemark: string;
  advocateEmail: string;
  errors: CaseFileErrorModel;
  status: string;
}

export enum StatusType {
  INFO,
  SUCCESS,
  WARNING,
  DANGER,
}
