import { SUCCESS_RESPONSE } from "../constants/api-constants";
import AxiosHelper from "./AxiosHelper";

const resetPassword = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "user/reset-password",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const changePassword = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "user/change-password",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const profileDetail = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "user/profile-detail",
      queryParams: null,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const updateProfile = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "user/update-profile",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const updateContextType = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "user/set-user-context-type",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const updateOrganizationDetail = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "organization/update-detail",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const inviteMember = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "organization/invite-member",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const getInviteMemberList = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "organization/list-invited-members",
      queryParams: null,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiInitEmailVerification = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "user/init-email-verification",
      queryParams: null,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const apiVerifyEmail = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/verify-email",
      queryParams: null,
      body: body,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : "");
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiUserKyc = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `user/kyc`, body })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const apiGetUserKyc = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "user/kyc",
      queryParams: null,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiUserList = (reqParam: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "user/list",
      queryParams: reqParam,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiUserDetails = (uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: "user/detail/" + uniqueId,
      queryParams: null,
    })
      .then((res: any) => {
        if (res.status === SUCCESS_RESPONSE) {
          resolve(res.data ? res.data : false);
        } else reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiUserKycVerify = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `user/kyc-status-update`, body })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};
const switchUserContext = (body: any) => {
  const { uniqueId } = body;
  

  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({
      path: `user/switch-context`,
      queryParams: { uniqueId: uniqueId },
    })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiOrganizationList = (params = null) => {
    return new Promise((resolve, reject) => {
        AxiosHelper.httpGet({
            path: "organization/list",
            queryParams: params,
        })
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    resolve(res.data ? res : false);
                } else reject(res.description);
            })
            .catch((e) => reject(e));
    });
};

const apiOrgDetails = (uniqueId: string) => {
    return new Promise((resolve, reject) => {
        AxiosHelper.httpGet({
            path: "organization/detail/" + uniqueId,
            queryParams: null,
        })
            .then((res: any) => {
                if (res.status === SUCCESS_RESPONSE) {
                    resolve(res.data ? res.data : false);
                } else reject(res.description);
            })
            .catch((e) => reject(e));
    });
};

const apiOrgKycVerify = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `organization/kyc-status-update`, body })
      .then((res: any) => {
        res.isSuccess ? resolve(res.data) : reject(res.description);
      })
      .catch((e) => reject(e));
  });
};

const apiDashboard = (params = null) => {
  return new Promise((resolve, reject) => {
      AxiosHelper.httpGet({
          path: "user/dashboard",
          queryParams: params,
      })
          .then((res: any) => {
              if (res.status === SUCCESS_RESPONSE) {
                  resolve(res.data ? res.data : false);
              } else reject(res.description);
          })
          .catch((e) => reject(e));
  });
};

const UserService = {
    resetPassword,
    changePassword,
    profileDetail,
    updateProfile,
    updateContextType,
    updateOrganizationDetail,
    inviteMember,
    getInviteMemberList,
    apiInitEmailVerification,
    apiVerifyEmail,
    apiUserKyc,
    apiGetUserKyc,
    apiUserList,
    apiUserDetails,
    apiUserKycVerify,
    switchUserContext,
    apiOrganizationList,
    apiOrgDetails,
    apiOrgKycVerify,
    apiDashboard,
};

export default UserService;
