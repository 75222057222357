import React, { useState } from 'react'
import ConditionalWrapper from '../../../../Layout/ConditionalWrapper';
import { Accordion, AccordionSummary, Box, Button, IconButton, Slider, TextField, Tooltip } from '@mui/material';
import { CounterPrayerModel, PrayerModel } from '../../../../model/caseDocument/prayer';
import { editPrayers, setCounterPrayersExpanded } from '../../../../redux/slice/caseResponseSlice';
import AcceptRejectButtons from '../../../case/components/MethodOfResolution/components/AcceptRejectButtons';
import { useDispatch } from 'react-redux';
import { AppDispatch, store } from '../../../../redux/store';
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from '@mui/icons-material/Cancel';
import { MuiInputFieldBorderStyle, MuiTextFieldBorderStyle } from "../../../../util/mui-helper";
import PrayersRow from '../prayers/PrayersRow';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";





interface ResponseToConterPrayerProps {
    counterPrayerList: CounterPrayerModel[],
    setDataToSend:any,
}

const ResponseToConterPrayer = (
    { counterPrayerList, setDataToSend }: ResponseToConterPrayerProps
) => {


    
    const dispatch: AppDispatch = useDispatch();

    const marks = [
        {
            label: "Low",
            value: 0,
        },

        {
            label: "Medium",
            value: 5,
        },

        {
            label: "High",
            value: 10,
        },
    ];
    function valuetext(value: number) {
        return `${value}`;
    }
    const marksForPercentage = [
        {
            value: 0,
            label: "0%",
        },
        {
            value: 1,
        },
        {
            value: 2,
        },
        {
            value: 3,
        },
        {
            value: 4,
        },
        {
            value: 5,
            label: "50%",
        },
        {
            value: 6,
        },
        {
            value: 7,
        },
        {
            value: 8,
        },
        {
            value: 9,
        },
        {
            value: 10,
            label: "100%",
        },
    ];
    function Percentage_valuetext(value: number) {
        return `${value * 10}%`
    }




    interface AcceptRejectButtonsProps {
        status: number;
        onAccept: () => void;
        onReject: () => void;
        onReset: () => void;
    }

    const AcceptRejectButtons = ({
        status,
        onAccept,
        onReject,
        onReset,
    }: AcceptRejectButtonsProps) => {
        return (
            <div className="d-flex gap-3">
                <Button
                    variant={"outlined"}
                    className={`${status === 1 ? " text-success" : ""}`}
                    startIcon={<DoneIcon />}
                    disabled={status === 1 || status === 2}
                    sx={{ textTransform: "none" }}
                    onClick={onAccept}
                >
                    {status === 1 ? "Accepted" : "Accept"}
                </Button>

                <Button
                    className={`${status === 2 ? " text-danger" : ""}`}
                    startIcon={<CancelIcon />}
                    variant={"outlined"}
                    disabled={status === 1}
                    sx={{ textTransform: "none" }}
                    onClick={onReject}
                >
                    {status === 2 ? "Rejected" : "Reject"}
                </Button>

                {(status === 1 || status === 2) && (
                    <Button
                        variant="outlined"
                        sx={{ textTransform: "none" }}
                        onClick={onReset}
                    >
                        Reset
                    </Button>
                )}
            </div>
        );
    };

    const { counterPrayerExpanded } = store.getState().caseResponse

    const handleChangeAccordion =
        (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (event.type === "click" || event.type === "keydown") {


                dispatch(setCounterPrayersExpanded(counterPrayerExpanded === panel ? -1 : panel));

            }
        };

    const getIntensity = (scale: number) => {
        if (scale >= 0 && scale <= 3) {
            return "Low";
        } else if (scale >= 4 && scale <= 7) {
            return "Medium";
        } else if (scale >= 8 && scale <= 10) {
            return "High";
        } else {
            return "";
        }
    };





    return (
        <div className="d-flex flex-column  w-100 overflow-auto bg-light m-3">
            <Box className="d-flex flex-column justify-content-center align-items-center gap-2">
                <div className={`d-flex flex-column  w-100`}>
                    {counterPrayerList?.map((prayers: PrayerModel, i: number) => (


                        <Accordion
                            key={i}
                            className="border-0 p-0"
                            expanded={counterPrayerExpanded === i}
                            onChange={handleChangeAccordion(i)}

                        >
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="w-100 p-0"
                            >
                                <div className="row bg-white p-0 m-0  w-100 ">
                                    {counterPrayerExpanded === i ? (
                                        <div className="fs-med-slg-medium  d-flex justify-content-center align-items-center gap-2">
                                            <span>

                                                Counter Prayer 


                                                
                                            </span>

                                            <Tooltip title="The reason and percentages you give us in response are confidential.">
                                                <InfoOutlinedIcon fontSize="small" />
                                            </Tooltip>

                                        </div>
                                    ) : null}
                                    <div className="col-9 row  ">

                                        <div className="col-3 d-flex gap-3 align-items-center">
                                            <img src={DotIcon} alt="dot" width={24} height={24} />
                                            <Box
                                                className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                                                width={28.21}
                                                height={28.21}
                                            >
                                                {i + 1}
                                            </Box>
                                        </div>

                                        <ConditionalWrapper className="col-9 d-flex align-items-center" condition={counterPrayerExpanded !== i}>
                                            {prayers.prayerDetail && (
                                                <Box className=" text-grey  text-truncate" maxWidth={400}>
                                                    {prayers.prayerDetail}
                                                </Box>
                                            )}


                                        </ConditionalWrapper>






                                    </div>


                                    <div className="col-3 p-0  d-flex gap-2 justify-content-end align-items-center ">
                                        {prayers.intensity ? (
                                            prayers.intensity <= 3 ? (
                                                <div className="bg-light-blue1 fs-sm-light px-3 rounded-4">low</div>
                                            ) : prayers.intensity > 3 && prayers.intensity <= 7 ? (
                                                <div className="bg-light-blue1 fs-sm-light  px-3 rounded-4">medium</div>
                                            ) : (
                                                <div className="bg-light-red fs-sm-light text-red px-3 rounded-4 ">
                                                    high
                                                </div>
                                            )
                                        ) : (
                                            <div className="bg-light-blue1 px-3 rounded-4">low</div>
                                        )}

                                    </div>
                                </div>
                            </AccordionSummary>

                            < >
                                <div className="d-flex h-100 flex-column">

                                    <div className="h-100">
                                        <Box className="container d-flex flex-column justify-content-center align-items-center gap-2 mb-3">
                                            <TextField
                                                multiline
                                                error={!!prayers.errors?.prayerDetail}
                                                type="text"
                                                placeholder={
                                                    "Add Your Counter Prayer"
                                                }
                                                variant="standard"
                                                name="prayerDetail"
                                                helperText={prayers.errors?.prayerDetail}
                                                value={prayers.prayerDetail ? prayers.prayerDetail : ""}
                                                sx={{ ...MuiTextFieldBorderStyle, width: 600 }}
                                            />
                                            <div className=" pt-2 p-0 m-0" style={{ width: 600 }}>
                                                <span className=''>Intensity of wants :</span>
                                                <span className='fs-sm-lg-med '>{getIntensity(prayers.intensity)}</span>
                                            </div>


                                            <ConditionalWrapper condition={prayers.status===2}>
                                                {/* Only if status is rejected  i.e status = 2 */}
                                                <ConditionalWrapper condition={prayers?.status === 2}>
                                                    <div className="fs-sm-lg-semibold mb-2">
                                                        <span> Add Your Objections here </span>

                                                    </div>

                                                    <TextField
                                                        error={!!prayers?.errors?.objection}
                                                        className="rounded d-flex "
                                                        type="text"
                                                        placeholder="Add Your Objections"
                                                        variant="outlined"
                                                        name="objection"
                                                        helperText={prayers?.errors?.objection}
                                                        multiline={true}
                                                        rows={3}
                                                        value={prayers?.objection}
                                                        sx={{ ...MuiTextFieldBorderStyle, width: 500 }}
                                                        onChange={(event) => {
                                                            let updatedPrayers = structuredClone(counterPrayerList);
                                                            updatedPrayers[i].objection = event.target.value ;
                                                            setDataToSend(updatedPrayers);
                                                        }}
                                                    />
                                                    <div className="fs-sm-lg-semibold mt-3 mb-5">
                                                        How much percentage do you agree ?
                                                    </div>
                                                    <Box sx={{ width: 500 }}>
                                                        <Slider
                                                            aria-label="Always visible"
                                                            defaultValue={5}
                                                            step={1}
                                                            min={0}
                                                            max={10}
                                                            valueLabelDisplay="auto"
                                                            getAriaValueText={Percentage_valuetext}
                                                            valueLabelFormat={Percentage_valuetext}
                                                            marks={marksForPercentage}
                                                            value={(prayers?.percentage as number) || 0}
                                                        onChange={(event, value) =>
                                                            {
                                                            let updatedPrayers = structuredClone(counterPrayerList);
                                                            updatedPrayers[i].percentage = value as any;
                                                            setDataToSend(updatedPrayers);
                                                            }
                                                        
                                                        }
                                                        />
                                                    </Box>
                                                </ConditionalWrapper>
                                            </ConditionalWrapper>
                                            {/* Allow to accept reject only for claimant side prayers */}
                                            <AcceptRejectButtons
                                                status={prayers.status}
                                                onAccept={() => {
                                                    let updatedPrayers = structuredClone(counterPrayerList);
                                                    updatedPrayers[i].status = 1;
                                                    setDataToSend(updatedPrayers);

                                                }}
                                                onReject={() => {
                                                    let updatedPrayers = structuredClone(counterPrayerList);

                                                    updatedPrayers[i].status = 2;
                                                    setDataToSend(updatedPrayers);

                                                }}
                                                onReset={() => {
                                                    let updatedPrayers = structuredClone(counterPrayerList);
                                                    updatedPrayers[i].status = 0;
                                                    updatedPrayers[i].objection = "";
                                                    setDataToSend(updatedPrayers);
                                                }}
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </>
                        </Accordion>
                    ))
                    }
                </div >

            </Box>
        </div>
    )
}

export default ResponseToConterPrayer