import React from "react";
import { DANGER, INFO, SUCCESS, WARNING } from "../../constants/status";
import { Chip } from "@mui/material";
import { StatusType } from "../../model/fileCase";

interface ShowLabelProps {
  label: string | null;

  secondaryLabel ?: string ;
  status: StatusType | null;
  onClick?: () => any;
}

export const ShowLabel = ({ label, status, onClick,secondaryLabel }: ShowLabelProps) => {
  const getStatusColor = (status: StatusType | null) => {
    switch (status) {
      case StatusType.INFO:
        return {
          backgroundColor: "#b7dff2",
          textColor: "#0081c1",
        };
      case StatusType.SUCCESS:
        return {
          backgroundColor: "#cffae8",
          textColor: "#45b26b",
        };
      case StatusType.WARNING:
        return {
          backgroundColor: "#fbd6bc",
          textColor: "#ff881a",
        };
      case StatusType.DANGER:
        return {
          backgroundColor: "#ffdad6",
          textColor: "#ba1a1a",
        };
      default:
        return {
          backgroundColor: "#ffffff",
          textColor: "#000000",
        };
    }
  };

  const { backgroundColor, textColor } = getStatusColor(status);
  if (
    label !== null &&
    label !== undefined &&
    label !== ""
  ) {
    return (
      <Chip
        className="cursor-pointer"
        label={
          <span className="fs-sm-med" style={{ color: textColor }}>
            {label} {secondaryLabel}
          </span>
        }
        size="small"
        onClick={onClick}
        sx={{ backgroundColor }}
      />
    );
  } else {
    return null;
  }


};
