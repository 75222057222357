import React, { useEffect, useState } from 'react';
// import { diffChars } from 'diff';
import * as HtmlDiff from "@ali-tas/htmldiff-js";

interface Styles {
  [key: string]: React.CSSProperties;
}

const styles: Styles = {
  ".diffmod del": {
    backgroundColor: "red",
  },
  ".diffmod ins": {
    backgroundColor: "green",
  },
};


// function compareAndHighlightHTML({ oldContent, newContent }: {oldContent: any, newContent: any}) {
//     const diff = diffChars(oldContent, newContent);
//     let highlightedHTML = '';
//     diff.forEach((part: any, index: number) => {
//       if (part.added) {
//         highlightedHTML += `<span style="background-color: lightgreen;">${part.value}</span>`;
//       } else if (part.removed) {
//         highlightedHTML += `<span style="background-color: lightcoral;">${part.value}</span>`;
//       } else {
//         highlightedHTML += part.value;
//       }
//     });
//     return { __html: highlightedHTML };
// }

const HTMLDiffComponent = ({ oldContent, newContent }: {oldContent: any, newContent: any}) => {

    // diff lib
    // const [highlightedHTML, setHighlightedHTML] = useState(compareAndHighlightHTML({ oldContent, newContent }));
    // useEffect(() => {
    //     setHighlightedHTML(compareAndHighlightHTML({ oldContent, newContent }));
    // }, [oldContent, newContent]);
    // return <div dangerouslySetInnerHTML={highlightedHTML}></div>;

    // @ali-tas/htmldiff-js lib
    const highlightedHTML = { __html: HtmlDiff.Diff.execute(oldContent, newContent) };
    return(
      <>
        <div dangerouslySetInnerHTML={highlightedHTML} style={styles}></div>
      </>
    );
}

export default HTMLDiffComponent;
