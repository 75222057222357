

import React from 'react'
interface ConditionalWrapperProps {
    className?: string,
    condition: boolean;
    children: React.ReactNode
}
function ConditionalWrapper({ children, className, condition }: ConditionalWrapperProps) {
    return (
        condition ? <div className={className}>{children}</div> : null
    )
}

export default ConditionalWrapper