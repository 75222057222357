import { Box } from "@mui/material";
import React from "react";

interface RoleBoxProps {
  imgUrl: string;
  name: string;
  active: boolean;
  disabled?: boolean;
  onClick?: any; // Define the type for onClick prop
}

const CardRole = ({
  imgUrl,
  name,
  active,
  disabled,
  onClick,
}: RoleBoxProps) => {
  return (
    <Box
      className={`d-flex flex-column justify-content-center 
        align-content-center p-2 me-2 bg-light text-center rounded-1
         shadow-sm cursor-pointer ${active && !disabled ? "active-border" : "border-bottom"
        }`}
      sx={{ width: 125, height: 125 }}
      onClick={disabled ? undefined : onClick}
    >
      <div>
        <img src={imgUrl} alt="roleIcon" width={65.5} height={65.5} />
      </div>
      <span className="mt-1 fs-normal-light">{name}</span>
    </Box>
  );
};

export default CardRole;
