import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CustomLoader = ({ size = 100, height = '100vh' }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: height,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default CustomLoader;
