import { DRAFT, RT_SELF } from "../../../constants/case-constants";
import { RESPONDENT_SYNOPSIS } from "../../../constants/case-state";
import { Evidence } from "../../../model/caseDocument/synopsis";
import { CaseFileErrorModel } from "../../../model/fileCase";
import { CaseAgreement, DateOption } from "./initCaseSliceState";
import { PrayerErrorModel } from "../../../model/caseDocument/prayer";



const vakalatnamaObj={
  vakalatnamaDoc:null  as Evidence|null,
  verification:0,
  error:null as null | string,
}
 export type VakalatnamaType= typeof vakalatnamaObj
const caseFileResponse = {
  uniqueId: null,
  representationType: RT_SELF,

  //lawyer information
  lawFirmName: "",
  advocateName: "",
  advocateMobileNo: "",
  advocateEmail: "",

  //agreement informations
  tempResolutionAgreementStatus: 0,
  resolutionAgreementStatus: 0,
  resolutionAgreementRemark: "",

  //vakalatnama information
  // vakalatnama: null as VakalatnamaType|null,
  
  caseAgreementList: [] as CaseAgreement[],


  //other information
  status: DRAFT,
  hasChanges: false,
  errors: {} as CaseFileErrorModel,
};


const errors= {
   dates: "",
    dateType: "",
  eventDetail: "",
  evidences: "",
};

 type errorType=typeof errors;
const synopsis = [
  {
    dates:[] ,
    dateType:DateOption.SINGLE_DATE,
    eventDetail: "",
    evidences: [] as Evidence[],
    errors:{} as errorType,
    hasChanges: false,
    resolution: "",
    resolved: false,
    rejected: null,
    reason: "",
  },
];

const prayer = {
  counterPrayer: [
    {
      id: 0,
      prayerDetail: "",
      intensity: 1,
      objection: "",
      status: 0,
      percentage: 0,
      errors: {} as PrayerErrorModel,
    },
  ],
  prayers: [
    {
      id: 0,
      prayerDetail: "",
      intensity: 1,
      objection: "",
      status: 0,
      percentage: 0,
    },
  ],
};

const responseScrutinyCount = {
  synopsis: null as number | null,
  statementOfObjection: null as number | null,
  evidence: null as number | null,
};

type ResponseScrutinyCountType = typeof responseScrutinyCount;

const StatementOfObjection = {
  statement: "" as string,
  hasChanges: false,
  resolution: "",
  resolved: false,
  rejected: null,
  reason: "",
}

const CaseResponseDetails = {
  synopsis: synopsis as SynopsisType,
  prayer: prayer as PrayerType,
  statementOfObjection: StatementOfObjection as typeof StatementOfObjection,
  statementOfObjectionError: "",
  previousSynopsis: [] as SynopsisType,
  previousStatementOfObjection: StatementOfObjection as typeof StatementOfObjection,
  hasResponseChanges: false,
  responseScrutinyCount: responseScrutinyCount as ResponseScrutinyCountType,
  // statementOfObjectionRejected: null,
  // statementOfObjectionReason: "" as string,
  // statementOfObjectionhasChanges: false as boolean,
};

export const ResponseScrutinyCount = {
  synopsis: null as number | null,
  statementOfObjection: null as number | null,
};


export const initResponse = {
  uniqueId: null,
  caseFileResponse: caseFileResponse as typecaseFileResponse,
  caseFileResponseDetail: CaseResponseDetails as CaseResponseDetailsType,
  neutralSelected:0,

  actions: [ ] as string[ ],
  versions: null as null|[],

  deletedFiles: [] as string[],
  activityLogs: [],
  currentSection: RESPONDENT_SYNOPSIS,
  permissionState: {} as any,

  respondentlegalHandover:null as boolean | null,

  caseRecommendationResponse: null as any,
  synopsisExpanded: 0,
  prayerExpanded: 0,
  counterPrayerExpanded: 0,
  isResponseLoading: false,
  hasResponseDateInSlice:false,
  errorMessage: "",
  isValid: false,
};

export type CaseResponseDetailsType = typeof CaseResponseDetails;
export type typecaseFileResponse = typeof caseFileResponse;
export type SynopsisType = typeof synopsis;
export type PrayerType = typeof prayer;
