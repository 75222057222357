import { Box, Button, Dialog, Snackbar } from "@mui/material";
import React, {
  ReactNode,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import pic from "../assets/dialogBoxIcon.svg";
import Slide, { SlideProps } from '@mui/material/Slide';
import { TransitionProps } from "@mui/material/transitions";

import SUCCESS_IMG from '../assets/Success.svg';
import FAILURE_IMG from '../assets/Failure.svg';
import WARNING_IMG from '../assets/Warning.svg';

export enum DialogStatus {
  SUCCESS = 'Success',
  FAILURE = 'Failure',
  WARNING = 'Warning',
}

type CustomDialogContextType = {
  showDialogBox: (label: string, info: string) => void;
  hideDialogBox: () => void;
};

type CustomDialogProps = {
  children: ReactNode;
};

const CustomDialogContext = createContext<CustomDialogContextType | undefined>(
  undefined
);

export function useCustomDialogBox() {
  const context = useContext(CustomDialogContext);
  if (!context) {
    throw new Error(
      "useCustomDialogBox must be used within a CustomDialogProvider"
    );
  }
  return context;
}

const Transition = forwardRef<HTMLDivElement, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function CustomDialogProvider({ children }: CustomDialogProps) {
  const [label, setLabel] = useState("");
  const [info, setInfo] = useState("");

  const showDialogBox = useCallback((label: string, info: string) => {
    setLabel(label);
    setInfo(info);
  }, []);

  const hideDialogBox = useCallback(() => {
    setLabel("");
    setInfo("");
  }, []);

  // Memoize the context value to avoid unnecessary re-renders in child components
  const contextValue: CustomDialogContextType = useMemo(
    () => ({
      showDialogBox,
      hideDialogBox,
    }),
    [showDialogBox, hideDialogBox]
  );

  return (
    <CustomDialogContext.Provider value={contextValue}>
      <Dialog open={label !== ""} TransitionComponent={Transition} keepMounted onClose={hideDialogBox}>
        <Box
          className="d-flex py-3 py-md-4 px-2 px-md-3  justify-content-center gap-2 align-items-center flex-column"
          width={312}
        >
          <img src={
            label === DialogStatus.SUCCESS
            ? SUCCESS_IMG
            : label === DialogStatus.FAILURE
            ? FAILURE_IMG
            : label === DialogStatus.WARNING
            ? WARNING_IMG
            : pic
          } alt="DialogBox image" width={61} height={61} />
          <div className="fs-med-lg-semibold">{label}</div>
          <p className=" text-grey fs-sm-lg-light">{info}</p>
          <Button
            className="w-fit bg-primary"
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={hideDialogBox}
          >
            Close
          </Button>
        </Box>
      </Dialog>

      {children}
    </CustomDialogContext.Provider>
  );
}
