import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import CustomLoader from "../components/common/CustomLoader";

const OpenLayout = () => {
  return (
    <Suspense fallback={<CustomLoader />}>
      <Outlet />
    </Suspense>
  );
};

export default OpenLayout;
