import AxiosHelper from "./AxiosHelper";

const caseFileUpload = (file: File, args: string[] | []) => {
  const formData: FormData = new FormData();
  formData.append("file", file, file.name);

  return new Promise((resolve, reject) => {
    AxiosHelper.fileUpload({
      path: `case/upload-file/${args.join("/")}`,
      body: formData,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e.message));
  });
};
const minutesDocUpload = (file: File, args: string[] | []) => {
  const formData:FormData = new FormData();

   formData.append("file", file,file.name)
 
  
  return new Promise((resolve, reject) => {
    AxiosHelper.fileUpload({
      path: `case/${args.join("/")}`,
      body: formData,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e.message));
  });
};

const downloadCaseFile = (args: string[] | []) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.getFile(`case/fetch-file/${args.join("/")}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
};

const apiUserKycFileUpload = (file: File, args: string[] | []) => {
  const formData: FormData = new FormData();
  formData.append("file", file, file.name);

  return new Promise((resolve, reject) => {
    AxiosHelper.fileUpload({
      path: `user/upload-file/${args.join("/")}`,
      body: formData,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e.message));
  });
};

const downloadUserFile = (args: string[] | []) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.getFile(`user/fetch-file/${args.join("/")}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
};
const downloadFileBlob = (args: string[] | []) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.getFileBlob(`case/fetch-file/${args.join("/")}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
};
const downloadFileApi = (args: string[] | []) => { 
  const  file_url=args.join("/")  //pass url as list argument

  return new Promise((resolve, reject) => {
    AxiosHelper.getFileBlob(file_url)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e));
  });
};



const reUploadAgreement = (reqBody: any, uniqueId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: `/case/${uniqueId}/re-upload-agreement`,
      body: reqBody,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => reject(e.message));
  });
};

const FileService = {
  caseFileUpload,
  minutesDocUpload,
  downloadCaseFile,
  apiUserKycFileUpload,
  downloadUserFile,
  downloadFileBlob,
  downloadFileApi,
  reUploadAgreement
};

export default FileService;
