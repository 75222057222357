// resolutionMethod

export const RM_ARBITRATION = "ARBITRATION";
export const RM_MEDIATION = "MEDIATION";
// export const RM_AMICABLE_SETTLEMENT = "AMICABLE_SETTLEMENT"; 
export const RM_CONCILIATION = "CONCILIATION"; 


//representationType
export const RT_SELF = "SELF";
export const RT_LEGAL_PROFESSIONAL = "LEGAL_PROFESSIONAL";

//claimantList
export const CT_INDIVIDUAL = "INDIVIDUAL";
export const CT_ORGANIZATION = "ORGANIZATION";

//respondentType
export const RESPONDENT_TYPE_INDIVIDUAL = "INDIVIDUAL";
export const RESPONDENT_TYPE_ORGANIZATION = "ORGANIZATION";

//evidenceType

export const ET_ANNEXURE = "ANNEXURE";
export const ET_EXHIBIT = "EXHIBIT";

export const NEW_CASE = "NEW_CASE";

export const NON_DRAFT = "NON_DRAFT";
export const DRAFT = "DRAFT";
export const LEGAL_HANDOVER = "LEGAL_HANDOVER";
export const LEGAL_REVOKE = "LEGAL_REVOKE";
export const LEGAL_REVOKE_ACCEPTED = "LEGAL_REVOKE_ACCEPTED";
export const LEGAL_REVOKE_REJECTED = "LEGAL_REVOKE_REJECTED";

export const SENT_FOR_RAD_SCRUTINY = "SENT_FOR_RAD_SCRUTINY";
export const SENT_FOR_RESCRUTINY = "SENT_FOR_RESCRUTINY";
export const SENT_TO_RESPONDENT = "SENT_TO_RESPONDENT";
export const SCRUTINY_RESOLVED = "SCRUTINY_RESOLVED";
export const SCRUTINY_REVIEW = "SCRUTINY_REVIEW";

export const SEND_RESPONSE_TO_CLAIMANT = "SEND_RESPONSE_TO_CLAIMANT";

export const SEND_BACK_TO_CLAIMANT = "SEND_BACK_TO_CLAIMANT";
export const SEND_BACK_TO_RESPONDENT = "SEND_BACK_TO_RESPONDENT";

export const RESPONDENT_ROLE = "RESPONDENT";
export const CLAIMANT_ROLE = "CLAIMANT";
export const LEGAL_ROLE = "LEGAL";
export const ADMIN_ROLE = "ADMIN";
export const NEUTRAL_ROLE = "NEUTRAL";

// tabs constants

export const CASE_DETAILS_TAB = "CASE_DETAILS_TAB";
export const LEGAL_DETAILS_TAB = "LEGAL_DETAILS_TAB";
export const ORGANIZTION_DETAIL_TAB="ORGANIZTION_DETAIL_TAB"
export const METHOD_OF_RESOLUTION_TAB = "METHOD_OF_RESOLUTION_TAB";

export const TRIBUNAL_ARBITRATOR = "TRIBUNAL";
export const SOLE_ARBITRATOR = "SOLE";

export const RESOLUTION_PHASE = "RESOLUTION_PHASE";

//ui tab


export const CASE_DETAILS = 0;
export const RESPONSE_TAB = 1;
export const TIMELINE_TAB = 2;



export const AS_ARBITRATOR = 4;
export const AS_CONCILIATOR = 6;
export const AS_MEDIATOR = 5;

export const AS_DRAFT = 0;
export const AS_CLAIMANT = 1;
export const AS_RESPONDENT = 2;


export const IS_NOT_LEGAL_HANDOVER = 0;
export const IS_CLAIMANT_LEGAL_HANDOVER = 1;
export const IS_RESPONDENT_LEGAL_HANDOVER = 2;
export const IS_BOTH_LEGAL_HANDOVER = 3;

// ENum Agreement Types
export enum AgreementType {
    CLAIMANT_VAKALATNAMA = 1,
    RESPONDENT_VAKALATNAMA = 2,

    CLAIMANT_AFFIDAVIT = 3,
    FIRST_PARTY_AFFIDAVIT = 4,
    
    RESPONDENT_AFFIDAVIT = 5,
    SECOND_PARTY_AFFIDAVIT = 6,

    MEDIATOR_DISCLOSURE_STATEMENT_AND_CONSENT_FORM = 7,
    CONCILIATOR_DECLARATION = 8,
    ARBITRATOR_DECLARATION = 9,
    CONSENT_FORM = 10,
    STANDARDISED_AGREEMENT = 11,
}

// to get actual agreement type name
export const getAgreementTypeName = (agreementType: AgreementType): string => {
    switch (agreementType) {
        case AgreementType.CLAIMANT_VAKALATNAMA:
            return 'Claimant / First Party Vakalatnama';
        case AgreementType.RESPONDENT_VAKALATNAMA:
            return 'Respondent / Second Party Vakalatnama';
        case AgreementType.CLAIMANT_AFFIDAVIT:
            return 'Claimant Affidavit';
        case AgreementType.FIRST_PARTY_AFFIDAVIT:
            return 'First Party Affidavit';
        case AgreementType.RESPONDENT_AFFIDAVIT:
            return 'Respondent Affidavit';
        case AgreementType.SECOND_PARTY_AFFIDAVIT:
            return 'Second Party Affidavit';
        case AgreementType.MEDIATOR_DISCLOSURE_STATEMENT_AND_CONSENT_FORM:
            return 'Mediator Disclosure Statement and Consent Form';
        case AgreementType.CONCILIATOR_DECLARATION:
            return 'Conciliator Declaration';
        case AgreementType.ARBITRATOR_DECLARATION:
            return 'Arbitrator Declaration';
        case AgreementType.CONSENT_FORM:
            return 'Consent Form';
        case AgreementType.STANDARDISED_AGREEMENT:
            return 'Standardised Agreement';
        default:
            return 'Unknown Agreement Type';
    }
};

