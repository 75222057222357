import { userDateProps } from "../model/userModel";
import AxiosHelper from "./AxiosHelper";

const register = (body: userDateProps) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/sign-up",
      queryParams: null,
      body: body,
    })
      .then((res: any) =>
        res.isSuccess ? resolve(res.data) : reject(res.description)
      )
      .catch((e) => {
        reject(e);
      });
  });
};

const login = (userData: any) => {
  const body = { username: userData.email, password: userData.password };

  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/sign-in",
      queryParams: null,
      body: body,
    })
      .then((res: any) => resolve({ ...res }))
      .catch((e) => reject(e));
  });
};

const verifyOtp = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/validate-otp",
      queryParams: null,
      body: body,
    })
      .then((res: any) =>
        res.isSuccess ? resolve(res.data) : reject(res.description)
      )
      .catch((e) => reject(e));
  });
};

const resendOtp = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/resend-otp",
      queryParams: null,
      body: body,
    })
      .then((res: any) =>
        res.isSuccess ? resolve(res.data) : reject(res.description)
      )
      .catch((e) => reject(e));
  });
};

const forgotPassword = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/forgot-password",
      queryParams: null,
      body: body,
    })
      .then((res: any) =>
        res.isSuccess ? resolve(res.data) : reject(res.description)
      )
      .catch((e) => reject(e));
  });
};

const validateInvitation = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/validate-invitation",
      queryParams: null,
      body: body,
    })
      .then((res: any) =>
        res.isSuccess ? resolve(res.data) : reject(res.description)
      )
      .catch((e) => reject(e));
  });
};

const invitationSignUp = (body: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({
      path: "auth/complete-invitation-signup",
      queryParams: null,
      body: body,
    })
      .then((res: any) =>
        res.isSuccess ? resolve(res.data) : reject(res.description)
      )
      .catch((e) => reject(e));
  });
};

const AuthService = {
  login,
  register,
  verifyOtp,
  resendOtp,
  forgotPassword,
  validateInvitation,
  invitationSignUp,
};

export default AuthService;
