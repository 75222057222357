import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material"
import ClosePic from "../../../../assets/icon/navigation/close_24px.png";
import logoIcon from "../../../../assets/icon/logo_icon.png";
import PrayerList from "../prayers/PrayersList";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { addPrayer } from "../../../../redux/slice/caseResponseSlice";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


interface ObjectionProps {
    open: boolean,
    onClose: () => void
}

const CounterPrayer = (props: ObjectionProps) => {
    const { onClose, open } = props

    const dispatch: AppDispatch = useDispatch();

    const handleAddPrayer = () => {
        dispatch(addPrayer())
    }
    return (
        <Dialog open={open} fullScreen={true}>
            <DialogTitle
                className="fs-med-lg-semibold"
                sx={{ mx: 1, my: 2, px: 2, py: 0 }}
                id="customized-dialog-title"
            >
                <div className="position-relative d-flex justify-content-between">
                    <img src={
                        `${process.env.PUBLIC_URL}/rad-logo-v2.png`
                    } alt="logo" height={38.09} />

                    <div className="position-absolute top-0 start-50 translate-middle-x">
                        <span className="fs-med-lg-semibold">Counter Prayer</span>
                    </div>
                </div>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => {
                    onClose()
                }}
                className="shadow-sm border p-2"
                sx={{
                    width: "fit-content",
                    height: "fit-content",
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <img src={ClosePic} alt="close button" />
            </IconButton>
            <DialogContent className="bg-light text-center" sx={{ p: 3 }}>
                <PrayerList isCounterPrayer={true} />
                
                <Button className="text-center text-primary fw-semibold"
                    sx={{ textTransform: "none" }}

                    onClick={handleAddPrayer}
                >+  Add More Counter Prayer</Button>
                <Tooltip className="text-primary" title={"For each Counter Prayer, Click “Add more Counter Prayer”"}><InfoOutlinedIcon />
                      </Tooltip>
                
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ textTransform: "none" }} onClick={onClose}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    )
}
    ;
export default CounterPrayer