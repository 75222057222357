import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import CardRole from "./common/CardRole";

import organization_Pic from "../assets/Organization.png";
import Individual_Pic from "../assets/Individual.png";
import Neutral_Pic from "../assets/Neutral.png";
import { PersonalDetail } from "../model/PersonalDetail";
import BusinessmanPic from "../assets/BusinessmanPic.svg";

import {
  INDIVIDUAL,
  LEGAL_PROFESSIONAL,
  NEUTRAL,
  OTHER_ORG,
} from "../constants/nav-constants";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    minWidth: 800,
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  personalDetail: PersonalDetail;
  onNextClick: (personalDetail: PersonalDetail) => void;
}

export default function CustomDialog({
  open,
  onClose,
  personalDetail,
  onNextClick,
}: CustomDialogProps) {
  const roles = [
    { contextType: INDIVIDUAL, roleName: "Individual" },
    { contextType: OTHER_ORG, roleName: "Organization" },
    { contextType: NEUTRAL, roleName: "Neutral" },
    { contextType: LEGAL_PROFESSIONAL, roleName: "Law Professional" },
  ];

  const [selectedRole, setSelectedRole] = React.useState<string | null>(
    roles[0].contextType
  );
  const { nextBtnLoading } = useSelector(
    (state: RootState) => state.profileDetail
  );

  const handleSelectedRole = (contextType: string) => {
    setSelectedRole(contextType);
  };

  const onNext = () => {
    const detail = { ...personalDetail, currentContextType: selectedRole };
    onNextClick(detail);
  };

  return (
    <div>
      <BootstrapDialog
        className="p-3"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
        ></BootstrapDialogTitle> */}

        <DialogContent>
          <Box className="d-flex justify-content-center flex-column align-items-center gap-4">
            <div className="bg-light-secondary d-flex justify-content-between w-100 mt-4 py-0 px-4">
              <div className="d-flex flex-column my-auto">
                {personalDetail ? (
                  <span className="fs-med-lg-light">
                    Hi, {personalDetail?.fullName}!
                  </span>
                ) : null}
                <p className="fs-sm-light p-0 m-0">
                  Complete your profile to file your case in our dispute
                  settlement platform.
                </p>
              </div>
              <div className="d-flex">
                <img src={BusinessmanPic} alt="bisnuss man" />
              </div>
            </div>

            <div className="fs-med-lg-semibold">
              How will you be using this application?
            </div>
            <div className="gap-md-4 gap-1 d-flex flex-column">
              <div className="gap-md-4 gap-1 d-flex">
                <CardRole
                  imgUrl={Individual_Pic}
                  name={roles[0].roleName}
                  active={selectedRole === roles[0].contextType}
                  onClick={() => {
                    handleSelectedRole(roles[0].contextType);
                  }}
                />
                <CardRole
                  imgUrl={organization_Pic}
                  name={roles[1].roleName}
                  active={selectedRole === roles[1].contextType}
                  onClick={() => {
                    handleSelectedRole(roles[1].contextType);
                  }}
                />
              </div>
              <div className="gap-md-4 gap-1 d-flex">
                <CardRole
                  imgUrl={Neutral_Pic}
                  name={roles[2].roleName}
                  active={selectedRole === roles[2].contextType}
                  // disabled
                  onClick={() => {
                    handleSelectedRole(roles[2].contextType);
                  }}
                />
                <CardRole
                  imgUrl={organization_Pic}
                  name={roles[3].roleName}
                  active={selectedRole === roles[3].contextType}
                  onClick={() => {
                    handleSelectedRole(roles[3].contextType);
                  }}
                />
              </div>
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          {/* <Button
            variant="text"
            onClick={onClose}
            sx={{ textTransform: "none" }}
          >
            Skip
          </Button> */}
          <Button
            variant="contained"
            onClick={onNext}
            disabled={!selectedRole || nextBtnLoading}
          >
            Next
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
