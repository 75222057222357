import React, { ChangeEvent } from "react";
import { Box, InputAdornment, Slider, TextField } from "@mui/material";
import { MuiTextFieldBorderStyle } from "../../../../util/mui-helper";
import { Prayer, PrayerModel } from "../../../../model/caseDocument/prayer";
import { editPrayers } from "../../../../redux/slice/caseSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, store } from "../../../../redux/store";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface PrayersEditBoxProps {
  prayers: PrayerModel;
  index: number;
}

const PrayersRow: React.FC<PrayersEditBoxProps> = ({ prayers, index }) => {
  const marks = [
    {
      label: "Low",
      value: 0,
    },

    {
      label: "Medium",
      value: 5,
    },

    {
      label: "High",
      value: 10,
    },
  ];

  const marksForPercentage = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
      label: "50%",
    },
    {
      value: 6,
    },
    {
      value: 7,
    },
    {
      value: 8,
    },
    {
      value: 9,
    },
    {
      value: 10,
      label: "100%",
    },
  ];
  function Percentage_valuetext(value:number){
    return `${value*10}%`
  }
  function valuetext(value: number) {
    return `${value}`;
  }
  const { isRespondent } = store.getState().case;

  const dispatch: AppDispatch = useDispatch();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    dispatch(editPrayers({ index, value, key }));
  };

  const caseFileResponsePrayers: any =
    store.getState().case.response?.caseFileResponseDetail?.prayers;

  const showPrayersBox = isRespondent;

  return (
    <div className="h-100">
      <Box className="container d-flex flex-column justify-content-center align-items-center gap-2 p-4">
        {!showPrayersBox && (
          <>
            <TextField
              multiline
             // error={!!prayers.errors?.prayerDetail}
              type="text"
              placeholder="Add Your Prayer"
              variant="standard"
              name="prayerDetail"
              //helperText={prayers.errors?.prayerDetail}
              
              value={prayers?.prayerDetail ? prayers.prayerDetail : ""}
              sx={{ ...MuiTextFieldBorderStyle, width: 500 }}
              onChange={onInputChange}
            />

            <p className="fs-sm-lg-med p-0 m-0" style={{ width: 500 }}>
              Intensity of wants
            </p>

            <Box sx={{ width: 500 }}>
              <Slider
                aria-label="Always visible"
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                value={prayers.intensity as number}
                min={0}
                max={10}
                valueLabelDisplay="auto"
                onChange={(event, value) =>
                  onInputChange({
                    target: {
                      name: "intensity",
                      value: value as any,
                    },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
              />
            </Box>
          </>
        )}

        {showPrayersBox && (
          <>
            <div className="fs-sm-lg-semibold">Add Your Objections here</div>

            <TextField
              error={!!caseFileResponsePrayers[index]?.errors?.prayerDetail}
              className="rounded d-flex "
              type="text"
              placeholder="Add Your Objections"
              variant="outlined"
              name="prayerDetail"
              helperText={caseFileResponsePrayers[index]?.errors?.prayerDetail}
              multiline={true}
              rows={3}
              value={
                caseFileResponsePrayers[index]?.prayerDetail
                  ? caseFileResponsePrayers[index]?.prayerDetail
                  : ""
              }
              sx={{ ...MuiTextFieldBorderStyle, width: 500 }}
              onChange={onInputChange}
            /> 
            <div className="fs-sm-lg-semibold mt-3 mb-5">
              How much percentage do you agree ?
            </div>
            <Box sx={{ width: 500 }}>
              <Slider
               aria-label="Always visible"
               defaultValue={5}
               step={1}
               min={0}
               max={10}
               valueLabelDisplay="auto"
               getAriaValueText={Percentage_valuetext}
              valueLabelFormat={Percentage_valuetext}
               marks={marksForPercentage}
                value={caseFileResponsePrayers[index]?.intensity as number || 0}
                onChange={(event, value) =>
                  onInputChange({
                    target: {
                      name: "intensity",
                      value: value as any,
                    },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
              />
            </Box>
          </>
        )}
      </Box>

    </div>
  );
};

export default PrayersRow;
