import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";

import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import FileService from "../network/FileService";
import { editKycDataAdmin, userDetailThunk, userKycVerifyThunk } from "../redux/slice/userSlice";
import CancelIcon from '@mui/icons-material/Cancel';
import { TextField } from "@mui/material";
import { KYC_PENDING, KYC_REJECTED, KYC_VERIFIED } from "../constants/nav-constants";
import { formatDateTime } from "../util/stringUtils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    minWidth: 800,
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

// function BootstrapDialogTitle(props: DialogTitleProps) {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }

interface UserDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  userDetail: any;
}

const UserDetailsDialog = ({
  open,
  onClose,
  userDetail,
}: UserDetailsDialogProps) => {
  const dispatch: AppDispatch = useDispatch();

  const downloadFile = async (fileName: string) => {
    try {
      const res: ArrayBuffer = (await FileService.downloadUserFile([
        userDetail?.uniqueId,
        'kyc',
        fileName,
      ])) as ArrayBuffer;
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };

  const handleKycVerify = () => {
    const updatedList = userDetail?.kycData.map((obj: any) => {
      if (obj.status === KYC_PENDING) {
        return { ...obj, status: KYC_VERIFIED };
      }
      return obj;
    });

    const reqBody = {
        "uniqueId": userDetail?.uniqueId,
        "kycData": updatedList
    }

    dispatch(userKycVerifyThunk(reqBody))
      .unwrap()
      .then((data) => {
        dispatch(userDetailThunk(userDetail?.uniqueId));
      })
      .catch();
  };

  const handleRemark = (index: number, value: string) => {
    dispatch(editKycDataAdmin({
      index: index,
      key: "remark",
      value: value
    }))
  }

  const handleRejectKycList = (index: number, id: string) => {
    const kycObj = userDetail?.kycData?.find((obj: any) => obj.uniqueId === id);

    if(kycObj && kycObj?.status !== KYC_REJECTED){
      dispatch(editKycDataAdmin({
        index: index,
        key: "status",
        value: KYC_REJECTED
      }))
    }
  }

  return (
    <>
      <BootstrapDialog
        className="p-3"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
        ></BootstrapDialogTitle> */}

        <DialogContent>
          <div className="row g-4">
            <div className="col-12">
              <div className="fs-sm-lg-semibold text-primary mt-4">
                {userDetail?.fullName}
              </div>
            </div>
            <div className="col-5">
              <div className="d-flex flex-column gap-3">
                <div>
                  <span className="fs-sm-light ms-1 text-grey">Email ID</span>
                  <div className="fs-normal-light">
                    {userDetail?.email ? userDetail?.email : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="fs-sm-light ms-1 text-grey">
                    Phone Number
                  </span>
                  <div className="fs-normal-light">
                    {userDetail?.mobileNo ? userDetail?.mobileNo : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="fs-sm-light ms-1 text-grey">
                    Date of birth
                  </span>
                  <div className="fs-normal-light">
                    {userDetail?.dob ? formatDateTime(userDetail?.dob) : "N/A"}
                  </div>
                </div>
                <div>
                  <span className="fs-sm-light ms-1 text-grey">Address</span>
                  <div className="fs-normal-light">
                    {userDetail?.address ? userDetail?.address : "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="d-flex flex-column gap-3">
                <div className="fs-sm-lg-semibold">KYC Documents</div>
                <div className="d-flex gap-3 flex-column">
                  {userDetail?.kycData?.map((data: any, key: number) => (
                    <div className={`card border ${data.status === KYC_REJECTED ? 'bg-light' : ''} p-2`} key={key}>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="d-flex gap-2 align-items-center flex-grow-1">
                          <span className="fs-sm-light ms-1 text-grey">
                            {data?.kycType}
                          </span>
                          <div className="fs-normal-light">
                            {data?.fieldValue}
                          </div>
                        </div>
                        <div className="d-flex gap-0 align-items-center">
                          {data?.attachment !== "" && (
                            <IconButton
                              color="primary"
                              onClick={() => downloadFile(data?.attachment)}>
                              <DownloadIcon fontSize="small" />
                            </IconButton>
                          )}
                          {userDetail?.kyc?.kycStatus === KYC_PENDING && (
                            <IconButton
                              color="error"
                              onClick={() => handleRejectKycList(key, data?.uniqueId)}>
                              <CancelIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                      </div>
                      {data.status === KYC_REJECTED &&
                      <TextField
                        multiline
                        className="bg-white mt-2"
                        label="Remark"
                        value={data?.remark}
                        onChange={(e)=>handleRemark(key, e.target.value)}
                        disabled={userDetail?.kyc?.kycStatus !== KYC_PENDING}
                        fullWidth
                      /> }
                    </div>
                  ))}
                </div>
                {userDetail?.kyc ? (
                  <div>
                    <span className="fs-sm-light ms-1 text-grey">
                      KYC Status
                    </span>
                    <div className="fs-normal-light">
                      {userDetail?.kyc?.kycStatus === KYC_PENDING && 'Pending'}
                      {userDetail?.kyc?.kycStatus === KYC_REJECTED && 'Rejected'}
                      {userDetail?.kyc?.kycStatus === KYC_VERIFIED && 'Verified'}
                    </div>
                  </div>
                ) : (
                  <div className="fs-normal-light">Not Available</div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {userDetail?.kyc?.kycStatus === KYC_PENDING && (
            <Button onClick={handleKycVerify}>
              {`Mark as ${userDetail?.kycData?.some((obj: any) => obj.status === KYC_REJECTED) ? 'Rejected' : 'Verified'}`}
            </Button>
          )}
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default UserDetailsDialog;
