import { EDIT_CASE_DETAILS } from "../../constants/local-storage-constants";

import SecureStorage from "../SecureStorage";

export const isEditing = () => {
  const res = SecureStorage.getItem(EDIT_CASE_DETAILS);

  if (res === true) {
    return true;
  } else {
    return false;
  }
};
