import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import pic from "../../assets/profile.png";
import profileIcon from "../../assets/icon/perm_identity_24px.png";
import businessIcon from "../../assets/icon/business_24px.png";
import penIcon from "../../assets/icon/pen.png";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import CloudUploadIcon from "../../assets/icon/cloudUpload.png";

import { useNavigate } from "react-router-dom";
import { ArrowDropDown } from "@mui/icons-material";
import { MuiTextFieldBorderStyle } from "../../util/mui-helper";
import { PersonalDetail } from "../../model/PersonalDetail";
import { AppDispatch, RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail, validateName, validatePhoneNumber } from "../../util/validation";
import { UserDetail } from "../../model/UserDetail";
import {
  editKycData,
  getUserKycThunk,
  initKycData,
  initLawFirmKycData,
  initNeutralKycData,
  initOrgKycData,
  orgKycThunk,
  profileDetailThunk,
  setFormSubmitted,
  setOrganizationDetailFormErrors,
  setPersonalDetailFormErrors,
  setProfileDetail,
  setSelectedProfileTab,
  setSelectedRole,
  setShowAddUserDialog,
  setTempOrganizationDetailForm,
  setTempPersonalDetailForm,
  userKycThunk,
} from "../../redux/slice/userSlice";
import FileInputUI from "./FileInputUI";
import FileService from "../../network/FileService";
import {
  KYC_PENDING,
  KYC_VERIFIED,
  ROLE_INDIVIDUAL,
  ROLE_LAW_FIRM_ADMIN,
  ROLE_NEUTRAL,
  ROLE_ORG_ADMIN,
  ROLE_ORG_USER,
} from "../../constants/nav-constants";
import AddIcon from '@mui/icons-material/Add';
import CameraEnhanceOutlinedIcon from '@mui/icons-material/CameraEnhanceOutlined';

interface UpdateProfileProps {
  closeEditProfile: () => void;
  profileDetail: UserDetail;
  updateProfileDetail: (detail: any, payload: any, orgUpdate: boolean) => void;
}

const UpdateProfile = ({
  closeEditProfile,
  profileDetail,
  updateProfileDetail,
}: UpdateProfileProps) => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const dispatch: AppDispatch = useDispatch();
  const {
    isLoading,
    tempPersonalDetailForm,
    tempOrganizationDetailForm,
    tempPersonalDetailFormError,
    tempOrganizationDetailFormError,
    selectedProfileTab,
    showAddUserDialog,
    selectedRole,
    isFormSubmitted,
  } = useSelector((state: RootState) => state.profileDetail);

  const refreshKycDetails = () => {
    dispatch(getUserKycThunk())
      .unwrap()
      .then((data: any) => {
        if (data) {
          let updatedDetails = {};
          const { profileDetail } = store.getState().profileDetail;

          if (data.kycData) {
            updatedDetails = {
              ...profileDetail,
              kycDetail: {
                kycStatus: data.kyc?.kycStatus,
                kycData: data.kycData,
              },
            };
          } else {
            const kycData =
              profileDetail?.personalDetail?.roleRid === ROLE_INDIVIDUAL
                ? initKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN
                ? initOrgKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN
                ? initLawFirmKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_NEUTRAL
                ? initNeutralKycData
                : [];

            updatedDetails = {
              ...profileDetail,
              kycDetail: {
                kycStatus: 0,
                kycData: kycData,
              },
            };
          }

          dispatch(setProfileDetail(updatedDetails));
        }
      })
      .catch((error: any) => {});
  };

  const handleRoleChange = (text: string) => {
    dispatch(setSelectedRole(selectedRole));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedProfileTab(newValue));
  };

  const handleCloseDialog = () => {
    dispatch(setShowAddUserDialog(!showAddUserDialog));
  };

  const personalDetailInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const updatedData = { ...tempPersonalDetailForm, [name]: value };

    if (name === "fullName") {
      dispatch(
        setPersonalDetailFormErrors({
          ...tempPersonalDetailFormError,
          [name]: value ? validateName(value) : "",
        })
      );
    } else if (name === "email") {
      dispatch(
        setPersonalDetailFormErrors({
          ...tempPersonalDetailFormError,
          [name]: value ? validateEmail(value) : "",
        })
      );
    } else if (name === "mobileNo") {
      dispatch(
        setPersonalDetailFormErrors({
          ...tempPersonalDetailFormError,
          [name]: value ? validatePhoneNumber(value) : "",
        })
      );
    }

    dispatch(setFormSubmitted(false));
    dispatch(setTempPersonalDetailForm(updatedData));
  };

  const orgDetailInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const updatedData = { ...tempOrganizationDetailForm, [name]: value };

    if (["name", "orgRepName", "orgRepDesignation"].includes(name)) {
      dispatch(
        setOrganizationDetailFormErrors({
          ...tempOrganizationDetailFormError,
          [name]: value ? validateName(value) : "",
        })
      );
    } else if (name === "orgRepEmail") {
      dispatch(
        setOrganizationDetailFormErrors({
          ...tempOrganizationDetailFormError,
          [name]: value ? validateEmail(value) : "",
        })
      );
    } else if (name === "orgRepMobileNo") {
      dispatch(
        setOrganizationDetailFormErrors({
          ...tempOrganizationDetailFormError,
          [name]: value ? validatePhoneNumber(value) : "",
        })
      );
    }

    dispatch(setFormSubmitted(false));
    dispatch(setTempOrganizationDetailForm(updatedData));
  };

  const updateUserData = () => {
    let payload = {
      fullName: tempPersonalDetailForm.fullName,
      mobileNo: tempPersonalDetailForm.mobileNo,
      email: tempPersonalDetailForm.email,
      dob: tempPersonalDetailForm.dob,
      address: tempPersonalDetailForm.address,
      profilePic: tempPersonalDetailForm.profilePic,
    };

    let userKycPlayload = {
      kycData:
        profileDetail?.kycDetail?.kycData[0]?.kycType === ""
          ? []
          : profileDetail?.kycDetail?.kycData?.map(
              ({ status, remark, ...rest }: { status: any; remark: any }) =>
                rest
            ),
    };

    let hasEmptyFields = Object.values(payload).some((value) => value === "");

    if (hasEmptyFields) {
      dispatch(setFormSubmitted(true));
      return;
    }

    dispatch(setFormSubmitted(false));
    if ((tempPersonalDetailForm?.roleRid === ROLE_INDIVIDUAL || tempPersonalDetailForm?.roleRid === ROLE_NEUTRAL) && profileDetail?.kycDetail?.kycStatus !== KYC_VERIFIED) {
      dispatch(userKycThunk(userKycPlayload))
        .unwrap()
        .then((data) => {
          refreshKycDetails();
        })
        .catch((err)=>{});
    }

    if (
      tempPersonalDetailFormError.fullName ||
      tempPersonalDetailFormError.email ||
      tempPersonalDetailFormError.mobileNo
    ) {
      return;
    }
    updateProfileDetail(tempPersonalDetailForm, payload, false);
  };

  const saveOrganizationDetails = () => {
    let payload = {
      name: tempOrganizationDetailForm?.name,
      address: tempOrganizationDetailForm?.address,
      orgRepName: tempOrganizationDetailForm?.orgRepName,
      orgRepDesignation: tempOrganizationDetailForm?.orgRepDesignation,
      orgRepMobileNo: tempOrganizationDetailForm?.orgRepMobileNo,
      orgRepEmail: tempOrganizationDetailForm?.orgRepEmail,
    };

    let orgKycPlayload = {
      kycData: profileDetail?.kycDetail?.kycData,
    };

    let hasEmptyFields = Object.values(payload)?.some((value) => !value);

    let isBCIFieldEmpty =
      profileDetail?.kycDetail?.kycData[0]?.fieldValue?.trim() === "";

    if (
      hasEmptyFields ||
      (tempPersonalDetailForm?.roleRid === ROLE_LAW_FIRM_ADMIN &&
        isBCIFieldEmpty)
    ) {
      dispatch(setFormSubmitted(true));
      return;
    }
    if (
      tempOrganizationDetailFormError?.name ||
      tempOrganizationDetailFormError?.orgRepName ||
      tempOrganizationDetailFormError?.orgRepDesignation ||
      tempOrganizationDetailFormError.orgRepEmail ||
      tempOrganizationDetailFormError.orgRepMobileNo
    ) {
      return;
    }
    dispatch(setFormSubmitted(true));
    if(profileDetail?.kycDetail?.kycStatus !== KYC_VERIFIED){
      dispatch(orgKycThunk(orgKycPlayload))
        .unwrap()
        .then((data) => {
          refreshKycDetails();
        })
        .catch();
    }
    updateProfileDetail(tempOrganizationDetailForm, payload, true);
  };

  const kycInputChange = (index: number, key: string, value: any) => {
    dispatch(editKycData({ index, key, value }));
  };

  const addKycFile = async (file: File, index: number) => {
    try {
      if (!file) {
        throw Error("File list is null");
      }

      const args: any = [
        'kyc'
      ];

      const { actualFileName, uploadedFileName, mineType } =
        (await FileService.apiUserKycFileUpload(file, args)) as any;

      dispatch(
        editKycData({
          index: index,
          key: "attachment",
          value: uploadedFileName,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const removeKycFile = (index: number) => {
    dispatch(
      editKycData({
        index: index,
        key: "attachment",
        value: '',
      })
    );
  }

  const profileInputRef = useRef<HTMLInputElement>(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState<string | undefined>(undefined);
  const handleProfilePictureChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      throw Error("File list is null");
    } else {
      addProfileFile(e.target.files[0]);
    }
  };
  const addProfileFile = async (file: File) => {
    try {
      if (!file) {
        throw Error("File list is null");
      }

      const args: any = [
        'profile'
      ];

      const { actualFileName, uploadedFileName, mineType } =
        (await FileService.apiUserKycFileUpload(file, args)) as any;

      dispatch(
        setTempPersonalDetailForm({
          ...tempPersonalDetailForm,
          profilePic: uploadedFileName,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const loadProfilePicture = async (fileName: string) : Promise<string | undefined> => {
    try {
      const res: ArrayBuffer = (await FileService.downloadUserFile([
        tempPersonalDetailForm?.uniqueId,
        'profile',
        fileName,
      ])) as ArrayBuffer;
      const url = window.URL.createObjectURL(new Blob([res]));
      return url;
    } catch (error) { throw error }
  };

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const url = await loadProfilePicture(tempPersonalDetailForm?.profilePic);
        dispatch(
          setTempPersonalDetailForm({
            ...tempPersonalDetailForm,
            profilePictureUrl: url,
          })
        );
      } catch (error) {
        dispatch(
          setTempPersonalDetailForm({
            ...tempPersonalDetailForm,
            profilePictureUrl: '/user-placeholder.png',
          })
        );
      }
    };

    if(tempPersonalDetailForm?.profilePic){
      fetchProfilePicture();
    }
  }, [tempPersonalDetailForm?.profilePic]);

  useEffect(() => {
    dispatch(setTempPersonalDetailForm(profileDetail.personalDetail));
    dispatch(setTempOrganizationDetailForm(profileDetail.orgDetail));
  }, []);

  return (
    <div className="h-100 bg-white overflow-auto pb-5">
      <div className="p-2 p-md-4 bg-white d-flex flex-column justify-content-center align-items-center mx-auto" style={{maxWidth: '400px'}}>
        {/* <Button
          className="d-inline-block fw-bold position-absolute m-3 m-md-5 top-0 end-0"
          variant="text"
          sx={{ textTransform: "none" }}
        >
          <img src={penIcon} alt="change user type" />
          <span className="fs-normal-med"> Change user type</span>
        </Button> */}

        <input
          id="input-profile-picture"
          type="file"
          accept=".jpg,.jpeg,.png" // Specify accepted file types if needed
          onChange={(e) => handleProfilePictureChange(e)}
          style={{ display: "none" }}
          ref={profileInputRef}
        />
        <div className="position-relative">
          <img
            className="profile-picture"
            src={
              tempPersonalDetailForm?.profilePic
              ? tempPersonalDetailForm?.profilePictureUrl
              : '/user-placeholder.png'
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/user-placeholder.png';
            }}
            alt="..."
          />

          <div className="position-absolute top-0 end-0 p-1" style={{border: '1px solid #DDE1FF', backgroundColor: '#FFF', borderRadius: '50px', cursor: 'pointer'}} onClick={() => profileInputRef.current?.click()}>
            <CameraEnhanceOutlinedIcon color="primary" />
          </div>
        </div>

        {/* <Box
          className="rounded-circle p-2  border-black border-3 position-relative"
          width={"93.9px"}
          height={"93.9px"}
        >
          <PersonIcon className="p-1 bg-light-blue1 rounded-circle" color="primary" fontSize="large" style={{fontSize:"100px"}} />
        </Box> */}
        {/* <div className="fs-sm-lg-semibold">Organization</div> */}
        <Box className="my-2" sx={{ minWidth: 350 }}>
          <Tabs value={selectedProfileTab} onChange={handleChange} centered>
            <Tab
              icon={<img src={profileIcon} alt="profile icon" />}
              iconPosition="start"
              label="Personal details"
              sx={{ textTransform: "none" }}
            />
            {(profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN ||
              profileDetail?.personalDetail?.roleRid ===
                ROLE_LAW_FIRM_ADMIN) && (
              <Tab
                icon={<img src={businessIcon} alt="business icon" />}
                iconPosition="start"
                label={
                  profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN
                    ? "Organization details"
                    : "Law Firm details"
                }
                sx={{ textTransform: "none" }}
              />
            )}
          </Tabs>
          {selectedProfileTab === 1 && (
            <div className="pe-3 pb-3">
              <div className="d-flex flex-column gap-3  py-4">
                <TextField
                  variant="standard"
                  type="text"
                  label={
                    profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN
                      ? "Organization Name"
                      : "Law Firm Name"
                  }
                  sx={MuiTextFieldBorderStyle}
                  id="name"
                  name="name"
                  value={tempOrganizationDetailForm?.name}
                  onChange={orgDetailInputChange}
                  error={
                    ((!tempOrganizationDetailForm?.name ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted) ||
                    tempOrganizationDetailFormError?.name
                      ? true
                      : false
                  }
                  helperText={
                    (!tempOrganizationDetailForm?.name ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? "This field is mandatory"
                      : tempOrganizationDetailFormError?.name
                  }
                />
                {/* <TextField
                  variant="standard"
                  type="text"
                  label="GSTIN"
                  sx={MuiTextFieldBorderStyle}
                  id="GSTIn"
                  name="GSTIn"
                  value={organizationDetail?.GSTIn}
                  onChange={orgDetailInputChange}
                /> */}

                <TextField
                  variant="standard"
                  type="text"
                  label="Representative Name"
                  sx={MuiTextFieldBorderStyle}
                  id="orgRepName"
                  name="orgRepName"
                  value={tempOrganizationDetailForm?.orgRepName}
                  onChange={orgDetailInputChange}
                  error={
                    ((!tempOrganizationDetailForm?.orgRepName ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted) ||
                    tempOrganizationDetailFormError?.orgRepName
                      ? true
                      : false
                  }
                  helperText={
                    (!tempOrganizationDetailForm?.orgRepName ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? "This field is mandatory"
                      : tempOrganizationDetailFormError?.orgRepName
                  }
                />

                <TextField
                  variant="standard"
                  type="text"
                  label="Designation"
                  sx={MuiTextFieldBorderStyle}
                  id="orgRepDesignation"
                  name="orgRepDesignation"
                  value={tempOrganizationDetailForm?.orgRepDesignation}
                  onChange={orgDetailInputChange}
                  error={
                    ((!tempOrganizationDetailForm?.orgRepDesignation ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted) ||
                    tempOrganizationDetailFormError?.orgRepDesignation
                      ? true
                      : false
                  }
                  helperText={
                    (!tempOrganizationDetailForm?.orgRepDesignation ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? "This field is mandatory"
                      : tempOrganizationDetailFormError?.orgRepDesignation
                  }
                />

                <TextField
                  variant="standard"
                  type="text"
                  label="Mobile Number"
                  sx={MuiTextFieldBorderStyle}
                  id="orgRepMobileNo"
                  name="orgRepMobileNo"
                  value={tempOrganizationDetailForm?.orgRepMobileNo}
                  onChange={orgDetailInputChange}
                  error={
                    ((!tempOrganizationDetailForm?.orgRepMobileNo ||
                      !tempOrganizationDetailForm) &&
                      isFormSubmitted) ||
                    tempOrganizationDetailFormError?.orgRepMobileNo
                      ? true
                      : false
                  }
                  helperText={
                    (!tempOrganizationDetailForm?.orgRepMobileNo ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? "This field is mandatory"
                      : tempOrganizationDetailFormError?.orgRepMobileNo
                  }
                />

                <TextField
                  variant="standard"
                  type="text"
                  label="Email"
                  sx={MuiTextFieldBorderStyle}
                  id="orgRepEmail"
                  name="orgRepEmail"
                  value={tempOrganizationDetailForm?.orgRepEmail}
                  onChange={orgDetailInputChange}
                  error={
                    ((!tempOrganizationDetailForm?.orgRepEmail ||
                      !tempOrganizationDetailForm) &&
                      isFormSubmitted) ||
                    tempOrganizationDetailFormError?.orgRepEmail
                      ? true
                      : false
                  }
                  helperText={
                    (!tempOrganizationDetailForm?.orgRepEmail ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? "This field is mandatory"
                      : tempOrganizationDetailFormError?.orgRepEmail
                  }
                />

                <TextField
                  variant="standard"
                  sx={MuiTextFieldBorderStyle}
                  type="text"
                  label="Address"
                  id="address"
                  name="address"
                  value={tempOrganizationDetailForm?.address}
                  onChange={orgDetailInputChange}
                  error={
                    (!tempOrganizationDetailForm?.address ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? true
                      : false
                  }
                  helperText={
                    (!tempOrganizationDetailForm?.address ||
                      !tempOrganizationDetailForm) &&
                    isFormSubmitted
                      ? "This field is mandatory"
                      : ""
                  }
                />

                {profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN &&
                  ![KYC_VERIFIED].includes(
                    profileDetail?.kycDetail?.kycStatus
                  ) && (
                    <>
                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        Certificate Of Incorporation
                      </p>
                      <FileInputUI
                        index={0}
                        fileName={
                          profileDetail?.kycDetail?.kycData[0]?.attachment
                        }
                        addFile={addKycFile}
                        removeFile={removeKycFile}
                      />

                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        Letter Of Authorisation
                      </p>
                      <FileInputUI
                        index={1}
                        fileName={
                          profileDetail?.kycDetail?.kycData[1]?.attachment
                        }
                        addFile={addKycFile}
                        removeFile={removeKycFile}
                      />

                      <>
                        <p className="fs-normal-semibold text-primary p-0 m-0">
                          Authorised Person's ID proof
                        </p>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel>KYC Type</InputLabel>
                          <Select
                            name="kycType"
                            value={
                              profileDetail?.kycDetail?.kycData?.[2]?.kycType
                            }
                            label="Role"
                            onChange={(e) =>
                              kycInputChange(2, "kycType", e.target.value)
                            }
                          >
                            <MenuItem value={"PAN"}>PAN</MenuItem>
                            <MenuItem value={"AADHAAR"}>AADHAAR</MenuItem>
                          </Select>
                        </FormControl>
                        {profileDetail?.kycDetail?.kycData?.[2]?.kycType &&
                          <TextField
                            variant="standard"
                            type="text"
                            label={`${profileDetail?.kycDetail?.kycData?.[2]?.kycType} No`}
                            sx={MuiTextFieldBorderStyle}
                            id="kycValue"
                            name="kycValue"
                            value={
                              profileDetail?.kycDetail?.kycData?.[2]?.fieldValue
                            }
                            onChange={(e) =>
                              kycInputChange(2, "fieldValue", e.target.value)
                            }
                          /> }

                          <FileInputUI
                            index={2}
                            fileName={
                              profileDetail?.kycDetail?.kycData?.[2]?.attachment
                            }
                            addFile={addKycFile}
                            removeFile={removeKycFile}
                          />
                        </>
                    </>
                  )}

                {profileDetail?.personalDetail?.roleRid ===
                  ROLE_LAW_FIRM_ADMIN &&
                  ![KYC_VERIFIED].includes(
                    profileDetail?.kycDetail?.kycStatus
                  ) && (
                    <>
                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        BCI Enrollment Number
                      </p>
                      <TextField
                        variant="standard"
                        type="text"
                        label="BCI no"
                        sx={MuiTextFieldBorderStyle}
                        id="kycValue"
                        name="kycValue"
                        value={
                          profileDetail?.kycDetail?.kycData?.[0]?.fieldValue
                        }
                        onChange={(e) =>
                          kycInputChange(0, "fieldValue", e.target.value)
                        }
                        error={
                          profileDetail?.kycDetail?.kycData?.[0]?.fieldValue?.trim() ===
                            "" && isFormSubmitted
                            ? true
                            : false
                        }
                        helperText={
                          profileDetail?.kycDetail?.kycData?.[0]?.fieldValue?.trim() ===
                            "" && isFormSubmitted
                            ? "This field is mandatory"
                            : ""
                        }
                      />

                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        GST
                      </p>
                      <TextField
                        variant="standard"
                        type="text"
                        label="GST No"
                        sx={MuiTextFieldBorderStyle}
                        id="kycValue"
                        name="kycValue"
                        value={
                          profileDetail?.kycDetail?.kycData?.[1]?.fieldValue
                        }
                        onChange={(e) =>
                          kycInputChange(1, "fieldValue", e.target.value)
                        }
                      />
                      <FileInputUI
                        index={1}
                        fileName={
                          profileDetail?.kycDetail?.kycData?.[1]?.attachment
                        }
                        addFile={addKycFile}
                        removeFile={removeKycFile}
                      />

                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        PAN
                      </p>
                      <TextField
                        variant="standard"
                        type="text"
                        label="PAN no"
                        sx={MuiTextFieldBorderStyle}
                        id="kycValue"
                        name="kycValue"
                        value={
                          profileDetail?.kycDetail?.kycData?.[2]?.fieldValue
                        }
                        onChange={(e) =>
                          kycInputChange(2, "fieldValue", e.target.value)
                        }
                      />
                      <FileInputUI
                        index={2}
                        fileName={
                          profileDetail?.kycDetail?.kycData?.[2]?.attachment
                        }
                        addFile={addKycFile}
                        removeFile={removeKycFile}
                      />
                    </>
                  )}
              </div>
              <div className="d-flex justify-content-end gap-4 ">
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={closeEditProfile}
                >
                  Cancel
                </Button>
                <Button
                  className="fw-bold"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={saveOrganizationDetails}
                  disabled={isLoading}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
          {selectedProfileTab === 0 && (
            <div className="pe-3 pb-3">
              <div className="d-flex flex-column gap-3  py-4 ">
                <TextField
                  variant="standard"
                  type="text"
                  label="Name"
                  sx={MuiTextFieldBorderStyle}
                  id="fullName"
                  name="fullName"
                  value={tempPersonalDetailForm?.fullName}
                  onChange={personalDetailInputChange}
                  error={
                    (!tempPersonalDetailForm?.fullName && isFormSubmitted) ||
                    tempPersonalDetailFormError?.fullName
                      ? true
                      : false
                  }
                  helperText={
                    !tempPersonalDetailForm?.fullName && isFormSubmitted
                      ? "This field is mandatory"
                      : tempPersonalDetailFormError?.fullName
                  }
                />

                <TextField
                  disabled
                  variant="standard"
                  type="text"
                  label="Email"
                  sx={MuiTextFieldBorderStyle}
                  id="email"
                  name="email"
                  value={tempPersonalDetailForm?.email}
                  onChange={personalDetailInputChange}
                  error={
                    (!tempPersonalDetailForm?.email && isFormSubmitted) ||
                    tempPersonalDetailFormError?.email
                      ? true
                      : false
                  }
                  helperText={
                    !tempPersonalDetailForm?.email && isFormSubmitted
                      ? "This field is mandatory"
                      : tempPersonalDetailFormError?.email
                  }
                />

                <TextField
                  disabled
                  variant="standard"
                  type="text"
                  label="Phone number"
                  sx={MuiTextFieldBorderStyle}
                  id="mobileNo"
                  name="mobileNo"
                  value={tempPersonalDetailForm?.mobileNo}
                  onChange={personalDetailInputChange}
                  error={
                    (!tempPersonalDetailForm?.mobileNo && isFormSubmitted) ||
                    tempPersonalDetailFormError?.mobileNo
                      ? true
                      : false
                  }
                  helperText={
                    !tempPersonalDetailForm?.mobileNo && isFormSubmitted
                      ? "This field is mandatory"
                      : tempPersonalDetailFormError?.mobileNo
                  }
                />
                <TextField
                  label="Date of Birth"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="standard"
                  sx={MuiTextFieldBorderStyle}
                  id="dob"
                  name="dob"
                  value={tempPersonalDetailForm?.dob}
                  onChange={personalDetailInputChange}
                  error={
                    !tempPersonalDetailForm?.dob && isFormSubmitted
                      ? true
                      : false
                  }
                  helperText={
                    !tempPersonalDetailForm?.dob && isFormSubmitted
                      ? "This field is mandatory"
                      : ""
                  }
                />
                <TextField
                  variant="standard"
                  type="text"
                  label="Address"
                  sx={MuiTextFieldBorderStyle}
                  id="address"
                  name="address"
                  value={tempPersonalDetailForm?.address}
                  onChange={personalDetailInputChange}
                  error={
                    !tempPersonalDetailForm?.address && isFormSubmitted
                      ? true
                      : false
                  }
                  helperText={
                    !tempPersonalDetailForm?.address && isFormSubmitted
                      ? "This field is mandatory"
                      : ""
                  }
                />

                {tempPersonalDetailForm?.roleRid === ROLE_INDIVIDUAL &&
                  ![KYC_VERIFIED].includes(
                    profileDetail?.kycDetail?.kycStatus
                  ) && (
                    <>
                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        KYC Document
                      </p>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>KYC Type</InputLabel>
                        <Select
                          name="kycType"
                          value={
                            profileDetail?.kycDetail?.kycData?.[0]?.kycType
                          }
                          label="Role"
                          onChange={(e) =>
                            kycInputChange(0, "kycType", e.target.value)
                          }
                        >
                          <MenuItem value={"PAN"}>PAN</MenuItem>
                          <MenuItem value={"AADHAAR"}>AADHAAR</MenuItem>
                        </Select>
                      </FormControl>
                      {profileDetail?.kycDetail?.kycData?.[0]?.kycType &&
                        <TextField
                          variant="standard"
                          type="text"
                          label={`${profileDetail?.kycDetail?.kycData?.[0]?.kycType} No`}
                          sx={MuiTextFieldBorderStyle}
                          id="kycValue"
                          name="kycValue"
                          value={
                            profileDetail?.kycDetail?.kycData?.[0]?.fieldValue
                          }
                          onChange={(e) =>
                            kycInputChange(0, "fieldValue", e.target.value)
                          }
                        /> }

                      <FileInputUI
                        index={0}
                        fileName={
                          profileDetail?.kycDetail?.kycData?.[0]?.attachment
                        }
                        addFile={addKycFile}
                        removeFile={removeKycFile}
                      />
                    </>
                  )}

                  {tempPersonalDetailForm?.roleRid === ROLE_NEUTRAL &&
                  ![KYC_VERIFIED].includes(
                    profileDetail?.kycDetail?.kycStatus
                  ) && (
                    <>
                      <p className="fs-normal-semibold text-primary p-0 m-0">
                        Upload CV
                      </p>
                      <FileInputUI
                        index={0}
                        fileName={
                          profileDetail?.kycDetail?.kycData?.[0]?.attachment
                        }
                        addFile={addKycFile}
                        removeFile={removeKycFile}
                      />
                    </>
                  )}
              </div>
              <div className="d-flex justify-content-end gap-4 ">
                <Button
                  className="fs-normal-med"
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={closeEditProfile}
                >
                  Cancel
                </Button>
                <Button
                  className="fs-normal-med"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={updateUserData}
                  disabled={isLoading}
                >
                  Update
                </Button>
              </div>
            </div>
          )}
        </Box>
      </div>
      {/* Dialog Box to add users */}
      <Dialog open={showAddUserDialog}>
        <DialogTitle className="d-flex justify-content-between">
          <div className="fs-lg-semibold text-primary align-items-center">
            Add User
          </div>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box className="d-flex flex-column gap-3 px-4 mb-3 " width={500}>
            <TextField variant="standard" type="text" label="Name" />
            <TextField variant="standard" type="text" label="Email" />
            <TextField variant="standard" type="text" label="Phone number" />
            <FormControl variant="standard" fullWidth>
              <InputLabel>Role</InputLabel>
              <Select
                value={selectedRole}
                label="Role"
                onChange={(e) => {
                  handleRoleChange(e.target.value);
                }}
              >
                <MenuItem value={"CEO"}>CEO</MenuItem>
                <MenuItem value={"CEO"}>CEO</MenuItem>
                <MenuItem value={"CEO"}>CEO</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className="mx-3">
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button variant="contained" autoFocus onClick={handleCloseDialog}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateProfile;
