import React, { useEffect } from 'react'
import ViewResponse from './ViewResponse'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from '../../../../context/SnackbarContext';
import { AppDispatch, store } from '../../../../redux/store';
import CloseIcon from "@mui/icons-material/Close";
import { OPEN_EXPAND_CASE } from '../../../../constants/local-storage-constants';
import SecureStorage from '../../../../util/SecureStorage';
import { useDispatch } from 'react-redux';
import { DRAFT } from '../../../../constants/case-constants';
import { caseDetailThunk } from '../../../../redux/slice/caseSlice';
import CustomLoader from '../../../../components/common/CustomLoader';


function ResponseExpand() {
    const {caseId,responseId}=useParams();
    const dispatch: AppDispatch = useDispatch();

    const navigate=useNavigate()
    const {showSnackbar}=useSnackbar()

    const { caseFile,isLoading } = store.getState().case;

    const handleClose = () => {
        const uniqueId = caseFile.uniqueId;
        SecureStorage.setItem(OPEN_EXPAND_CASE, true)

        navigate(-1);
    };

    useEffect(()=>{
        dispatch(
            caseDetailThunk({
                draftStatus: 1,
                uniqueId: caseId as string,
            })
        ).unwrap().then(() => { }).catch((e: any) => {
            navigate(-1)
        });
    })

    
   

    if(responseId){
        return(
            <div className="d-flex flex-column vh-100 bg-light">
                <div className="d-flex align-items-center bg-light-blue1 px-4 py-2">
                    <div className="fs-sm-lg-light flex-grow-1 p-0 m-0">
                        Case {caseFile.caseId} /{" "}
                        <span className="text-capitalize ms-1">
                            {caseFile.resolutionMethod?.toLowerCase().replace(/_/g, " ")}
                        </span>
                    </div>
                    

                        <div className="cursor-pointer" onClick={handleClose}>
                            <CloseIcon color="primary" />
                        </div>
                
                </div>
                <div className="flex-grow-1 overflow-auto p-4">
                    <div
                        className="bg-white mx-auto"
                        style={{ width: "60%", borderRadius: "5px" }}
                    >
                        <ViewResponse 
                        selectedRespondent={responseId}
                        isExpanded={true}
                        />
                    </div>
                </div>
            </div>
        )
    }else{
        showSnackbar("Invalid response id")
        navigate(-1)
        return <></>
    }
  
}

export default ResponseExpand