import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Link, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SUPPORT_ICON from '../../assets/technical-support.png';
import { ExpandMore, HelpOutline, NavigateNext } from '@mui/icons-material';

export const FAQ_LIST = [
  {
    issue: "Unable to login",
    solution: "Check your Wi-Fi connection or internet strength. If the issue persists, contact support for further assistance."
  },
  {
    issue: "No Edit option is available for documents",
    solution: "An already edited document cannot be edited"
  },
  {
    issue: "Unable to view new notification",
    solution: "Try refreshing the page. If notifications still don't appear, re-login to your account."
  },
  {
    issue: "Unable to download the document",
    solution: "Documents cannot be downloaded for any reason"
  },
  {
    issue: "Unable to submit the document",
    solution: "Double-check that all required fields are filled in and the document format is compatible (JPG, PDF, PNG). If the issue persists, contact support."
  },
  {
    issue: "How to add more claimants?",
    solution: "When filing a case, choose your entity type. After selecting the entity type, an “Add Claimants” option will be available. Click on this option to include additional parties involved in the case."
  },
  {
    issue: "What details should be filed in the case details?",
    solution: "The “Case Details” section requires a chronological account of the events leading to the legal dispute. Briefly explain the situation, including key dates, actions taken by involved parties, and anything relevant to establishing your case."
  },
  {
    issue: "What happens after submitting a statement of claims/defence?",
    solution: "Once you submit your statement of claim or defence, it undergoes Scrutiny by our TruPact Team. This process ensures the statement adheres to our platform's guidelines and formatting requirements. After successful scrutiny, the statement will be sent back to you for confirmation before proceeding further."
  },
  {
    issue: "How to terminate the proceedings?",
    solution: "To terminate the proceedings, an email must be sent to “support@thetrupact.com” mentioning your reasons to terminate the proceedings."
  },
  {
    issue: "How to choose a neutral",
    solution: "If you’re a Claimant/First Party, please initiate the resolution phase. Click the “Select Neutral” button and choose the preferred neutral. If you’re a Respondent/Second Party, please wait for the Claimant/First Party to choose their preferred neutral. Once they have chosen their preferred neutral, you can proceed with choosing your preferred neutral."
  },
]

const SupportPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterFaqList, setFilterFaqList] = useState(FAQ_LIST);

  useEffect(() => {
    if(searchQuery?.length > 2){
      const filterList = FAQ_LIST.filter(item => {
        return item.issue.toLowerCase().includes(searchQuery.toLowerCase());
      });

      setFilterFaqList(filterList);
    } else {
      setFilterFaqList(FAQ_LIST);
    }
  }, [searchQuery]);

  return (
    <div className="h-100 ms-4">
      <div className="d-flex flex-row align-items-center">
        <div className="fs-sm-heading-med  me-4">Support</div>

        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link underline="none" href="/">
            <span className="fs-normal-light text-grey">Dashboard</span>
          </Link>
          <span className="fs-normal-light text-dark">Support</span>
        </Breadcrumbs>
      </div>
      <div className="bg-white h-100 overflow-y-scroll mt-3 mb-5">
        <div className="row g-0 justify-content-center pt-5 pb-4">
          <div className="col-lg-5 d-flex align-items-center flex-column gap-4">
            {/* <img src={SUPPORT_ICON} alt='...' height={100} /> */}
            <h4 className="fs-sm-heading-semibold p-0 m-0">
              <HelpOutline /> Support
            </h4>

            <TextField
              type="search"
              placeholder="Search for Answers.."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              fullWidth
            />
          </div>
        </div>

        <div className="row g-0 justify-content-center pt-3 pb-4 px-0 m-0">
          <div className="col-lg-6">
            {
              filterFaqList.length === 0
              ? <div className="d-flex justify-content-center">
                  <span className="fs-sm-lg-med text-center">No solutions found!</span>
                </div>
              : (
                filterFaqList.map((faq, faqIndex) => (
                  <Accordion key={faqIndex}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="fs-sm-lg-med">{faq.issue}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="fs-normal-light">{faq.solution}</span>
                    </AccordionDetails>
                  </Accordion>
                ))
              )
            }
          </div>
        </div>

        <div className="container">
          <div className="row gx-4 pt-3 pb-5 px-0 m-0">
            <div className="col-lg-6">
              <div className="d-flex gap-2 flex-column border rounded p-4 h-100">
                <h5 className="fs-lg-semibold p-0 m-0">Feedback</h5>
                <p className="fs-sm-lg-light p-0 m-0">
                  To provide your feedback, please feel free to reach out to us at <a href="mailto:feedback@thetrupact.com" className="custom-email fs-sm-lg-med text-primary">feedback@thetrupact.com</a>
                  </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex gap-2 flex-column border rounded p-4 h-100">
                <h5 className="fs-lg-semibold p-0 m-0">Contact us</h5>
                <p className="fs-sm-lg-light p-0 m-0">
                  Should you face any issue that you could not resolve with the above-given resources, please feel free to reach out to us at <a href="mailto:support@thetrupact.com" className="custom-email fs-sm-lg-med text-primary">support@thetrupact.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5"></div>
      </div>
    </div>
  );
}

export default SupportPage;