import {
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";

import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";
import PenIcon from "../../../../assets/icon/Pen button.png";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { PrayerModel } from "../../../../model/caseDocument/prayer";
import { useDispatch } from "react-redux";
import { AppDispatch, store } from "../../../../redux/store";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";



import PrayersRow from "./PrayersRow";

import { deletePrayers, setCounterPrayersExpanded, setPrayers, setPrayersExpanded } from "../../../../redux/slice/caseResponseSlice";
import ConditionalWrapper from "../../../../Layout/ConditionalWrapper";
import { useEffect, useState } from "react";

const PrayerList = ({ isCounterPrayer }: { isCounterPrayer?: boolean }) => {
  const dispatch: AppDispatch = useDispatch();


  let prayers = [] as any

  const { isRespondent, isRespondentAdvocate } = store.getState().case;


  const { caseFileResponseDetail, prayerExpanded, counterPrayerExpanded, caseFileResponse } = store.getState().caseResponse



  if (isCounterPrayer) {
    prayers = caseFileResponseDetail.prayer.counterPrayer
  } else {
    prayers = caseFileResponseDetail.prayer.prayers
  }



  const handleChangeAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (event.type === "click" || event.type === "keydown") {
        if (isCounterPrayer) {

          dispatch(setCounterPrayersExpanded(counterPrayerExpanded === panel ? -1 : panel));
        } else {
          dispatch(setPrayersExpanded(prayerExpanded === panel ? -1 : panel));
        }
      }
    };

  const handleDeletePrayers = (index: number) => {
    dispatch(deletePrayers(index));
  };

  return (
    <div className="d-flex flex-column  w-100 overflow-auto">
      <Box className="d-flex flex-column justify-content-center align-items-center gap-2">
      <div className={`d-flex flex-column ${isCounterPrayer ? 'w-75' : 'w-100'}`}>
      {prayers?.map((prayers: PrayerModel, i: number) => (


        <Accordion
          key={i}
          className="border-0 p-0"
          expanded={isCounterPrayer ? counterPrayerExpanded === i : prayerExpanded === i}
          onChange={handleChangeAccordion(i)}

        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className="w-100 p-0"
          >
            <div className="row bg-white p-0 m-0  w-100 ">
              {(isCounterPrayer ? counterPrayerExpanded === i : prayerExpanded === i) ? (
                <div className="fs-med-slg-medium  d-flex justify-content-center align-items-center gap-2">
                  <span>

                  {isCounterPrayer ? "Counter Prayer" : "Prayer"}
                  </span>
                  {!isCounterPrayer ? (
                    <Tooltip title="The reason and percentages you give us in response are confidential.">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                  ):null}
                </div>
              ):null}
              <div className="col-9 row  ">
              
                <div className="col-3 d-flex gap-3 align-items-center">
                  <img src={DotIcon} alt="dot" width={24} height={24} />
                  <Box
                    className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                    width={28.21}
                    height={28.21}
                  >
                    {i + 1}
                  </Box>
                </div>
                
                <ConditionalWrapper className="col-9 d-flex align-items-center" condition={(!(isCounterPrayer ? counterPrayerExpanded===i : prayerExpanded === i))}>
                  {prayers.prayerDetail && (
                    <Box className=" text-grey  text-truncate" maxWidth={400}>
                      {prayers.prayerDetail}
                    </Box>
                  )}
         
                  {!isRespondent ? (
                    <IconButton className="p-0 ">
                      <img src={PenIcon} alt="pen" width={24} height={24} />
                    </IconButton>
                  ) : null}
                </ConditionalWrapper>

               

                <ConditionalWrapper className="col-9 fs-med-slg-medium  p-0 m-0" condition={prayerExpanded === i && !isRespondent && !isRespondentAdvocate }>
                  {isCounterPrayer ? "Counter Prayers" : "Prayers"}
                </ConditionalWrapper>

                <ConditionalWrapper className="ms-5 mt-3" condition={prayerExpanded === i && (isRespondent||isRespondentAdvocate) && !isCounterPrayer}>
                  {prayers.prayerDetail}
                </ConditionalWrapper>
              

              </div>


              <div className="col-3 p-0  d-flex gap-2 justify-content-end align-items-center ">
                {prayers.intensity ? (
                  prayers.intensity <= 3 ? (
                    <div className="bg-light-blue1 fs-sm-light px-3 rounded-4">low</div>
                  ) : prayers.intensity > 3 && prayers.intensity <= 7 ? (
                    <div className="bg-light-blue1 fs-sm-light  px-3 rounded-4">medium</div>
                  ) : (
                    <div className="bg-light-red fs-sm-light text-red px-3 rounded-4 ">
                      high
                    </div>
                  )
                ) : (
                  <div className="bg-light-blue1 px-3 rounded-4">low</div>
                )}
                {isCounterPrayer ? (
                  <>
                    <IconButton
                      className="p-0"
                      onClick={() => handleDeletePrayers(i)}
                    >
                      <Box
                        className={`${prayerExpanded === i
                          ? "bg-light-red "
                          : " bg-light-blue1"
                          } p-2 rounded-circle d-flex justify-content-center align-items-center`}
                        width={40}
                        height={40}
                      >
                        <DeleteOutlinedIcon fontSize="large" className={`${prayerExpanded === i ? "text-danger " : null}`} />

                      </Box>
                    </IconButton>
                  </>
                ) : null}
              </div>
            </div>
          </AccordionSummary>

          < >
            <div className="d-flex h-100 flex-column">
              <PrayersRow
                index={i}
                prayers={prayers}
                isCounterPrayer={isCounterPrayer ? true : false}
                showObjectionsBox={isRespondent || isRespondentAdvocate}
              />
            </div>
          </>
        </Accordion>
      ))
      }
    </div >

      </Box>
      </div>
   
  );
};
export default PrayerList;
