import React, { Suspense, useEffect, useState } from "react";
import SideBarLayout from "../components/common/SideNav";
import CustomLoader from "../components/common/CustomLoader";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import CustomDialog from "../components/CustomDialog";
import { useUserContext } from "../context/UserContext";
import { CASE_LIST, LOGIN, PROFILE } from "../routes/routes";
import { AppDispatch, RootState, store } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { FIRST_TIME_NEUTRAL_KEY, LSK_USER_DETAIL, OPEN_DIALOG } from "../constants/local-storage-constants";
import SecureStorage from "../util/SecureStorage";
import {
  getUserKycThunk,
  initKycData,
  initLawFirmKycData,
  initNeutralKycData,
  initOrgKycData,
  profileDetailThunk,
  setProfileDetail,
  setProfileUpdated,
  setTempPersonalDetailForm,
  updateContextTypeThunk,
} from "../redux/slice/userSlice";
import { PersonalDetail } from "../model/PersonalDetail";
import CustomAppBar from "../components/common/AppBar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ROLE_ADMIN, ROLE_INDIVIDUAL, ROLE_LAW_FIRM_ADMIN, ROLE_NEUTRAL, ROLE_ORG_ADMIN, ROLE_SUPER_ADMIN } from "../constants/nav-constants";
import FileService from "../network/FileService";
import { caseCountThunk, caseListThunk } from "../redux/slice/caseListSlice";
import JoyRide from "../components/respondent-tour/Joyride";
import { dashboardThunk } from "../redux/slice/dashboardSlice";
import DrawerLayout from "./DrawerLayout";
import { Button } from "@mui/material";
import { NEUTRAL_ROLE } from "../constants/case-constants";
import DialogLayout from "./DialogLayout";

const MainLayout = () => {
  const [open, setOpen] = useState(false);
  const [openActivities, setOpenActivities] = useState(false);

  const toggleActivities = () => {
    if (openActivities) {
      setOpenActivities(false);
    } else {
      setOpenActivities(true);
    }
  }

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [openInfoDialog, setInfoOpenDialog] = useState(false);
  const { isLoggedIn } = useUserContext();
  const dispatch: AppDispatch = useDispatch();
  const { profileDetail, profileUpdated } = store.getState().profileDetail

  const navigate = useNavigate();

  const loadProfilePicture = async (
    args: string[]
  ): Promise<string | undefined> => {
    try {
      const res: ArrayBuffer = (await FileService.downloadUserFile(
        args
      )) as ArrayBuffer;
      const url = window.URL.createObjectURL(new Blob([res]));
      return url;
    } catch (error) {
      throw error;
    }
  };



  const updateProfileDetails = async (profileData: any) => {

    let tempKyc={} as any

   await dispatch(getUserKycThunk())
      .unwrap()
      .then((data: any) => {
        if (data) {
          const { profileDetail } = store.getState().profileDetail;

          if (data.kycData) {
            tempKyc = {
                kycStatus: data.kyc?.kycStatus,
                kycData: data.kycData,
              }
          } else {
            const kycData =
              profileDetail?.personalDetail?.roleRid === ROLE_INDIVIDUAL
                ? initKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN
                  ? initOrgKycData
                  : profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN
                    ? initLawFirmKycData
                    : profileDetail?.personalDetail?.roleRid === ROLE_NEUTRAL
                      ? initNeutralKycData
                      : [];

           
              tempKyc={
                kycStatus: 0,
                kycData: kycData,
              }
            };
          }
        
      })
      .catch((error: any) => { });




    let userDetail = {
      kycDetail:tempKyc,
      personalDetail: {
        ...profileDetail.personalDetail,
        ...profileData.personalDetail,
      },
      orgDetail: { ...profileDetail.orgDetail, ...profileData.orgDetail },
      openDialog: false,
    };

    if (profileData?.personalDetail?.profilePic) {
      const url = await loadProfilePicture([
        profileData?.personalDetail?.uniqueId,
        "profile",
        profileData?.personalDetail?.profilePic,
      ]);

      userDetail = {
        ...userDetail,
    
        personalDetail: {
          ...userDetail.personalDetail,
          profilePictureUrl: url,
        },
        openDialog: true,

      };
    }

    SecureStorage.setItem(LSK_USER_DETAIL, userDetail);

    if (!profileData?.personalDetail?.currentContextType) {
      setOpenDialog(true);
    } else {
      if (profileData?.personalDetail?.roleRid === ROLE_NEUTRAL) {
        dispatch(caseCountThunk({
          position: NEUTRAL_ROLE
        }));
      } else {
        dispatch(caseCountThunk(null));
      }
    }

    dispatch(setProfileDetail(userDetail));
    return;
  }

  const fetchProfileDetails = async () => {
    dispatch(profileDetailThunk())
      .unwrap()
      .then(async (data: any) => {
        updateProfileDetails(data);
      })
      .catch((error: any) => { });
  }

  useEffect(() => {
    if (!isLoggedIn) return;
    // let userData: any = SecureStorage.getItem(LSK_USER_DETAIL);
    // if (userData?.personalDetail) {
    //   updateProfileDetails(userData);
    // } else {
    //   fetchProfileDetails();
    // }
    
    
    fetchProfileDetails();
  }, []);


  

  const onNextClick = (personalDetail: PersonalDetail) => {
    let body = {
      contextType: personalDetail.currentContextType,
    };
    dispatch(updateContextTypeThunk(body))
      .unwrap()
      .then((data: any) => {
        dispatch(profileDetailThunk())
          .unwrap()
          .then((data: any) => {
            let userDetail = {
              personalDetail: {
                ...profileDetail.personalDetail,
                ...data.personalDetail,
              },
              orgDetail: { ...profileDetail.orgDetail, ...data.orgDetail },
              kycDetail: { ...profileDetail.kycDetail },
            };

            SecureStorage.setItem(LSK_USER_DETAIL, userDetail);

            dispatch(setProfileDetail(userDetail));
            navigate(PROFILE);
          })
          .catch((error: any) => { });
        dispatch(setProfileUpdated(true));
        setOpenDialog(false);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (profileUpdated) {
      SecureStorage.setItem(LSK_USER_DETAIL, profileDetail);
      dispatch(setProfileUpdated(false));
    }
  }, [profileUpdated]);

  

  if (isLoggedIn)
    return (
      <Suspense fallback={<CustomLoader />}>
        <div
          className={`vh-100 bg-light d-flex position-relative
            
          `}
        >
          {/* <JoyRide/>    */}
          {/* <div
          className={` vh-100 bg-light ${open ? "marginOpen" : "marginClose"}`}
        > */}

          <SideBarLayout open={open} toggleSidebar={toggleSidebar} />

          <div className="d-flex flex-column w-100 h-100">
            <CustomAppBar onClick={toggleSidebar} />
            <div className="overflow-hidden h-100 pe-4 ">
              <Outlet />
            </div>
          </div>
          {/* </SideBarLayout> */}

          <div
            style={{ zIndex: 10000 }}
            className="d-block d-md-none position-absolute bottom-0 end-0 rounded bg-primary text-white cursor-pointer p-2 my-5 mx-3"
            onClick={toggleSidebar}
          >
            {open ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
          </div>
        </div>
        {/* //TODO: Activities is not implemented */}
        {/* <div>
          {window.location.pathname === '/' ?
            <div style={{
              position: 'fixed',
              right: openActivities ? '300px' : '-45px',
              top: '50%',
              zIndex: '1900',
              transform: 'rotate(270deg)',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}>
              <Button
                sx={{
                  textTransform: 'none',
                  borderRadius: '5px 5px 0 0',
                  width: '125px', height: '30px',
                  backgroundColor: '#FFF',
                  boxShadow: '0px -4px 4px 0px #00000040'
                }}
                onClick={toggleActivities}>
                <span className="fs-sm-lg-med text-primary">Activities</span>
              </Button>
            </div> : ''}

          <DrawerLayout
            open={openActivities}
            drawerWidth={'350px'}
            title="Activities"
            handleClose={toggleActivities}>

          </DrawerLayout>

        </div> */}
        <CustomDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(!openDialog);
          }}
          personalDetail={profileDetail.personalDetail}
          onNextClick={onNextClick}
        />

        {/* //?for neutral */}
        {/* <DialogLayout
          title="Welcome!"
          open={
          }
          handleClose={() => {

          }}
          negativeActions={
            [
              {
                label: "close",
                onClick: () => {
                }
              }
            ]
          }
          positiveActions={[
            {
              label: "View Case",
              onClick: () => {
                navigate(CASE_LIST)
                setInfoOpenDialog(false)
                SecureStorage.setItem(OPEN_DIALOG, true)
              }
            }
          ]}

        >
          <div className="d-flex flex-column">
            <span >Hello {profileDetail?.personalDetail?.fullName}</span>
            <p>
              A case has been handedover to you!
            </p>
          </div>
        </DialogLayout> */}

        {/* //?for Lawyer */}
        {/* <DialogLayout
          title="Welcome!"
          open={
          }
          handleClose={() => {

          }}
          negativeActions={
            [
              {
                label: "close",
                onClick: () => {
                }
              }
            ]
          }
          positiveActions={[
            {
              label: "View Case",
              onClick: () => {
                navigate(CASE_LIST)
                setInfoOpenDialog(false)
                SecureStorage.setItem(OPEN_DIALOG, true)
              }
            }
          ]}

        >
          <div className="d-flex flex-column">
            <span >Hello {profileDetail?.personalDetail?.fullName}</span>
            <p>
              A case has been handedover to you!
            </p>
          </div>
        </DialogLayout> */}
      </Suspense >
    );

  return <Navigate to={LOGIN} />;
};

export default MainLayout;
