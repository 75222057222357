import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";
import { initNotification } from "../../model/notification/notification";

export const getNotificationThunk = createAsyncThunk(
  "/user/notification",
  async (thunkApi) => await NetworkService.getNotificationApi()
);
export const updateNotificationThunk = createAsyncThunk(
  "/user/notification/markAsRead",
  async (data: any, thunkApi) =>
    await NetworkService.updateNotification({ queryParams: { idList: data} })
);

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: initNotification,
  reducers: {
    setNotificationCount: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationThunk.pending, (state, action) => {
        return { ...state, isLoading: true };
      })
      .addCase(getNotificationThunk.fulfilled, (state, action) => {
        const data = action.payload as any;
           

        return {
          ...state,
          notificationList: data.notificationList,
          notificationCount: data.unReadNotification,
          isLoading: false,
        };
      })
      .addCase(getNotificationThunk.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(updateNotificationThunk.pending, (state, action) => {
        return { ...state, isLoading: true };
      })
      .addCase(updateNotificationThunk.fulfilled, (state, action) => {
        const data = action.payload as any;
           
        return {
          ...state,
          isLoading: false,
        };
      })
      .addCase(updateNotificationThunk.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      });
  },
});

export const { setNotificationCount } = notificationSlice.actions;
