import { RESPONDENT_TYPE_ORGANIZATION } from "../../constants/case-constants";
import { Claimant, Respondent } from "../../model/fileCase";
import { validateEmail, validatePhoneNumber } from "../validation";

interface ValidationResult {
  tempList: Respondent[];
  isValid: boolean;
  errorMessageList: string[];
}

// ...

const validateRespondentList = (
  respondentList: Respondent[],
  claimantList?: Claimant[]
): ValidationResult => {
  let isValid = true;

  let errorMessageList = [] as string[];

  const tempList: Respondent[] = respondentList.map((respondent, index) => {
    const error = {} as any;

    const compareWithClaimant = (email?: string, phoneNumber?: string) => {
      claimantList?.forEach((claimant) => {
        if (email && email === claimant.email) {
          error.email = "The claimant cannot also be the respondent";
          errorMessageList.push("The claimant cannot also be the respondent");
          isValid = false;
        }
        if (phoneNumber && phoneNumber === claimant.mobileNo) {
          error.mobileNo = "The claimant cannot also be the respondent";
          errorMessageList.push("The claimant cannot also be the respondent");
          isValid = false;
        }
      });
    };
    const isRepeatedMobileNo = respondentList.some(
      (c, i) => i !== index && c.mobileNo === respondent.mobileNo
    );
    const isRepeatedEmail = respondentList?.some(
      (c, i) => i !== index && c.email === respondent.email
    );

    if (isRepeatedMobileNo) {
      errorMessageList.push(
        "Respondent with the same Mobile Number already exists"
      );
      isValid = false;
    }
    if (isRepeatedEmail) {
      errorMessageList.push("Respondent with the same email already exists");
      isValid = false;
    }

    const validateField = (field: keyof Respondent, errorMessage: string) => {
      if (!respondent[field]) {
        error[field] = errorMessage;
        errorMessageList.push(errorMessage);

        isValid = false;
        
      }
    };

    validateField("name", "Name is required");

    // Check mobileNo is present before validation
    if (!respondent.mobileNo) {
      error.mobileNo = "Phone number is required";
      errorMessageList.push("Phone number is required");
      isValid = false;
    } else {
      if (respondent.mobileNo && validatePhoneNumber(respondent.mobileNo)) {
        let err = validatePhoneNumber(respondent.mobileNo);
        error.mobileNo = err;
        errorMessageList.push(err);
        isValid = false;
      }
    }
    // Check Email is present before validation
    if (!respondent.email) {
      error.email = "Email  is required";
      errorMessageList.push("Email  is required");
      isValid = false;
    } else {
      let err = validateEmail(respondent.email);
      if (respondent.email && err) {
        error.email = err;
        errorMessageList.push(err);
        isValid = false;
      }
    }

    compareWithClaimant(respondent.email, respondent.mobileNo);

    // validateField("address", "Address is required");

    if (respondent.respondentType === RESPONDENT_TYPE_ORGANIZATION) {
      validateField(
        "orgRepresentative",
        "Organization Representative is required"
      );
      validateField("designation", "Organization Designation is required");
      //?removed this as per client requirements
      // validateField("authorizationLetter", "Authorization Letter is required");
      // validateField("eAadhaar", "eAadhaar is required");
    }

    return { ...respondent, errors: error };
  });

  return { tempList, isValid, errorMessageList };
};

export default validateRespondentList;
