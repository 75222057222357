import { access } from "fs";
import {
  AgreementType,
  CT_INDIVIDUAL,
  NEW_CASE,
  RESPONDENT_TYPE_INDIVIDUAL,
  RM_ARBITRATION,
  RT_SELF,
  SOLE_ARBITRATOR,
} from "../../../constants/case-constants";
import { FILE_NOT_VERIFIED } from "../../../constants/status";
import { DetailedFacts } from "../../../model/caseDocument/detailedFacts";
import { Grounds } from "../../../model/caseDocument/grounds";
import { PrayerModel } from "../../../model/caseDocument/prayer";
import { Evidence, SynopsisModel } from "../../../model/caseDocument/synopsis";
import {
  ActivityLogs,
  CaseFile,
  CaseFileResponse,
  CaseFileResponseDetail,
  Claimant,
  Respondent,
} from "../../../model/fileCase";
import { Recommendation } from "../../../model/recommendation";
import StatementOfObjectionRow from "../../../pages/CaseDetails/components/StatementOfObjection/StatementOfObjectionRow";
import { CLAIMANT_VAKALATNAMA } from "../../../constants/downloadFileType";

export enum AgreementStatus {
  VERIFIED = 1,
  NOT_VERIFIED = 2,
}
export enum CaseDetailTabType {
  CASE_DETAILS_TAB = 0,
  RESPONSE_TAB = 1,
  TIMELINE = 2,
}
export const DateOption = {
  SINGLE_DATE: 1,
  MULTIPLE_DATES: 2,
  RANGE_OF_DATES: 3,
  ONLY_YEAR: 4,
  NOT_APPLICABLE: 5,
};

export interface CaseResponseList {
  fullName: string;
  uniqueId: string;
  responseParty: number; // owner is 1
}

export interface CaseAgreement {
  docName: string;
  actualFileName: string;
  uploadedFileName: string;
  mimeType: string;
  reUpload: number;
  agreementType: AgreementType;
}

export const initCaseSliceState = {
  caseFile: {
    uniqueId: "" /* case file rid should be non-zero while editing */,
    caseCategory: "",
    caseId: "",
    caseType: "",
    resolutionMethod:
      RM_ARBITRATION /*ARBITRATION or MEDIATION or AMICABLE_SETTLEMENT */,
    representationType: RT_SELF /*SELF or LEGAL_PROFESSIONAL */,

    lawFirmName: "",
    advocateName: "",
    advocateMobileNo: "",
    advocateEmail: "",

    // Vakalatnama
    vakalatnama: null,
    deletedFiles: [] as any,
    case_agreement: null,

    caseAgreementList: [] as CaseAgreement[],

    resolutionAgreement: null,
    resolutionAgreementStatus: 0,
    resolutionAgreementRemark: "",
    tempResolutionAgreementVerified: FILE_NOT_VERIFIED,
    tempresolutionAgreementRemark: "",

    finalVerdict: null,
    finalVerdictFinalized: 0,

    showReuploadDialog: false,

    status: NEW_CASE,
  } as CaseFile,

  caseFileDetail: {
    synopsis: [
      {
        dates: [] as any[],
        dateType: DateOption.SINGLE_DATE,
        eventDetail: "",
        evidences: [] as Evidence[],
      },
    ] as SynopsisModel[],
    detailedFacts: {
      factsDetail: "",
      evidences: [] as Evidence[],
    } as DetailedFacts,
    grounds: [{ groundsDetail: "", evidences: [] as any }] as Grounds[],

    prayers: [
      {
        id: 0,
        prayerDetail: "",
        intensity: 1,
        objection: "",
        status: 0,
        percentage: 0,
      },
    ] as PrayerModel[],

    previousSynopsis: [] as SynopsisModel[],
    previousClaimants: [] as Claimant[],
    previousRespondents: [] as Respondent[],
    previousGrounds: [] as Grounds[],
    previousDetailedFacts: {} as DetailedFacts,
    previousPrayers: [] as PrayerModel[],
  },
  claimantList: [
    {
      claimantType: CT_INDIVIDUAL /*INDIVIDUAL or ORGANIZATION */,
      name: "",
      orgRepresentative: "",
      designation: "",
      authorizationLetter: null,
      eAadhaar: null,
      mobileNo: "",
      email: "",
      address: "",
      isPrimary: 1,
      errors: {
        claimantType: "",
        name: "",
        orgRepresentative: "",
        designation: "",
        authorizationLetter: "",
        eAadhaar: "",
        mobileNo: "",
        email: "",
        address: "",
      },
    },
  ] as Claimant[],
  respondentList: [
    {
      respondentType:
        RESPONDENT_TYPE_INDIVIDUAL /*INDIVIDUAL or ORGANIZATION */,
      name: "",
      address: "",
      orgRepresentative: "",
      designation: "",
      authorizationLetter: null,
      eAadhaar: null,
      email: "",
      mobileNo: "",

      errors: {
        respondentType: "",
        name: "",
        orgRepresentative: "",
        designation: "",
        authorizationLetter: "",
        eAadhaar: "",
        mobileNo: "",
        email: "",
        address: "",
      },
    },
  ] as Respondent[],
  versions: [],

  response: {
    uniqueId: null as string | null,
    caseFileResponse: null as CaseFileResponse | null,
    caseFileResponseDetail: null as CaseFileResponseDetail | null,
    activityLogs: [],
  },

  caseResponseList: null as null | CaseResponseList[],

  neutralHistory: [] as any,
  scrutinyCount: {
    claimantList: null as null | number,
    respondentList: null as null | number,
    prayers: null as null | number,
    synopsis: null as null | number,
    grounds: null as null | number,
    evidences: null as null | number,
    detailedFacts: null as null | number,
  },

  neutralInfo: {
    neutralUniqueId: "" as string,
    reject: false as boolean,
    remark: "" as string,
  },
  arbitratorType: SOLE_ARBITRATOR,
  arbitratorList: [] as any,

  isAdvocate: false,
  isRespondent: false,
  isClaimant: false,
  isAdmin: false,
  isClaimantAdvocate: false,
  isRespondentAdvocate: false,

  primaryClaimant: false,

  enableSelectNeutral: false,

  hasChanges: false,
  hasResponse: false,
  hasResponseChanges: false,
  hasReupload: false,

  previousSynopsis: [] as SynopsisModel[],
  previousDetailedFacts: "",

  claimantExpanded: 0,
  respondentExpanded: 0,
  synopsisExpanded: 0,
  groundsExpanded: 0,
  prayersExpanded: 0,
  activityLogs: [] as ActivityLogs[],

  permissionState: {} as any,
  permissionStateResponse: {},

  selectedResponseId: null as string | null,

  actions: [] as string[] | null,

  version: 0,
  deletedFiles: [] as any,
  caseRecommendation: {} as Recommendation,
  valid: false,
  finalState: 0,
  currentSection: 0,
  currentPage: 0,
  isLoading: false,
  errorMessage: "",
  saving: false,
  sending: false,

  legalHandover: false, //0 false 1 true
  neutralSelectionContext: {
    caseNeutralHistoryCount: 0,
    neutralSelected: 0,
    claimantNeutralSelected: 0,
  },
  neutralSelected:0,

  caseDetailTab: 0 as CaseDetailTabType,
  caseNeutralHistoryCount: 0,
  chooseNeutralLoader: false,

  caseNeutrals: {
    primaryNeutral: {} as any,
    neutral1: {} as any,
    neutral2: {} as any,
  } as any | null,

  timelinePublished: false,

  primaryNeutral: false,

  isEdited: false,
};
