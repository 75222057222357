import { SCRUTINY_REVIEW } from "../../constants/case-constants";
import { SynopsisModel } from "../../model/caseDocument/synopsis";

export const checkForChanges = (caseData: any) => {
  const { claimantList, respondentList } = caseData;
  const {
    previousSynopsis,
    previousGrounds,

    previousClaimants,
    previousRespondents,
    synopsis,
    grounds,

    previousDetailedFacts,
    detailedFacts,
  } = caseData.caseFileDetail;

  const tempData = { ...caseData };
  let scrutinyCount = {
    claimantList: null as null | number,
    respondentList: null as null | number,
    synopsis: null as null | number,
    grounds: null as null | number,
    evidences: null as null | number,
    detailedFacts: null as null | number,
  };

  let evidencesCount = 0;
  
  for (let i = 0; i < synopsis.length; i++) {
    for (let j = 0; j < synopsis[i].evidences.length; j++) {
      if (synopsis[i]?.evidences[j]?.reUpload) {
        evidencesCount++;
      }
    }
    scrutinyCount.evidences = evidencesCount === 0 ? null : evidencesCount;
  }

  if (synopsis) {
    let count = 0;
    synopsis.forEach((data: SynopsisModel, index: number) => {
      if (previousSynopsis[index]) {
        const condition1 = data?.eventDetail !== previousSynopsis[index]?.eventDetail;
        const condition2 = data?.dateType !== previousSynopsis[index]?.dateType;
        const condition3 = data?.dates?.length !== previousSynopsis[index]?.dates?.length;
      
     
        let condition4 = false;
        if (!condition3 && data?.dates && previousSynopsis[index]?.dates) {
          for (let i = 0; i < data.dates.length; i++) {
            if (data.dates[i] !== previousSynopsis[index].dates[i]) {
              condition4 = true;
              break;
            }
          }
        }
      
      
        if (condition1 || condition2 || condition3 || condition4) {
   
          tempData.caseFileDetail.synopsis[index].hasChanges = true;
          tempData.hasChanges = true;
          count++;
        }
      }
      
      
    });

    scrutinyCount.synopsis = count === 0 ? null : count;
  }

  if (claimantList) {
    let count = 0;
    claimantList.forEach((data: any, index: number) => {
      const previous = previousClaimants[index];

      if (
        previous &&
        (data?.name !== previous?.name ||
          data?.address !== previous?.address ||
          data?.mobileNo !== previous?.mobileNo ||
          data?.email !== previous?.email||
          data?.orgRepresentative !== previous?.orgRepresentative||
          data?.designation !== previous?.designation
        )
      ) {
        tempData.claimantList[index].hasChanges = true;
        tempData.hasChanges = true;
        count++;
      }
    });
    scrutinyCount.claimantList = count === 0 ? null : count;
  }

  if (respondentList) {
    let count = 0;
  
    respondentList.forEach((data: any, index: number) => {
      const previous = previousRespondents[index];
  
      if (previous) {
        const condition1 = data?.name !== previous?.name;
        const condition2 = data?.address !== previous?.address;
        const condition3 = data?.mobileNo !== previous?.mobileNo;
        const condition4 = data?.email !== previous?.email;
        const condition5 = data?.orgRepresentative !== previous?.orgRepresentative;
        const condition6 = data?.designation !== previous?.designation;
  
    
  
        if (condition1 || condition2 || condition3 || condition4 || condition5 || condition6) {
   
          tempData.respondentList[index].hasChanges = true;
          tempData.hasChanges = true;
          count++;
        }
      }
    });
  
    scrutinyCount.respondentList = count === 0 ? null : count;
  }
  
  if (grounds) {
    let count = 0;
    grounds.forEach((data: any, index: number) => {
      if (
        previousGrounds[index] &&
        data?.groundsDetail !== previousGrounds[index]?.groundsDetail
      ) {
        tempData.caseFileDetail.grounds[index].hasChanges = true;
        tempData.hasChanges = true;
        count++;
      }
    });
    scrutinyCount.grounds = count === 0 ? null : count;
  }

  if (detailedFacts) {
    let count = 0;
    if (
      previousDetailedFacts &&
      detailedFacts?.factsDetail !== previousDetailedFacts?.factsDetail
    ) {
      tempData.caseFileDetail.detailedFacts.hasChanges = true;
      tempData.hasChanges = true;
      count++;
    }
    scrutinyCount.detailedFacts = count === 0 ? null : count;
  }

  if(caseData.caseFile.status!==SCRUTINY_REVIEW){
   scrutinyCount={
    claimantList: null ,
    respondentList: null,
    synopsis: null,
    grounds: null,
    evidences: null,
    detailedFacts: null,
  }
}
  return { ...tempData, scrutinyCount };
};
