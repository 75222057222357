import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import { CASE } from "../../../routes/routes";
import ViewCaseContent from "./ViewCaseContent";
import { store } from "../../../redux/store";
import { OPEN_EXPAND_CASE } from "../../../constants/local-storage-constants";
import SecureStorage from "../../../util/SecureStorage";
import { truncate } from "fs";


interface CaseExpandProps {
  isRefer?: boolean; //?only for refer case content
}

const CaseExpandView = ({ isRefer }: CaseExpandProps) => {
  const navigate = useNavigate();

  const { caseFile } = store.getState().case;

  const handleClose = () => {
    const uniqueId = caseFile.uniqueId;
    SecureStorage.setItem(OPEN_EXPAND_CASE, true)

    navigate(-1);
  };

  return (
    <div className="d-flex flex-column vh-100 bg-light">
      <div className="d-flex align-items-center bg-light-blue1 px-4 py-2">
        <div className="fs-sm-lg-light flex-grow-1 p-0 m-0">
          Case {caseFile.caseId} /{" "}
          <span className="text-capitalize ms-1">
            {caseFile.resolutionMethod?.toLowerCase().replace(/_/g, " ")}
          </span>
        </div>
        

          <div className="cursor-pointer" onClick={handleClose}>
            <CloseIcon color="primary" />
          </div>
      
      </div>
      <div className="flex-grow-1 overflow-auto p-4">
        <div
          className="bg-white mx-auto"
          style={{ width: "60%", borderRadius: "5px" }}
        >
          <ViewCaseContent
            showLoadBtn={false}
            isExpanded={true}
          
          />
        </div>
      </div>
    </div>
  );
};

export default CaseExpandView;
