import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Pagination, PaginationItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { MuiSelectBorderStyle } from "../../util/mui-helper";
import CustomLoader from '../../components/common/CustomLoader';
import { EMAIL_PENDING, EMAIL_REQUEST_SENT, EMAIL_VERIFIED, KYC_PENDING, KYC_REJECTED, KYC_VERIFIED, NAME_ASC, NAME_DESC } from '../../constants/nav-constants';
import { orgDetailsThunk, orgListThunk } from '../../redux/slice/orgListSlice';
import OrgDetailsDialog from '../../components/OrgDetailsDialog';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import DrawerLayout from '../../Layout/DrawerLayout';
import { RECORDS_LIMIT, RECORDS_PER_PAGE } from '../../constants/api-constants';

const OrganizationList = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [recordsLimit, setRecordsLimit] = React.useState(RECORDS_LIMIT);

  const [openDialog, setOpenDialog] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { organizationList, isLoading, recordCount } = useSelector(
    (state: RootState) => state.orgList
  );
  const { organizationDetails } = useSelector(
    (state: RootState) => state.orgDetails
);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const onSelectChange = (event: any) => {
    setRecordsLimit(event.target.value);
    setPageNumber(1);
  };

  const getOrganizationList = (params: any = {}) => {
    params.pageNumber = pageNumber;
    params.recLimit = recordsLimit;
    params.name = searchTerm;
    params.sortBy = nameSort;
    params.kycVerified = filterKyc === '' ? null : filterKyc;
    params.emailVerified = filterEmail === '' ? null : filterEmail;
    dispatch(orgListThunk(params));
  }

  useEffect(() => {
    getOrganizationList();
  }, [pageNumber, recordsLimit]);

  const onRowCLick = (uniqueId: string) => {
    dispatch(
      orgDetailsThunk(uniqueId)
    );
    setOpenDialog(true);
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getOrganizationList();
      } else {
        return
      }
    } else {
        getOrganizationList();
    }
    setPageNumber(1);
  }, [searchTerm]);

  const [openFilter, setOpenFilter] = useState(false);
  const [nameSort, setNameSort] = useState(NAME_ASC);
  const [filterKyc, setFilterKyc] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const toggleFilter = () => {
    if(openFilter){
      setOpenFilter(false);
    } else {
      setOpenFilter(true);
    }
  }
  const handleFilterClear = () => {
    setNameSort(NAME_ASC);
    setFilterKyc('');
    setFilterEmail('');
  }
  const handleFilterApply = () => {
    getOrganizationList();
    toggleFilter();
    setPageNumber(1);
  }

  return (
    <div className="d-flex flex-column h-100 ps-4  ">
        <div className="d-flex flex-row">
            <div className="fs-sm-heading-med  me-4">Organizations</div>
            <div className="fs-sm-light my-auto">
                {" "}
                {`Dashboard  >  `}
                <span className="text-grey">Organizations</span>
            </div>
        </div>

        <div className="mt-3 rounded h-100 d-flex flex-column bg-white overflow-hidden mb-4">
            <div className="bg-white d-flex flex-column h-100">
              <div className="d-flex gap-3 justify-content-end align-items-center bg-white p-3">
                <div className="d-flex justify-content-center align-items-center bg-light-blue1 cursor-pointer" style={{height: '40px', width: '40px', borderRadius: '50%'}} onClick={() => toggleFilter()}>
                  <FilterListIcon color="primary" />
                </div>

                <TextField
                  label="Search"
                  id="outlined-start-adornment"
                  sx={{ width: '320px' }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                      endAdornment: <InputAdornment position="end">
                          <SearchIcon />
                      </InputAdornment>,
                  }}
                  size="small"
                  autoComplete="off"
                />
              </div>

            <div className="bg-white d-flex flex-column flex-grow-1 overflow-auto">
                <TableContainer>
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="case-tr fs-normal-med"
                                style={{ top: 60, minWidth: 300}}>
                                Organization Name
                            </TableCell>
                            <TableCell
                                className="case-tr fs-normal-med"
                                style={{ top: 60, minWidth: 300}}>
                                Email ID
                            </TableCell>
                            <TableCell
                                className="case-tr fs-normal-med"
                                style={{ top: 60, minWidth: 300}}>
                                Contact No.
                            </TableCell>
                            <TableCell
                                className="case-tr fs-normal-med"
                                style={{ top: 60, minWidth: 300}}>
                                KYC
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {isLoading ? (
                      <CustomLoader size={60} height={"75vh"} />
                      ) : (
                    <TableBody>
                        {organizationList?.map((data: any, index: number) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                              className="case-tbody-tr cursor-pointer"
                              onClick={(e) => onRowCLick(data.uniqueId)}
                            >
                              <TableCell
                                align="left"
                                className="fs-normal-med text-grey case-th"
                              >
                                {data.name}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="fs-normal-med  case-th"
                              >
                                {data.orgRepEmail}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="fs-normal-med case-th"
                              >
                                {data.orgRepMobileNo}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="fs-normal-med case-th"
                              >
                                {data.kycStatus === KYC_PENDING && <span className="text-warning">Pending</span>}
                                {data.kycStatus === KYC_REJECTED && <span className="text-danger">Rejected</span>}
                                {data.kycStatus === KYC_VERIFIED && <span className="text-success">Verified</span>}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody> )}
                    </Table>
                </TableContainer>
                </div>

                <div className="d-flex justify-content-between p-3 bg-white">
                    <div className="d-flex fs-normal-light">
                        <div className="my-auto me-3">Showing </div>
                            <FormControl sx={{ width: 80 }} size="small">
                                <Select
                                    sx={{
                                    ...MuiSelectBorderStyle,
                                    ".MuiOutlinedInput-input": {
                                        padding: "8px",
                                    },
                                    }}
                                    value={recordsLimit}
                                    onChange={onSelectChange}
                                >
                                  {
                                    RECORDS_PER_PAGE?.map((data, key) => (
                                      <MenuItem value={data} key={key}>{data}</MenuItem>
                                    ))
                                  }
                                </Select>
                            </FormControl>
                            <div className="ms-3 my-auto">
                            {" "}
                            of {recordCount} entries
                            </div>
                        </div>
                        <Pagination
                            count={Math.ceil(recordCount / recordsLimit)}
                            className="my-auto"
                            color="primary"
                            shape="rounded"
                            page={pageNumber}
                            onChange={handleChange}
                            renderItem={(item: any) => (
                            <PaginationItem
                                slots={{
                                previous: () => {
                                    return (
                                    <>
                                        <div className="fs-normal-light text-primary">
                                        Prev
                                        </div>
                                    </>
                                    );
                                },
                                next: () => {
                                    return (
                                    <>
                                        <div className="fs-normal-light text-primary">
                                        Next
                                        </div>
                                    </>
                                    );
                                },
                                }}
                                {...item}
                            />
                            )}
                        />
                </div>
              </div>
            

            <OrgDetailsDialog
                open={openDialog}
                onClose={() => {
                    setOpenDialog(!openDialog);
                }}
                orgDetail={organizationDetails}
            />

            <DrawerLayout
              open={openFilter}
              drawerWidth={'350px'}
              title="Filter"
              handleClose={toggleFilter}
              negativeActions={[
                {label: 'Clear', onClick: handleFilterClear}
              ]}
              positiveActions={[
                {label: 'Apply', onClick: handleFilterApply}
              ]}
            >
              <div className="d-flex gap-4 flex-column">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="fs-normal-med">Name</span>
                  <div className="d-flex gap-2">
                    <Button
                      variant={nameSort === NAME_ASC ? 'contained' : 'outlined'}
                      size="small"
                      onClick={() => setNameSort(NAME_ASC)}
                      disableElevation>A-Z</Button>
                    <Button
                      variant={nameSort === NAME_DESC ? 'contained' : 'outlined'}
                      size="small"
                      onClick={() => setNameSort(NAME_DESC)}
                      disableElevation>Z-A</Button>
                  </div>
                </div>
                
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">KYC Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterKyc}
                    onChange={(e) => setFilterKyc(e.target.value)}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={KYC_PENDING}>PENDING</MenuItem>
                    <MenuItem value={KYC_VERIFIED}>VERIFIED</MenuItem>
                    <MenuItem value={KYC_REJECTED}>REJECTED</MenuItem>
                  </Select>
                </FormControl>

                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">Email Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  >
                    <MenuItem value={''}>All</MenuItem>
                    <MenuItem value={EMAIL_PENDING}>PENDING</MenuItem>
                    <MenuItem value={EMAIL_REQUEST_SENT}>REQUEST SENT</MenuItem>
                    <MenuItem value={EMAIL_VERIFIED}>VERIFIED</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </DrawerLayout>
        </div>
    </div>
  );
}

export default OrganizationList;