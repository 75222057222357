import { Snackbar } from "@mui/material";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type SnackbarContextType = {
  showSnackbar: (message: string) => void;
  hideSnackbar: () => void;
};

type SnackbarProviderProps = {
  children: ReactNode;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
}

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [message, setMessage] = useState("");

  const showSnackbar = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const hideSnackbar = useCallback(() => {
    setMessage("");
  }, []);

  // Memoize the context value to avoid unnecessary re-renders in child components
  const contextValue: SnackbarContextType = useMemo(
    () => ({
      showSnackbar,
      hideSnackbar,
    }),
    [showSnackbar, hideSnackbar]
  );

  return (
    <SnackbarContext.Provider value={contextValue}>
      <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={hideSnackbar}
        message={message}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      {children}
    </SnackbarContext.Provider>
  );
}
