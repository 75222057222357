import React, { ChangeEvent, useRef, useState } from 'react';
import CloudUploadIcon from "../../assets/icon/cloudUpload.png";
import Doc22Icon from "../../assets/icon/doc_22x.svg";
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogLayout from '../../Layout/DialogLayout';
import { useSnackbar } from '../../context/SnackbarContext';
import MediaLayout from '../../Layout/MediaLayout';
import ViewMedia from '../ViewMedia';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';



interface FileInputUIProps {
    index: number,
    fileName: string,
    addFile: (file: any, i: number) => void;
    removeFile: (i: number) => void;
}

const FileInputUI: React.FC<FileInputUIProps> = ({ index, fileName, addFile, removeFile }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [isFilePdf, setIsFilePdf] = useState(false);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const { showSnackbar } = useSnackbar();

    const { profileDetail } = useSelector(
        (state: RootState) => state.profileDetail
    );

    const handleViewFile = async () => {
        const kycFileUrl = [
            profileDetail?.personalDetail?.uniqueId,
            'kyc',
            fileName,
        ].join("/");

        setIsFilePdf(kycFileUrl.endsWith(".pdf"));
        setOpenFile(true);
        setFileUrl(kycFileUrl);
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault(); // Prevent default action
        setIsDraggingOver(false);

        if (fileInputRef?.current) {
        fileInputRef.current.value = '';
        }
    
        addFile(e.dataTransfer.files[0], index);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
        e.dataTransfer.dropEffect = "copy";
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };


    const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const fileType = file?.name?.split('.')?.pop()?.toLowerCase();
            if (fileType && !['pdf', 'jpg', 'png'].includes(fileType)) {
                alert('Please select a .pdf, .jpg, or .png file');
                // Reset file input
                if (fileInputRef?.current) {
                    fileInputRef.current.value = '';
                }
            } else {
                // Handle the selected file
                addFile(file, index);

            }
        } else {
            // File list is null (e.g., user canceled file selection), handle this case gracefully
            showSnackbar('No file selected.')
        }
    };


    return (
        <>
            <input
                type="file"
                accept=".pdf,.jpg,.png" // Specify accepted file types if needed
                onChange={(e) => handleFileInputChange(e)}
                style={{ display: "none" }}
                ref={fileInputRef}
            />
            {
                (fileName === null || fileName === '')
                    ? <div
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <div
                            className={`drop-area ${isDraggingOver ? 'drag-over' : ''}`}
                        >
                            <img src={CloudUploadIcon} alt="file upload" />{" "}
                            <span className="ms-2">Browse or Drag & drop</span>
                        </div>
                    </div>
                    : <div className="position-relative d-flex gap-2 align-items-center flex-column py-4 px-5" style={{ border: '2px dashed grey', borderRadius: '5px' }}  >
                        <img className='cursor-pointer' src={Doc22Icon} height={28} width={28} alt="..." />
                        <span className="fs-normal-med text-center" onClick={()=> handleViewFile()}>
                            {fileName}
                        </span>

                        <div className="position-absolute top-0 end-0 p-1 cursor-pointer" onClick={() => removeFile(index)}>
                            <CloseIcon />
                        </div>
                    </div>
            }

            <MediaLayout
                title={"Media Preview"}
                open={openFile}
                handleClose={() => {
                    setOpenFile(false)
                }}
            >
                <ViewMedia isPdf={isFilePdf} url={fileUrl ?? ""} fileOrigin="user" />
            </MediaLayout>
        </>
    );
}

export default FileInputUI;