import React, { useState, useEffect } from "react";
import { Button, Box, Tabs, Tab, CircularProgress } from "@mui/material";
import pic from "../../assets/profile.png";

import phoneIcon from "../../assets/icon/navigation/phone_in_talk_24px.png";
import emailIcon from "../../assets/icon/social/email_24px.png";
import checkIcon from "../../assets/icon/check/Line.png";
import profileIcon from "../../assets/icon/perm_identity_24px.png";
import businessIcon from "../../assets/icon/business_24px.png";
import OrganizationDetails from "../organizationDetails";
import ProfileDetailsTab from "./profileDetailsTab";
import UpdateProfile from "./updateProfile";
import { AppDispatch, RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import SecureStorage from "../../util/SecureStorage";
import { LSK_USER_DETAIL } from "../../constants/local-storage-constants";
import {
  getUserKycThunk,
  initEmailThunk,
  initKycData,
  initLawFirmKycData,
  initNeutralKycData,
  initOrgKycData,
  inviteMemberThunk,
  profileDetailThunk,
  resetInviteEmailForm,
  setProfileDetail,
  setProfileUpdated,
  setSelectedTab,
  setUserList,
  updateOrganizationDetailThunk,
  updateProfileThunk,
  userDetailThunk,
} from "../../redux/slice/userSlice";

import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from "@mui/icons-material/Person";

import TabPanel from "./TabPanel";
import { useSnackbar } from "../../context/SnackbarContext";
import { EMAIL_REQUEST_SENT, EMAIL_VERIFIED, ROLE_INDIVIDUAL, ROLE_LAW_FIRM_ADMIN, ROLE_NEUTRAL, ROLE_ORG_ADMIN } from "../../constants/nav-constants";

const ProfileDetails = () => {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [role, setRole] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const { profileDetail, profileUpdated, selectedTab, userList } = useSelector(
    (state: RootState) => state.profileDetail
  );
  const { showSnackbar } = useSnackbar();

  const handleChangeProfileTabs = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    dispatch(setSelectedTab(newValue));
  };

  const handleRoleChange = (text: any) => {
    setRole(text);
  };

  const toggleEditProfile = () => {
    setShowEditProfile(!showEditProfile);
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  // useEffect(() => {
  //   let userData: any = SecureStorage.getItem(LSK_USER_DETAIL);
  //   if (userData) {
  //     dispatch(
  //       setProfileDetail({
  //         personalDetail: {
  //           ...profileDetail.personalDetail,
  //           ...userData.personalDetail,
  //         },
  //         orgDetail: { ...profileDetail.orgDetail, ...userData.orgDetail },
  //       })
  //     );
  //   }
  // }, []);

  const closeEditProfile = () => {
    setShowEditProfile(!showEditProfile);
  };

  useEffect(() => {
    if (profileUpdated) {
      SecureStorage.setItem(LSK_USER_DETAIL, profileDetail);
      dispatch(setProfileUpdated(false));
    }
  }, [profileUpdated]);

  const updateProfileDetail = (
    detail: any,
    payload: any,
    orgUpdate: boolean = false
  ) => {
    if (orgUpdate) {
      dispatch(updateOrganizationDetailThunk(payload))
        .unwrap()
        .then((data) => {
          dispatch(
            setProfileDetail({
              ...profileDetail,
              orgDetail: { ...profileDetail.orgDetail, ...detail },
            })
          );
          dispatch(setProfileUpdated(true));
          setShowEditProfile(false);
        })
        .catch((error) => {});
      return;
    }

    dispatch(updateProfileThunk(payload))
      .unwrap()
      .then((data) => {
        dispatch(
          setProfileDetail({
            ...profileDetail,
            personalDetail: { ...profileDetail.personalDetail, ...detail },
          })
        );
        dispatch(setProfileUpdated(true));
        setShowEditProfile(false);
      })
      .catch((error) => {});
  };

  const inviteUser = (payload: any, userList: any) => {
    dispatch(inviteMemberThunk(payload))
      .unwrap()
      .then((data) => {
        dispatch(setUserList(userList));
        dispatch(resetInviteEmailForm());
      })
      .catch((error) => {});
  };

  const { isEmailBtnLoading } = useSelector(
    (state: RootState) => state.initEmail
  );
  const handleInitEmail = () => {
    dispatch(initEmailThunk())
      .unwrap()
      .then((data) => {
        dispatch(profileDetailThunk())
          .unwrap()
          .then((data: any) => {
            let userDetail = {
              personalDetail: {
                ...profileDetail.personalDetail,
                ...data.personalDetail,
              },
              orgDetail: { ...profileDetail.orgDetail, ...data.orgDetail },
              kycDetail: { ...profileDetail.kycDetail },
            };

            SecureStorage.setItem(LSK_USER_DETAIL, userDetail);
            dispatch(setProfileDetail(userDetail));
          })
          .catch((error: any) => {});
        showSnackbar("Verification mail sent!");
      })
      .catch((error) => {
        showSnackbar(error);
      });
  };

  useEffect(() => {
    let userData: any = SecureStorage.getItem(LSK_USER_DETAIL);
    if (userData) {
      dispatch(
        setProfileDetail({
          ...profileDetail,
          personalDetail: {
            ...profileDetail.personalDetail,
            ...userData.personalDetail,
          },
          orgDetail: { ...profileDetail.orgDetail, ...userData.orgDetail },
        })
      );
    }

    dispatch(getUserKycThunk())
      .unwrap()
      .then((data: any) => {
        if (data) {
          let updatedDetails = {};
          const { profileDetail } = store.getState().profileDetail;

          if (data.kycData) {
            updatedDetails = {
              ...profileDetail,
              kycDetail: {
                kycStatus: data.kyc?.kycStatus,
                kycData: data.kycData,
              },
            };
          } else {
            const kycData =
              profileDetail?.personalDetail?.roleRid === ROLE_INDIVIDUAL
                ? initKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN
                ? initOrgKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN
                ? initLawFirmKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_NEUTRAL
                ? initNeutralKycData
                : [];

            updatedDetails = {
              ...profileDetail,
              kycDetail: {
                kycStatus: 0,
                kycData: kycData,
              },
            };
          }

          dispatch(setProfileDetail(updatedDetails));
        }
      })
      .catch((error: any) => {});
  }, []);

  return (
    <>
      {!showEditProfile ? (
        <div className="h-100 bg-white overflow-auto br-5">
          <div className="position-relative">
            <Button
              className="d-inline-block fs-normal-med position-absolute m-3 m-md-5 top-0 end-0"
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => {
                toggleEditProfile();
              }}
            >
              Edit profile
            </Button>

            <div className="d-flex flex-column justify-content-center align-items-center  p-3 overflow-auto">
              <img
                className="profile-picture"
                src={
                  profileDetail?.personalDetail?.profilePic
                  ? profileDetail?.personalDetail?.profilePictureUrl
                  : '/user-placeholder.png'
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/user-placeholder.png';
                }}
                alt="..."
              />
              <div className="fs-sm-lg-semibold mt-4">
                {profileDetail?.personalDetail?.fullName}
              </div>

              <div className="gap-3 d-flex align-items-center">
                <div>
                  <img src={phoneIcon} alt="phone no" />
                  <span className="ms-2 fs-normal-light text-light-dark">
                    {profileDetail?.personalDetail?.mobileNo}
                  </span>
                </div>
                <div className="position-relative">
                  <img src={emailIcon} alt="email" />
                  <span className="ms-2 fs-normal-light  text-light-dark">
                    {profileDetail?.personalDetail?.email}
                  </span>

                  {profileDetail.personalDetail?.emailVerified === EMAIL_VERIFIED ? (
                    <img
                      className="position-absolute top-50 start-100 translate-middle-y bg-success ms-2 rounded-circle"
                      src={checkIcon}
                      alt="check"
                    />
                  ) : profileDetail.personalDetail?.emailVerified === EMAIL_REQUEST_SENT ? (
                    <div className="position-absolute top-50 start-100 translate-middle-y w-100 ms-3">
                      <span className="fs-normal-med text-primary p-0 m-0">
                        Email Request Sent
                      </span>
                    </div>
                  ) : (
                    <div className="position-absolute top-50 start-100 translate-middle-y w-100 ms-3">
                      {isEmailBtnLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Button
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={handleInitEmail}
                        >
                          <span className="fs-normal-med text-primary p-0 m-0">
                            Verify Email
                          </span>
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <Box className="my-3" sx={{ minWidth: 350 }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeProfileTabs}
                  centered
                  className="fw-medium"
                >
                  <Tab
                    icon={<img src={profileIcon} alt="profile icon" />}
                    iconPosition="start"
                    label="Personal details"
                    sx={{ textTransform: "none" }}
                  />
                  {(profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN ||
                    profileDetail?.personalDetail?.roleRid ===
                      ROLE_LAW_FIRM_ADMIN) && (
                    <Tab
                      icon={<img src={businessIcon} alt="business icon" />}
                      iconPosition="start"
                      label={
                        profileDetail?.personalDetail?.roleRid ===
                        ROLE_ORG_ADMIN
                          ? "Organization details"
                          : "Law Firm details"
                      }
                      sx={{ textTransform: "none" }}
                    />
                  )}
                </Tabs>
                {selectedTab === 0 && (
                  <TabPanel index={0} selectedTab={0}>
                    <ProfileDetailsTab
                      personalDetail={profileDetail?.personalDetail}
                    />
                  </TabPanel>
                )}
                {selectedTab === 1 && (
                  <TabPanel index={1} selectedTab={1}>
                    <OrganizationDetails
                      organizationDetails={profileDetail?.orgDetail}
                      inviteUser={inviteUser}
                    />
                  </TabPanel>
                )}
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <UpdateProfile
          profileDetail={profileDetail}
          closeEditProfile={closeEditProfile}
          updateProfileDetail={updateProfileDetail}
        />
      )}
    </>
  );
};

export default ProfileDetails;
