import { createTheme } from "@mui/material";

const themePrimary = {
  main: "#00268F",
  light: "#DDE1FF",
  dark: "#223DA2",
  contrastText: "#fff",
};

const themeSecondary = {
  main: "#D7B24D",
  light: "#FFF8E3",
  dark: "#9E7401",
  contrastText: "#fff",
};

const textUnderline = "#E5E5FF";
const danger = "rgb(211, 47, 47)";
const fontFamily = "Poppins, sans-serif";

export const theme = createTheme({
  palette: {
    primary: themePrimary,
    secondary: themeSecondary,
  },
  typography: {
    fontFamily: fontFamily,
  },
});

export const MuiTextFieldBorderStyle = {
  "& .MuiInputBase-root": {
    "&:before": {
      borderBottom: `2px solid ${textUnderline}`,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid  ${textUnderline} !important`,
    },
    "&.Mui-error:after": {
      borderBottomColor: `${danger}`,
    },
  },
};

export const MuiInputFieldBorderStyle = {
  fontSize: "16px",
  "& .MuiInputBase-root": {
    "&:before": {
      borderBottom: `2px solid ${textUnderline}`,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid  ${textUnderline} !important`,
    },
    "&.Mui-error:after": {
      borderBottomColor: `${danger}`,
    },
  },
};

export const MuiSelectBorderStyle = {
  "& fieldset": {
    borderColor: `${textUnderline} !important`,
  },
  "&:hover fieldset": {
    borderColor: `${textUnderline} !important`,
  },
  "&.Mui-focused fieldset": {
    borderColor: `${textUnderline} !important`,
  },
};

export const MuiOutlinedInputStyle = {
  "& .MuiOutlinedInput-input": {
    height: `15px !important`,
  },
  "& fieldset": {
    top: `0px !important`,
    borderColor: `${textUnderline} !important`,
  },
  "&:hover fieldset": {
    borderColor: `${textUnderline} !important`,
  },
  "&.Mui-focused fieldset": {
    borderColor: `${textUnderline} !important`,
  },
};
