import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthLayout from "../Layout/AuthLayout";
import MainLayout from "../Layout/MainLayout";
import OpenLayout from "../Layout/OpenLayout";
import Profile from "../pages/profile/profile";

import {
  CASE,
  CASE_EXPAND,
  CASE_EXPAND_RESPONSE,
  CASE_LIST,
  DASHBOARD,
  EDIT_CASE,
  FILE_CASE,
  FILE_OBJECTION,
  FORGOT_PASSWORD,
  INVITATION,
  LOGIN,
  LP_VERIFY_EMAIL,
  MEDIA,
  MEETING_ROOT,
  NEW_PASSWORD,
  NOTIFICATION_PAGE,
  ORG_LIST,
  OTP,
  PROFILE,
  REGISTER,
  SUPPORT_PAGE,
  USER_LIST,
  VERSION_COMPARISON,
  VIEW_CASE,
} from "./routes";
import FileCaseLayout from "../Layout/FileCaseLayout";
import CaseExpandView from "../pages/CaseDetails/components/CaseExpandView";
import EditCase from "../pages/case/EditCase";
import VerifyEmailLP from "../pages/landing-page/VerifyEmailLP";
import UserList from "../pages/users/UserList";
import OrganizationList from "../pages/organization/OrganizationList";

import VersionComparison from "../pages/version-comparison";
import RespondentResponse from "../pages/CaseDetails/components/RespondentResponse";
import MeetingRoot from "../pages/CaseDetails/MeetingRoot";
import ReferViewCase from "../pages/CaseDetails/components/ReferViewCase";
import ResponseExpand from "../pages/CaseDetails/components/RespondentSide/ResponseExpand";
import SupportPage from "../pages/support/support";

const RouteBuilder = (isLoggedIn: boolean) => {
  // Private pages
  const DashBoard = lazy(() => import("../pages/dashboard"));
  const NotFound = lazy(() => import("../pages/not-found"));

  const NotificationPage = lazy(() => import("../components/Notification/NotificationPage"));
  const ViewMedia = lazy(() => import("../components/ViewMedia"));

  const CaseDetails = lazy(() => import("../pages/CaseDetails"));

  const CaseLists = lazy(() => import("../pages/case/CaseLists"));
  const FileCase = lazy(() => import("../pages/case"));

  // open pages
  const Login = lazy(() => import("../pages/Auth/login"));
  const Register = lazy(() => import("../pages/Auth/sign-up"));
  const Otp = lazy(() => import("../pages/Auth/otp"));
  const ForgotPassword = lazy(() => import("../pages/Auth/forgotPassword"));
  const NewPassword = lazy(() => import("../pages/Auth/newPassword"));

  const Invitation = lazy(() => import("../pages/invitation"));

  return [
    {
      element: <MainLayout />,
      children: [
        { path: DASHBOARD, element: <DashBoard /> },
        { path: PROFILE, element: <Profile /> },

        { path: CASE_LIST + "/:tab", element: <CaseLists /> },
        { path: CASE_LIST, element: <CaseLists /> },
        { path: CASE + "/:uniqueId/:responseId?", element: <CaseDetails /> },

        { path: USER_LIST, element: <UserList /> },
        { path: ORG_LIST, element: <OrganizationList /> },
        { path: NOTIFICATION_PAGE, element: <NotificationPage /> },
        // { path: MEDIA, element: <ViewMedia /> },
        { path: SUPPORT_PAGE, element: <SupportPage /> },
      ],
    },

    {
      path: MEETING_ROOT, element: <MeetingRoot />,
    },

    {
      element: <FileCaseLayout />,
      children: [
        { path: FILE_CASE + "/:status/:uniqueId", element: <FileCase /> },
        {
          path: FILE_OBJECTION + "/:status/:caseId/:uniqueId?",
          element: <RespondentResponse />,
        },
        {
          path: CASE_EXPAND + "/:uniqueId",
          element: <CaseExpandView />,
        },
        {
          path: CASE_EXPAND_RESPONSE + "/:caseId/:responseId",
          element: <ResponseExpand />,
        },
        {
          path: VERSION_COMPARISON + "/:uniqueId/:caseVersion/:isResponse", //send 1 if response
          element: <VersionComparison />,
        },
        {
          path: EDIT_CASE + "/:uniqueId",
          element: <EditCase />,
        },
        {
          path: VIEW_CASE + "/:uniqueId",
          element: <ReferViewCase />,
        },
      ],
    },

    {
      element: <AuthLayout />,
      children: [
        { path: LOGIN, element: <Login /> },
        { path: REGISTER, element: <Register /> },
        { path: OTP, element: <Otp /> },
        { path: FORGOT_PASSWORD, element: <ForgotPassword /> },
        { path: NEW_PASSWORD, element: <NewPassword /> },
      ],
    },
    {
      element: <OpenLayout />,
      children: [
        {
          path: `${INVITATION}/:token`,
          element: <Invitation />,
        },

        {
          path: `${LP_VERIFY_EMAIL}/:token`,
          element: <VerifyEmailLP />,
        },

        {
          path: "*",
          element: isLoggedIn ? <NotFound /> : <Navigate to={LOGIN} />,
        },
      ],
    },
  ];
};

export default RouteBuilder;
