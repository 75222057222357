import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../network/UserService";

export const userListThunk = createAsyncThunk(
    "/user-list",
    async (_params: any, thunkApi) =>
      await UserService.apiUserList(_params)
);

export const userListSlice = createSlice({
    name: "userList",
    initialState: {
      userList: [],
      isLoading: false,
      errorMessage: "",
      recordCount: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(userListThunk.pending, (state, action) => {
          state.isLoading = true;
          state.errorMessage = "";
        })
        .addCase(userListThunk.fulfilled, (state, action) => {
          const { recordCount, data } = action.payload as any;
          
          state.userList = data;
          state.isLoading = false;
          state.errorMessage = "";
          state.recordCount = recordCount;
        })
        .addCase(userListThunk.rejected, (state, action) => {
          state.errorMessage = action.error.message ?? "";
          state.isLoading = false;
        });
    },
});