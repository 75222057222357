
import EditRoundedIcon from "@mui/icons-material/EditRounded";

interface EditButtonProps {
    onClick: (() => void) | null;
}





const EditButton = ({  onClick }: EditButtonProps) => {
    if (onClick) {
        return (<div
            className="d-flex gap-1 align-items-center cursor-pointer"
            onClick={onClick}
        >
            <EditRoundedIcon color="primary" fontSize="small" />
            <p className="fs-normal-med text-primary p-0 m-0">Edit</p>
        </div>)
    } else {
        return null
    }
}


export default EditButton