//Auth Routes

export const LOGIN = "/login";
export const OTP = "/verify-otp";
export const DASHBOARD = "/";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const NEW_PASSWORD = "/new-password";

//** */
export const PROFILE = "/profile";
export const FILE_CASE = "/file-case";
export const FILE_OBJECTION = "/file-objection";

export const CASE_LIST = "/case-list";
export const CASE = "/case";
export const CASE_EXPAND = "/case-expand";
export const CASE_EXPAND_RESPONSE = "/case-expand-response";
export const VERSION_COMPARISON = "/version-comparison";
export const EDIT_CASE = "/edit-case";

export const SUPPORT_PAGE = "/support";

//File Case
export const CASE_DETAILS = "/case-details";
export const CASE_DOCUMENTS = "/case-documents";
export const CASE_PREVIEW = "/case-preview";

export const INVITATION = "/invitation";
export const LP_VERIFY_EMAIL = "/verify-email";

export const USER_LIST = "/user-list";
export const ORG_LIST = "/org-list";
export const NOTIFICATION_PAGE = "/notificaton";
export const MEDIA = "/media";
export const VIEW_CASE = "/view-case";

export const MEETING_ROOT = "/meeting";



