import React from "react";
import { UserRole } from "../../../../model/userModel";
import DialogLayout from "../../../../Layout/DialogLayout";
import FileUpload from "../../../../components/common/FileUpload";
import InfoIcon from "@mui/icons-material/Info";
import {
  AgreementType,
  CASE_DETAILS,
  DRAFT,
  NEW_CASE,
  RM_ARBITRATION,
  TIMELINE_TAB,
} from "../../../../constants/case-constants";
import { AppDispatch, RootState, store } from "../../../../redux/store";
import FileUploadLayout from "../../../../components/common/FileUploadLayout";
import { CaseAgreement } from "../../../../redux/slice/components/initCaseSliceState";
import { Evidence } from "../../../../model/caseDocument/synopsis";
import { DOWNLOAD_MOR_FILE_URL, FileUploadApiType } from "../../../../constants/api-constants";
import {
  removeCaseAgreementDoc,
  updateCaseAgreementList,
} from "../../../../redux/slice/caseSlice";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../../../../context/SnackbarContext";
import { useSelector } from "react-redux";
import { removeResponseCaseAgreementDoc, updateResponseCaseAgreementList } from "../../../../redux/slice/caseResponseSlice";
import FileService from "../../../../network/FileService";

interface propsTypes {
  open: boolean;
  onClose: () => void;
  role: UserRole;
}

function VerifyAffidavitConsentForm({ open, onClose, role }: propsTypes) {
  // const {caseFile, isClaimant,isClaimantAdvocate,isRespondent,isRespondentAdvocate}=store.getState().case
  const {
    caseFile,
    isClaimant,
    isClaimantAdvocate,
    isRespondent,
    isRespondentAdvocate,
    caseDetailTab
  } = useSelector((state: RootState) => state.case);
  const { caseFileResponse } = store.getState().caseResponse;

  const { showSnackbar } = useSnackbar();

  const dispatch: AppDispatch = useDispatch();

  const handleDownloadFile = async (fileType: string) => {
    let agreementType: AgreementType | null = null;
    if(fileType === 'AFFIDAVIT'){
      if (isRespondent || isRespondentAdvocate) {
        caseFile.resolutionMethod === RM_ARBITRATION
          ? agreementType = AgreementType.RESPONDENT_AFFIDAVIT
          : agreementType = AgreementType.SECOND_PARTY_AFFIDAVIT
      } else if (isClaimant || isClaimantAdvocate) {
        caseFile.resolutionMethod === RM_ARBITRATION
          ? agreementType = AgreementType.CLAIMANT_AFFIDAVIT
          : agreementType = AgreementType.FIRST_PARTY_AFFIDAVIT
      } else {
        showSnackbar("You don't have permission!");
        return;
      }
    } else if(fileType === 'CONSENT'){
      agreementType = AgreementType.CONSENT_FORM
    }

    try {
      const blobfile = await FileService.downloadFileApi([
        DOWNLOAD_MOR_FILE_URL,
        agreementType ? agreementType.toString() : ''
      ]) as Blob;
  
      const url = URL.createObjectURL(blobfile);
  
      const a = document.createElement('a');
      a.href = url;
      document.body.appendChild(a);
      a.click();
  
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Download failed', error);
    }
  };

  const addAffidavit = (file: CaseAgreement | Evidence) => {
    const getAgreementType = () => {
      if (isRespondent || isRespondentAdvocate) {
        return caseFile.resolutionMethod === RM_ARBITRATION
          ? AgreementType.RESPONDENT_AFFIDAVIT
          : AgreementType.SECOND_PARTY_AFFIDAVIT;
      } else if (isClaimant || isClaimantAdvocate) {
        return caseFile.resolutionMethod === RM_ARBITRATION
          ? AgreementType.CLAIMANT_AFFIDAVIT
          : AgreementType.FIRST_PARTY_AFFIDAVIT;
      } else {
        showSnackbar("You don't have permission!");
        return null;
      }
    };

    const agreementType = getAgreementType();

    if (!agreementType) return;

    let case_agreement: CaseAgreement[] = [];


    if(isRespondent||isRespondentAdvocate){

      if (caseFileResponse?.caseAgreementList?.length) {
        case_agreement = [
          ...caseFileResponse.caseAgreementList,
          {
            ...file,
            agreementType: agreementType,
          },
        ];
      } else {
        case_agreement = [
          {
            ...file,
            agreementType: agreementType,
          },
        ];
      }
      
    dispatch(updateResponseCaseAgreementList(case_agreement));

    }else{
      
    if (caseFile?.caseAgreementList?.length) {
      case_agreement = [
        ...caseFile.caseAgreementList,
        {
          ...file,
          agreementType: agreementType,
        },
      ];
    } else {
      case_agreement = [
        {
          ...file,
          agreementType: agreementType,
        },
      ];
    }

      dispatch(updateCaseAgreementList(case_agreement));
    }

  };
  const addConsentForm = (file: CaseAgreement | Evidence) => {
    const getAgreementType = () => {
      if (isRespondent || isRespondentAdvocate) {
        return  AgreementType.CONSENT_FORM;
      } else if (isClaimant || isClaimantAdvocate) {
        return  AgreementType.CONSENT_FORM;
      } else {
        showSnackbar("You don't have permission!");
        return null;
      }
    };

    const agreementType = getAgreementType();
    if (!agreementType) return;

    let case_agreement: CaseAgreement[] = [];

    if (caseFile?.caseAgreementList?.length) {
      case_agreement = [
        ...caseFile.caseAgreementList,
        {
          ...file,
          agreementType: agreementType,
        },
      ];
    } else {
      case_agreement = [
        {
          ...file,
          agreementType: agreementType,
        },
      ];
    }

    if(isRespondent||isRespondentAdvocate){
      if (caseFileResponse?.caseAgreementList?.length) {
        case_agreement = [
          ...caseFileResponse.caseAgreementList,
          {
            ...file,
            agreementType: agreementType,
          },
        ];
      } else {
        case_agreement = [
          {
            ...file,
            agreementType: agreementType,
          },
        ];
      }

      
      dispatch(updateResponseCaseAgreementList(case_agreement));
  
      }else{
        if (caseFile?.caseAgreementList?.length) {
          case_agreement = [
            ...caseFile.caseAgreementList,
            {
              ...file,
              agreementType: agreementType,
            },
          ];
        } else {
          case_agreement = [
            {
              ...file,
              agreementType: agreementType,
            },
          ];
        }
        dispatch(updateCaseAgreementList(case_agreement));
      }
  };

  const handleRemoveAffdavit = () => {
    const getAgreementType = () => {
      if (isRespondent || isRespondentAdvocate) {
        return caseFile.resolutionMethod === RM_ARBITRATION
          ? AgreementType.RESPONDENT_AFFIDAVIT
          : AgreementType.SECOND_PARTY_AFFIDAVIT;
      } else if (isClaimant || isClaimantAdvocate) {
        return caseFile.resolutionMethod === RM_ARBITRATION
          ? AgreementType.CLAIMANT_AFFIDAVIT
          : AgreementType.FIRST_PARTY_AFFIDAVIT;
      } else {
        showSnackbar("You don't have permission!");
        return null;
      }
    };

    const agreementType = getAgreementType();
    if (!agreementType) return;

    if (isClaimant || isClaimantAdvocate) {
      dispatch(removeCaseAgreementDoc(agreementType));
    } else {
      dispatch(removeResponseCaseAgreementDoc(agreementType));

    }
  };
  const handleRemoveConsentForm = () => {
    const getAgreementType = () => {
      if (isRespondent || isRespondentAdvocate) {
        return  AgreementType.CONSENT_FORM
      } else if (isClaimant || isClaimantAdvocate) {
        return  AgreementType.CONSENT_FORM;
      } else {
        showSnackbar("You don't have permission!");
        return null;
      }
    };

    const agreementType = getAgreementType();
    if (!agreementType) return;

    if (isClaimant || isClaimantAdvocate) {
      dispatch(removeCaseAgreementDoc(agreementType));
    } else {
      dispatch(removeResponseCaseAgreementDoc(agreementType));

    }
  };

  const getAffidavit = (role: UserRole)=>{
    if (role === UserRole.CLAIMANT) {
      return caseFile?.caseAgreementList?.filter(
        (agreement) =>
          agreement.agreementType ===
          (caseFile.resolutionMethod === RM_ARBITRATION
            ? AgreementType.CLAIMANT_AFFIDAVIT
            : AgreementType.FIRST_PARTY_AFFIDAVIT)
      )[0] as CaseAgreement
    }

    if (role === UserRole.RESPONDENT) {
      return caseFileResponse?.caseAgreementList?.filter(
        (agreement) =>
          agreement.agreementType ===
          (caseFile.resolutionMethod === RM_ARBITRATION
            ? AgreementType.RESPONDENT_AFFIDAVIT
            : AgreementType.SECOND_PARTY_AFFIDAVIT)
      )[0] as CaseAgreement
    }
    
    if(role === UserRole.ADMIN){
      if (caseDetailTab===CASE_DETAILS||caseDetailTab===TIMELINE_TAB){
        return caseFile?.caseAgreementList?.filter(
          (agreement) =>
            agreement.agreementType ===
            (caseFile.resolutionMethod === RM_ARBITRATION
              ? AgreementType.CLAIMANT_AFFIDAVIT
              : AgreementType.FIRST_PARTY_AFFIDAVIT)
        )[0] as CaseAgreement
      }else{  
        return caseFileResponse?.caseAgreementList?.filter(
          (agreement) =>
            agreement.agreementType ===
            (caseFile.resolutionMethod === RM_ARBITRATION
              ? AgreementType.RESPONDENT_AFFIDAVIT
              : AgreementType.SECOND_PARTY_AFFIDAVIT)
        )[0] as CaseAgreement
      }
    

      
    }
    return null;
  }
  const getConcentForm = (role: UserRole)=>{
    if (role === UserRole.CLAIMANT) {
      return  caseFile?.caseAgreementList?.filter(
          (agreement) =>
            agreement.agreementType === AgreementType.CONSENT_FORM
        )[0] as CaseAgreement
       
    }
    if (role === UserRole.RESPONDENT) {
      return caseFileResponse?.caseAgreementList?.filter(
        (agreement) =>
          agreement.agreementType === AgreementType.CONSENT_FORM
      )[0] as CaseAgreement
       
    }
    
    if(role === UserRole.ADMIN){
      if (caseDetailTab===CASE_DETAILS||caseDetailTab===TIMELINE_TAB){
        return caseFile?.caseAgreementList?.filter(
          (agreement) =>
            agreement.agreementType === AgreementType.CONSENT_FORM
        )[0] as CaseAgreement
      }else{  
        return caseFileResponse?.caseAgreementList?.filter(
          (agreement) =>
            agreement.agreementType === AgreementType.CONSENT_FORM
        )[0] as CaseAgreement
      }
    

      
    }
    return null;
  }

  return (
    <DialogLayout
      open={open}
      handleClose={onClose}
      title="Verifying Affidavit & Consent Form"
      positiveActions={role!==UserRole.ADMIN?[
        {
          label: "Done",
          onClick: onClose,
        },
      ]:[]}
      drawerWidth="50%"
    >
      <div className="container d-flex flex-column gap-3">
        <div className="fw-bold h6 w-100 text-center">Verifying Affidavit</div>
        <div
          className=" d-flex gap-1 p-1 ps-2 w-100 rounded-1 align-items-center  fs-sm-med   bg-white mb-2"
          style={{
            border: "1px solid #0081c1",
          }}
        >
          <div className="d-flex flex-column">
            <p>
              <InfoIcon className="me-2" color="info" />To successfully
              send the case to the {(isRespondent||isRespondentAdvocate)?caseFile.resolutionMethod===RM_ARBITRATION? "claimant":"first party":caseFile.resolutionMethod===RM_ARBITRATION?"respondent":"second party"}, please download the verifying
              affidavit, complete the required information, sign it, and upload
              the signed document below.
            </p>
            Note: Please ensure that the document uploaded is legible and in PDF
            Format.
            <div className="text-end">
              <div
                className="text-primary cursor-pointer custom-link"
                onClick={() => {
                  handleDownloadFile('AFFIDAVIT');
                }}
              >
                Verifying Affidavit
              </div>
            </div>
          </div>
        </div>
        <FileUploadLayout
          url={""}
          fileToView={ getAffidavit(role) }
          fileType=".pdf"
          isLoading={false}
          addFile={addAffidavit}
          fileUploadApiType={FileUploadApiType.CASE}
          uploadArguments={[
            caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
            caseFile.uniqueId,
          ]}
          removeFile={role===UserRole.ADMIN?null:() => handleRemoveAffdavit()}
        />

        <div className="fw-bold h6  w-100 text-center my-1">
          Consent Form
        </div>
        <div
          className=" d-flex gap-1 p-1 ps-2 w-100 rounded-1 align-items-center  fs-sm-med   bg-white mb-2"
          style={{
            border: "1px solid #0081c1",
          }}
        >
          <div className="d-flex flex-column">
            <p>
              <InfoIcon className="me-2" color="info" />Please download
              the Consent Form, complete the required information, sign it, and
              upload the signed document below.
            </p>
            Note: Please ensure that the document uploaded is legible and in PDF
            Format.
            <div className="text-end">
              <div
                className="text-primary cursor-pointer custom-link"
                onClick={() => {
                  handleDownloadFile('CONSENT');
                }}
              >
                Consent Form
              </div>
            </div>
          </div>
        </div>
        <FileUploadLayout
          url={""}
          fileToView={
            getConcentForm(role)
          }
          fileType=".pdf"
          isLoading={false}
          addFile={addConsentForm}
          fileUploadApiType={FileUploadApiType.CASE}
          uploadArguments={[
            caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
            caseFile.uniqueId,
          ]}
          removeFile={role === UserRole.ADMIN ? null : () => handleRemoveConsentForm()}
        />
      </div>
    </DialogLayout>
  );
}

export default VerifyAffidavitConsentForm;
