export const convertPermissionString = (str: string) => {
  let permissionState = {};
  const keyValuePairs: string[] = str?.split(";");
  if (keyValuePairs) {
    for (const pair of keyValuePairs) {
      const [key, value] = pair.split(":");
      permissionState = {
        ...permissionState,
        [key]: parseInt(value),
      };
    }
  }
  return permissionState;
};

export const toLocalePrice = (value: any) => {
  const result = Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return isNaN(parseFloat(result)) ? 0 : result;
}

export const toLocalePriceNoDecimal = (value: any) => {
  const result = Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
  return isNaN(parseFloat(result)) ? 0 : result;
}

export const formatDateTime = (dateTimeString: string | null, showTime: boolean = false) => {
  if(dateTimeString){
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
  
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = year;
  
    let formattedTime = '';
    if (showTime) {
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        formattedTime = ` ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  
    return `${formattedDay}-${formattedMonth}-${formattedYear}${formattedTime}`;
  } else {
    let formattedTime = '';
    if (showTime) {
        formattedTime = `H:M:S`;
    }
  
    return `dd-mm-yyyy ${formattedTime}`;
  }
  
}
