import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { LoadingButton } from "@mui/lab";
import { Button, Drawer, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  CLAIMANT_DETAILS,
  DETAILED_FACTS,
  GROUNDS_REASONING,
  RESPONDENT_DETAILS,
  RESPONDENT_STATEMENT_OF_OBJECTION,
  RESPONDENT_SYNOPSIS,
  SYNOPSIS,
} from "../../../constants/case-state";
import {
  editClaimant,
  editDetailedFacts,
  editGrounds,
  editRespondent,
  editSynopsis,
  editSynopsisEvidence,
  setScrutinyCount,
} from "../../../redux/slice/caseSlice";
import {
  editResponseStatementOfObj,
  editResponseSynopsis as editResponseSynopsis, setResponseScrutinyCount
} from "../../../redux/slice/caseResponseSlice";
import CloseIcon from "@mui/icons-material/Close";
import { RejectStatus } from "../../../model/caseDocument/synopsis";
import { useSnackbar } from "../../../context/SnackbarContext";

interface RejectApproveBtnProps {
  dataKey: number;
  index: number;
}

export const RejectApproveBtns: React.FC<RejectApproveBtnProps> = ({
  dataKey,
  index,
}) => {
  const {
    claimantList,
    respondentList,
    caseFileDetail,
    
    scrutinyCount,
    isClaimant,
    isClaimantAdvocate,
    isRespondentAdvocate,
    hasResponse,
  } = useSelector((state: RootState) => state.case);
  const { caseFileResponseDetail } = useSelector(
    (state: RootState) => state.caseResponse
  );
  const { showSnackbar } = useSnackbar();

  const [reason, setReason] = useState("");
  const [open, setOpen] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const onReject = (dataKey: number, index: number) => {
    toggleDrawer();
  };

  const onApprove = (dataKey: number, index: number) => {
    const rejectedKey = "rejected";
    if (isClaimant || isClaimantAdvocate) {
      if (dataKey === CLAIMANT_DETAILS) {
        dispatch(
          editClaimant({
            index,
            value: RejectStatus.ACCEPTED,
            key: rejectedKey,
          })
        );
        dispatch(editClaimant({ index, value: "", key: "reason" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            claimantList: scrutinyCount.claimantList
              ? scrutinyCount.claimantList - 1
              : scrutinyCount.claimantList,
          })
        );
      }
      if (dataKey === RESPONDENT_DETAILS) {
        dispatch(
          editRespondent({
            index,
            value: RejectStatus.ACCEPTED,
            key: rejectedKey,
          })
        );
        dispatch(editRespondent({ index, value: "", key: "reason" }));

        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            respondentList: scrutinyCount.respondentList
              ? scrutinyCount.respondentList - 1
              : scrutinyCount.respondentList,
          })
        );
      }
      if (dataKey === SYNOPSIS) {
        dispatch(
          editSynopsis({
            index,
            value: RejectStatus.ACCEPTED,
            key: rejectedKey,
          })
        );
        dispatch(editSynopsis({ index, value: "", key: "reason" }));

        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            synopsis: scrutinyCount.synopsis
              ? scrutinyCount.synopsis - 1
              : scrutinyCount.synopsis,
          })
        );
      }
      if (dataKey === GROUNDS_REASONING) {
        dispatch(
          editGrounds({ index, value: RejectStatus.ACCEPTED, key: rejectedKey })
        );
        dispatch(editGrounds({ index, value: "", key: "reason" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            grounds: scrutinyCount.grounds
              ? scrutinyCount.grounds - 1
              : scrutinyCount.grounds,
          })
        );

      }
      if (dataKey === DETAILED_FACTS) {
        dispatch(
          editDetailedFacts({ value: RejectStatus.ACCEPTED, key: rejectedKey })
        );
        dispatch(editDetailedFacts({ value: "", key: "reason" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            detailedFacts: scrutinyCount.detailedFacts
              ? scrutinyCount.detailedFacts - 1
              : scrutinyCount.detailedFacts,
          })
        );

      }
    } else {
      if (dataKey === SYNOPSIS) {
        dispatch(
          editResponseSynopsis({
            index,
            value: RejectStatus.ACCEPTED,
            key: rejectedKey,
          })
        );
        dispatch(editResponseSynopsis({ index, value: "", key: "reason" }));
        dispatch(
          setResponseScrutinyCount({
            ...caseFileResponseDetail.responseScrutinyCount,
            synopsis: caseFileResponseDetail.responseScrutinyCount.synopsis
              ? caseFileResponseDetail.responseScrutinyCount.synopsis - 1
              : caseFileResponseDetail.responseScrutinyCount.synopsis,
          })
        );
      }

      if (dataKey === RESPONDENT_STATEMENT_OF_OBJECTION) {
        dispatch(
          editResponseStatementOfObj({
            value: RejectStatus.ACCEPTED,
            key: 'rejected',
          })
        );
        dispatch(editResponseStatementOfObj({ value: "", key: "reason" }));
        dispatch(
          setResponseScrutinyCount({
            ...caseFileResponseDetail.responseScrutinyCount,
            statementOfObjection: 0, // remove count on approve
          })
        );
      }
    }
  };

  const onReset = (dataKey: number, index: number) => {
    setReason("")
    if (isClaimant || isClaimantAdvocate) {
      if (dataKey === CLAIMANT_DETAILS) {
        dispatch(editClaimant({ index, value: "", key: "reason" }));
        dispatch(editClaimant({ index, value: null, key: "rejected" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            claimantList:
              scrutinyCount.claimantList !== null
                ? scrutinyCount.claimantList + 1
                : scrutinyCount.claimantList,
          })
        );
      }
      if (dataKey === RESPONDENT_DETAILS) {
        dispatch(editRespondent({ index, value: "", key: "reason" }));
        dispatch(editRespondent({ index, value: null, key: "rejected" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            respondentList:
              scrutinyCount.respondentList !== null
                ? scrutinyCount.respondentList + 1
                : scrutinyCount.respondentList,
          })
        );
      }
      if (dataKey === SYNOPSIS) {
        dispatch(editSynopsis({ index, value: "", key: "reason" }));
        dispatch(editSynopsis({ index, value: null, key: "rejected" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            synopsis:
              scrutinyCount.synopsis !== null
                ? scrutinyCount.synopsis + 1
                : scrutinyCount.synopsis,
          })
        );
      }
      if (dataKey === GROUNDS_REASONING) {
        dispatch(editGrounds({ index, value: "", key: "reason" }));
        dispatch(editGrounds({ index, value: null, key: "rejected" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            grounds:
              scrutinyCount.grounds !== null
                ? scrutinyCount.grounds + 1
                : scrutinyCount.grounds,
          })
        );
      }
      if (dataKey === DETAILED_FACTS) {
        dispatch(editDetailedFacts({ value: "", key: "reason" }));
        dispatch(editDetailedFacts({ value: null, key: "rejected" }));
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            detailedFacts:
              scrutinyCount.detailedFacts !== null
                ? scrutinyCount.detailedFacts + 1
                : scrutinyCount.detailedFacts,
          })
        );
      }
    } else {
      if (dataKey === SYNOPSIS) {
        dispatch(editResponseSynopsis({ index, value: "", key: "reason" }));
        dispatch(editResponseSynopsis({ index, value: null, key: "rejected" }));
        dispatch(
          setResponseScrutinyCount({
            ...caseFileResponseDetail.responseScrutinyCount,
            synopsis:
              caseFileResponseDetail.responseScrutinyCount.synopsis !== null
                ? caseFileResponseDetail.responseScrutinyCount.synopsis + 1
                : caseFileResponseDetail.responseScrutinyCount.synopsis,
          })
        );

      }

      if (dataKey === RESPONDENT_STATEMENT_OF_OBJECTION) {
        dispatch(editResponseStatementOfObj({ value: "", key: "reason" }));
        dispatch(editResponseStatementOfObj({ value: null, key: "rejected" }));
        dispatch(
          setResponseScrutinyCount({
            ...caseFileResponseDetail.responseScrutinyCount,
            statementOfObjection: 1, // add count on reset
          })
        );

      }

    }
  };

  const onSubmit = () => {

    
    if (!reason){
     showSnackbar("Please enter a reason before submitting!")
     return;
    }
    const rejectedKey = "rejected";
    if (isClaimant || isClaimantAdvocate) {
      if (dataKey === CLAIMANT_DETAILS) {
        dispatch(editClaimant({ index, value: reason, key: "reason" }));
        dispatch(
          editClaimant({
            index,
            value: RejectStatus.REJECTED,
            key: rejectedKey,
          })
        );
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            claimantList:
              scrutinyCount.claimantList !== null
                ? scrutinyCount.claimantList - 1
                : scrutinyCount.claimantList,
          })
        );
      }
      if (dataKey === RESPONDENT_DETAILS) {
        dispatch(editRespondent({ index, value: reason, key: "reason" }));
        dispatch(
          editRespondent({
            index,
            value: RejectStatus.REJECTED,
            key: rejectedKey,
          })
        );
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            respondentList:
              scrutinyCount.respondentList !== null
                ? scrutinyCount.respondentList - 1
                : scrutinyCount.respondentList,
          })
        );
      }
      if (dataKey === SYNOPSIS) {
        dispatch(editSynopsis({ index, value: reason, key: "reason" }));
        dispatch(
          editSynopsis({
            index,
            value: RejectStatus.REJECTED,
            key: rejectedKey,
          })
        );
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            synopsis:
              scrutinyCount.synopsis !== null
                ? scrutinyCount.synopsis - 1
                : scrutinyCount.synopsis,
          })
        );
      }
      if (dataKey === GROUNDS_REASONING) {
        dispatch(editGrounds({ index, value: reason, key: "reason" }));
        dispatch(
          editGrounds({ index, value: RejectStatus.REJECTED, key: rejectedKey })
        );
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            grounds:
              scrutinyCount.grounds !== null
                ? scrutinyCount.grounds - 1
                : scrutinyCount.grounds,
          })
        );
      }

      if (dataKey === DETAILED_FACTS) {
        dispatch(editDetailedFacts({ value: reason, key: "reason" }));
        dispatch(
          editDetailedFacts({ value: RejectStatus.REJECTED, key: rejectedKey })
        );
        dispatch(
          setScrutinyCount({
            ...scrutinyCount,
            detailedFacts:
              scrutinyCount.detailedFacts !== null
                ? scrutinyCount.detailedFacts - 1
                : scrutinyCount.detailedFacts,
          })
        );
      }
    } else {
      if (dataKey === SYNOPSIS) {
        dispatch(editResponseSynopsis({ index, value: reason, key: "reason" }));
        dispatch(
          editResponseSynopsis({
            index,
            value: RejectStatus.REJECTED,
            key: rejectedKey,
          })
        );
        dispatch(
          setResponseScrutinyCount({
            ...caseFileResponseDetail.responseScrutinyCount,
            synopsis: caseFileResponseDetail.responseScrutinyCount.synopsis
              ? caseFileResponseDetail.responseScrutinyCount.synopsis - 1
              : caseFileResponseDetail.responseScrutinyCount.synopsis,
          })
        );
      }

      if (dataKey === RESPONDENT_STATEMENT_OF_OBJECTION) {
        dispatch(editResponseStatementOfObj({ value: reason, key: "reason" }));
        dispatch(
          editResponseStatementOfObj({
            value: RejectStatus.REJECTED,
            key: "rejected",
          })
        );
        dispatch(
          setResponseScrutinyCount({
            ...caseFileResponseDetail.responseScrutinyCount,
            statementOfObjection: 0, // remove count on save
          })
        );
      }
    }

    toggleDrawer();
  };

  const onHandleChange = (value: any) => {
    setReason(value);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const ResetBtn = ({ dataKey, index }: RejectApproveBtnProps) => {
    const key = dataKey;
    const i = index;

    return (
      <LoadingButton
        color="primary"
        style={{
          textTransform: "none",
          border: "0.5px solid #E5E5FF",
        }}
        onClick={() => {
          onReset(key, i);
        }}
      >
        Reset
      </LoadingButton>
    );
  };

  const isRejected = ({ dataKey, index }: RejectApproveBtnProps) => {
    let isTrue = false;
    if (isClaimant || isClaimantAdvocate) {
      if (dataKey === CLAIMANT_DETAILS) {
        claimantList[index].rejected === RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === RESPONDENT_DETAILS) {
        respondentList[index].rejected === RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === SYNOPSIS) {
        caseFileDetail.synopsis[index].rejected === RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === GROUNDS_REASONING) {
        caseFileDetail.grounds[index].rejected === RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === DETAILED_FACTS) {
        caseFileDetail.detailedFacts.rejected === RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
    } else {
      if (dataKey === SYNOPSIS) {
        caseFileResponseDetail.synopsis[index].rejected ===
          RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === RESPONDENT_STATEMENT_OF_OBJECTION) {
        caseFileResponseDetail.statementOfObjection.rejected ===
          RejectStatus.REJECTED
          ? (isTrue = true)
          : (isTrue = false);
      }
    }

    return isTrue;
  };

  const isApproved = ({ dataKey, index }: RejectApproveBtnProps) => {
    let isTrue = false;
    if (isClaimant||isClaimantAdvocate) {
      if (dataKey === CLAIMANT_DETAILS) {
        if (claimantList[index].rejected === RejectStatus.ACCEPTED) {
          isTrue = true;
        }
      }
      if (dataKey === RESPONDENT_DETAILS) {
        respondentList[index].rejected === RejectStatus.ACCEPTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === SYNOPSIS) {
        caseFileDetail.synopsis[index].rejected === RejectStatus.ACCEPTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === GROUNDS_REASONING) {
        caseFileDetail.grounds[index].rejected === RejectStatus.ACCEPTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === DETAILED_FACTS) {
        caseFileDetail.detailedFacts.rejected === RejectStatus.ACCEPTED
          ? (isTrue = true)
          : (isTrue = false);
      }
    } else {
      if (dataKey === SYNOPSIS) {
        caseFileResponseDetail.synopsis[index].rejected ===
          RejectStatus.ACCEPTED
          ? (isTrue = true)
          : (isTrue = false);
      }
      if (dataKey === RESPONDENT_STATEMENT_OF_OBJECTION) {
        caseFileResponseDetail.statementOfObjection.rejected ===
          RejectStatus.ACCEPTED
          ? (isTrue = true)
          : (isTrue = false);
      }
    }

    return isTrue;
  };

  return (
    <>
      <div className="d-flex gap-3 justify-content-center align-items-center mt-4">
        {!isApproved({ dataKey, index }) && (
          <LoadingButton
            variant="text"
            disabled={isRejected({ dataKey, index }) ? true : false}
            style={{
              color: "red",
              textTransform: "none",
              border: "0.5px solid #E5E5FF",
            }}
            onClick={() => {
              onReject(dataKey, index);
            }}
          >
            <HighlightOffOutlinedIcon fontSize="small" />
            {isRejected({ dataKey, index }) ? "Rejected" : "Reject"}
          </LoadingButton>
        )}

        {!isRejected({ dataKey, index }) && (
          <LoadingButton
            disabled={isApproved({ dataKey, index }) ? true : false}
            style={{
              color: "#45B26B",
              textTransform: "none",
              border: "0.5px solid #E5E5FF",
            }}
            onClick={() => {
              onApprove(dataKey, index);
            }}
          >
            <CheckCircleOutlineIcon fontSize="small" />
            {isApproved({ dataKey, index }) ? "Approved" : "Approve"}
          </LoadingButton>
        )}

        {(isApproved({ dataKey, index }) || isRejected({ dataKey, index })) && (
          <ResetBtn dataKey={dataKey} index={index} />
        )}
      </div>

      <Drawer open={open} anchor="right" onClose={toggleDrawer}>
        <div
          style={{ width: "500px" }}
          className="p-3 fs-med-lg-semibold d-flex justify-content-between"
        >
          Reason of rejection
          <IconButton className="border border-1" onClick={toggleDrawer}>
            <CloseIcon color="primary" fontSize="small" />
          </IconButton>
        </div>
        <div className="px-3">
          <TextField
            className="mb-2 w-100"
            multiline
            rows={4}
            placeholder="Add your reason"
            value={reason}
            onChange={(event) => {
              onHandleChange(event.target.value);
            }}
          />

          <div>
            <LoadingButton variant="contained" onClick={onSubmit}>
              Submit
            </LoadingButton>
          </div>
        </div>
      </Drawer>
    </>
  );
};
