import { ShowLabel } from "../../../../components/common/ShowLabel";
import { SCRUTINY_REVIEW } from "../../../../constants/case-constants";
import { StatusType } from "../../../../model/fileCase";
import { store } from "../../../../redux/store";

interface ScrutinyNotePRops {
  scrutinyCount: number | null;
  onClick?: () => void;
}

const ScrutinyNote = ({ scrutinyCount, onClick }: ScrutinyNotePRops) => {
  const {  isAdmin } = store.getState().case;


  if (
    !isAdmin &&
    scrutinyCount 
  ) {
    return (
      <ShowLabel

        label={scrutinyCount + " Scrutiny notes "}
        onClick={onClick}
        status={
          scrutinyCount && scrutinyCount > 0
            ? StatusType.DANGER
            : StatusType.SUCCESS
        }
      />
    );
  } else {
    return <></>;
  }
};

export default ScrutinyNote;
