import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { error } from "console";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../context/SnackbarContext";
import UserService from "../../network/UserService";
import { changePasswordThunk, resetChangePasswordData, setChangePasswordFormData, setChangePasswordFormErrors, setChangePasswordFormSubmitted, setChangeShowConfirmPassword, setChangeShowPassword } from "../../redux/slice/userSlice";
import { AppDispatch, RootState, store } from "../../redux/store";
import { MuiInputFieldBorderStyle, MuiTextFieldBorderStyle } from "../../util/mui-helper";
import { validatePassword } from "../../util/validation";

const ChangePassword = () => {

  const dispatch: AppDispatch = useDispatch()
  const { isLoading, successMessage, formData, formErrors, isFormSubmitted, showPassword, showConfirmPassword } = useSelector((state: RootState) => state.changePassword);
  const { showSnackbar } = useSnackbar();

  const handleClickShowPassword = () => {
    dispatch(setChangeShowPassword(!showPassword));
  };

  const handleClickShowConfirmPassword = () => {
    dispatch(setChangeShowConfirmPassword(!showConfirmPassword));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const updatedErrors = { ...formErrors };
    const updatedData = { ...formData, [name]: value };

    if (name === "oldPassword") {
      value
        ? (
          value === formData.newPassword ? updatedErrors.newPassword = "New password should be different" : (updatedErrors.newPassword = "")
        ) : (updatedErrors.newPassword = "");
    }
    else if (name === "newPassword") {
      value
        ? (
          value === formData.oldPassword ? updatedErrors.newPassword = "New password should be different" :
            updatedErrors.newPassword = validatePassword(value)
        )
        : (updatedErrors.newPassword = "");

      value
        ? (updatedErrors.confirmPassword =
          (value != formData.confirmPassword && formData.confirmPassword) ? "Passwords not matching" : "")
        : (updatedErrors.confirmPassword = "");

    }
    else if (name === "confirmPassword") {
      value
        ? (updatedErrors.confirmPassword =
          value === formData.newPassword ? "" : "Passwords not matching")
        : (updatedErrors.confirmPassword = "");
    }

    dispatch(setChangePasswordFormSubmitted(false));
    dispatch(setChangePasswordFormData(updatedData));
    dispatch(setChangePasswordFormErrors(updatedErrors));
  };

  const updatePassword = async () => {

    const hasEmptyFields = Object.values(formData).some(
      (value) => value === ""
    );

    if (hasEmptyFields) {
      dispatch(setChangePasswordFormSubmitted(true));
      return
    }

    if (formErrors.newPassword || formErrors.confirmPassword) {
      return
    }

    const body = {
      oldPassword: formData.oldPassword,
      newPassword: formData.confirmPassword,
      confirmPassword: formData.confirmPassword,
    };

    dispatch(changePasswordThunk(body))
      .unwrap()
      .then(() => {
        const {successMessage} = store.getState().changePassword;
        
        showSnackbar(successMessage);
        dispatch(resetChangePasswordData());
      })
      .catch((error) => {
        showSnackbar(error.message)
      })
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      updatePassword();
    }
  };

  return (
    <Box
      className="bg-white d-flex flex-column px-5 py-4 gap-4 rounded-3 fs-sm-lg-light"
      width={"fit-content"}
    >
      <TextField
        className={` p-0 fs-sm-lg-light`}
        error={
          (formData.oldPassword === "" && isFormSubmitted) ||
            formErrors.oldPassword
            ? true
            : false
        }
        variant="standard"
        id="oldPassword"
        name="oldPassword"
        label="Old Password "
        helperText={
          formData.oldPassword === "" && isFormSubmitted
            ? "This field is mandatory"
            : formErrors.oldPassword
        }
        onChange={handleChange}
        sx={{
          ...MuiTextFieldBorderStyle,
          '& input': {
            fontSize: '16px',
          },
        }}
        value={formData.oldPassword}
        onKeyPress={handleKeyPress}
      />

      <TextField
        className={` p-0 fs-sm-lg-light`}
        error={
          (formData.newPassword === "" && isFormSubmitted) ||
            formErrors.newPassword
            ? true
            : false
        }
        variant="standard"
        id="newPassword"
        name="newPassword"
        label="New Password "
        helperText={
          formData.newPassword === "" && isFormSubmitted
            ? "This field is mandatory"
            : formErrors.newPassword
        }
        onChange={handleChange}
        sx={{
          ...MuiTextFieldBorderStyle,
          '& input': {
            fontSize: '16px',
          },
        }}
        value={formData.newPassword}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyPress={handleKeyPress}
      />

      <TextField
        className={` p-0 fs-sm-lg-light`}
        error={
          (formData.confirmPassword === "" && isFormSubmitted) ||
            formErrors.confirmPassword
            ? true
            : false
        }
        variant="standard"
        id="confirmPassword"
        name="confirmPassword"
        label="Confirm Password "
        helperText={
          formData.confirmPassword === "" && isFormSubmitted
            ? "This field is mandatory"
            : formErrors.confirmPassword
        }
        onChange={handleChange}
        sx={{
          ...MuiTextFieldBorderStyle,
          '& input': {
            fontSize: '16px',
          },
        }}
        type={showConfirmPassword ? "text" : "password"}
        value={formData.confirmPassword}

        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyPress={handleKeyPress}
      />

      <Button
        className="mb-3 fs-normal-med"
        variant="contained"
        sx={{ textTransform: "none" }}
        onClick={updatePassword}
        disabled={isLoading}
      >
        Update Password
      </Button>
    </Box>
  );
};

export default ChangePassword;
