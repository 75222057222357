import React from "react";
import { diff_match_patch } from "diff-match-patch";

const StringDifferenceHighlighter = ({ original, modified }: { original: string; modified: string }) => {
  const dmp = new diff_match_patch();
  
  if(original&&modified){
    const diffs = dmp.diff_main(original, modified);
    dmp.diff_cleanupSemantic(diffs);

    const highlightedWords = [];

    for (const [type, text] of diffs) {
      const backgroundColor =
        type === 1 ? "yellow" : type === -1 ? "" : "transparent";
      const textDecoration =
        type === 1 ? "" : type === -1 ? "line-through" : "none";
      highlightedWords.push(
        <span style={{ backgroundColor, textDecoration }} key={text}>
          {text}
        </span>
      );
    }
    return <div>{highlightedWords}</div>;
  }else{
    return <div>{original??modified}</div>;
  }


};

export default StringDifferenceHighlighter;
