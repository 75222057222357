export const checkForResponse = (data: any) => {
  let hasResponse = false;

  let tempData = data;

const {claimantList}=data
  if (claimantList) {
    let count = 0;
    claimantList.forEach((data: any, index: number) => {
      if (
       
        data
      ) {
         tempData.claimantList[index].eAadhaar = data.eAadhaar === "" ? "" : JSON.parse(data.eAadhaar);
         tempData.claimantList[index].authorizationLetter = data.authorizationLetter === "" ? "" : JSON.parse(data.authorizationLetter);
       
      }
    });
  }




  if (
    data.response?.caseFileResponse &&
    data.response?.caseFileResponse?.uniqueId
  ) {
    hasResponse = true;
  } else {
    tempData = {
      ...data,
      response: {
        ...data.response,
        caseFileResponse: null,
      },
    };
  }
  if (
    data.response?.caseFileResponseDetail &&
    data.response?.caseFileResponse?.uniqueId
  ) {
    hasResponse = true;
  } else {
    tempData = {
      ...data,
      response: {
        ...tempData.response,
        caseFileResponseDetail: {
          synopsis: [],
          grounds: [],
          prayers: [],
        },
      },
    };
  }

  return { hasResponse, tempData };
};
