import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import UserService from "../../network/UserService";

export const resetPasswordThunk = createAsyncThunk(
  "/reset-password",
  async (body: any, thunkApi) => await UserService.resetPassword(body)
);

export const resetPasswordInitialData = {
  newPassword: "",
  confirmPassword: "",
};

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    data: null,
    isLoading: false,
    errorMessage: "",
    formData: resetPasswordInitialData,
    formErrors: resetPasswordInitialData,
    showPassword: false,
    showConfirmPassword: false,
    isFormSubmitted: false,
  },
  reducers: {
    setResetPasswordFormData: (state, action) => {
      state.formData = action.payload;
    },
    setResetPasswordFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setResetShowPassword: (state, action) => {
      state.showPassword = action.payload;
    },
    setResetShowConfirmPassword: (state, action) => {
      state.showConfirmPassword = action.payload;
    },
    setResetPasswordFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    resetResetPasswordData: (state) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = "";
      state.formData = resetPasswordInitialData;
      state.formErrors = resetPasswordInitialData;
      state.showPassword = false;
      state.showConfirmPassword = false;
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(resetPasswordThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(resetPasswordThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
      });
  },
});
export const {
  setResetPasswordFormData,
  setResetPasswordFormErrors,
  setResetShowPassword,
  setResetShowConfirmPassword,
  setResetPasswordFormSubmitted,
  resetResetPasswordData,
} = resetPasswordSlice.actions;

export const changePasswordThunk = createAsyncThunk(
  "/change-password",
  async (body: any, thunkApi) => await UserService.changePassword(body)
);

export const changePasswordInitialData = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState: {
    data: null,
    isLoading: false,
    successMessage: "",
    errorMessage: "",
    formData: changePasswordInitialData,
    formErrors: changePasswordInitialData,
    showPassword: false,
    showConfirmPassword: false,
    isFormSubmitted: false,
  },
  reducers: {
    setChangePasswordFormData: (state, action) => {
      state.formData = action.payload;
    },
    setChangePasswordFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setChangeShowPassword: (state, action) => {
      state.showPassword = action.payload;
    },
    setChangeShowConfirmPassword: (state, action) => {
      state.showConfirmPassword = action.payload;
    },
    setChangePasswordFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    resetChangePasswordData: (state) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = "";
      state.formData = changePasswordInitialData;
      state.formErrors = changePasswordInitialData;
      state.showPassword = false;
      state.showConfirmPassword = false;
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.successMessage = "";
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.isLoading = false;
        state.errorMessage = "";
        state.successMessage = "Completed successfully!";
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
        state.successMessage = "";
      });
  },
});
export const {
  setChangePasswordFormData,
  setChangePasswordFormErrors,
  setChangeShowPassword,
  setChangeShowConfirmPassword,
  setChangePasswordFormSubmitted,
  resetChangePasswordData,
} = changePasswordSlice.actions;

export const profileDetailThunk = createAsyncThunk(
  "/profile-detail",
  async (_, thunkApi) => await UserService.profileDetail()
);

export const updateProfileThunk = createAsyncThunk(
  "/change-password",
  async (body: any, thunkApi) => await UserService.updateProfile(body)
);

export const updateContextTypeThunk = createAsyncThunk(
  "/set-user-context-type",
  async (body: any, thunkApi) => await UserService.updateContextType(body)
);

export const updateOrganizationDetailThunk = createAsyncThunk(
  "/update-org-detail",
  async (body: any, thunkApi) =>
    await UserService.updateOrganizationDetail(body)
);

export const inviteMemberThunk = createAsyncThunk(
  "/invite-member",
  async (body: any, thunkApi) => await UserService.inviteMember(body)
);

export const inviteMemberListThunk = createAsyncThunk(
  "/list-invited-members",
  async (_, thunkApi) => await UserService.getInviteMemberList()
);

export const initEmailThunk = createAsyncThunk(
  "/init-email-verification",
  async (_, thunkApi) => await UserService.apiInitEmailVerification()
);
export const initEmailSlice = createSlice({
  name: "initEmail",
  initialState: {
    isEmailBtnLoading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(initEmailThunk.pending, (state, action) => {
        state.isEmailBtnLoading = true;
      })
      .addCase(initEmailThunk.fulfilled, (state, action) => {
        state.isEmailBtnLoading = false;
      })
      .addCase(initEmailThunk.rejected, (state, action) => {
        state.isEmailBtnLoading = false;
      });
  },
});

export const verifyEmailThunk = createAsyncThunk(
  "/verify-email",
  async (token: any, thunkApi) =>
    await UserService.apiVerifyEmail({ token: token })
);

export const userKycThunk = createAsyncThunk(
  "/user-kyc",
  async (body: any, thunkApi) => await UserService.apiUserKyc(body)
);
export const orgKycThunk = createAsyncThunk(
  "/org-kyc",
  async (body: any, thunkApi) => await UserService.apiUserKyc(body)
);
export const getUserKycThunk = createAsyncThunk(
  "/get-user-kyc",
  async (_, thunkApi) => await UserService.apiGetUserKyc()
);

export const initKycData = [
  {
    kycType: "",
    fieldValue: "",
    attachment: "",
    remark: "",
    status: "",
  },
];
export const initOrgKycData = [
  {
    kycType: "CIN",
    fieldValue: "",
    attachment: "",
  },
  {
    kycType: "LOA",
    fieldValue: "",
    attachment: "",
  },
  {
    kycType: "", // Authorised Person's ID proof
    fieldValue: "",
    attachment: "",
  },
];
export const initLawFirmKycData = [
  {
    kycType: "BCI",
    fieldValue: "",
    attachment: "",
  },
  {
    kycType: "GST",
    fieldValue: "",
    attachment: "",
  },
  {
    kycType: "PAN",
    fieldValue: "",
    attachment: "",
  },
];
export const initNeutralKycData = [
  {
    kycType: "CV",
    fieldValue: "",
    attachment: "",
  },
];

const initialUserDetail = {
  orgDetail: {
    orgRid: 0,
    name: "",
    orgType: "",
    orgRepName: "",
    orgRepDesignation: "",
    orgRepEmail: "",
    orgRepMobileNo: "",
    address: "",
    status: 0,
    inchargeUserRid: 0,
  },
  personalDetail: {
    userRid: 0,
    username: "",
    fullName: "",
    mobileNo: "",
    email: "",
    emailVerified: 0,
    dob: "",
    address: "",
    authorities: "",
    accountNonExpired: false,
    accountNonLocked: false,
    credentialsNonExpired: false,
    enabled: false,
    currentContextType: null,
    contextList: [
      {
        uniqueId: "",
        contextType: "",
        orgName: "",
      },
    ],
    status: "",
    roleRid: 0,

    features: [],
    uniqueId: "",
    profilePic: "",
    profilePictureUrl: "",
  },
  kycDetail: {
    kycStatus: "",
    kycData: initKycData,
  },
};

const personalErrorDetail = {
  fullName: "",
  email: "",
  mobileNo: "",
};
const organizationErrorDetail = {
  name: "",
  orgRepName: "",
  orgRepDesignation: "",
  orgRepEmail: "",
  orgRepMobileNo: "",
};

export const profileDetailSlice = createSlice({
  name: "profileDetail",
  initialState: {
    profileDetail: initialUserDetail,

    errorMessage: "",
    isLoading: false,
    profileUpdated: false,
    inviteMemberLoading: false,
    selectedTab: 0,
    nextBtnLoading: false,
    userList: [],
    inviteEmail: "",
    showChangePasswordMenu: false,
    showProfileDetailsMenu: false,
    inviteEmailFormError: "",
    selectedProfileTab: 0,
    showAddUserDialog: false,
    selectedRole: "",
    tempPersonalDetailForm: initialUserDetail.personalDetail,
    tempOrganizationDetailForm: initialUserDetail.orgDetail,
    tempPersonalDetailFormError: personalErrorDetail,
    tempOrganizationDetailFormError: organizationErrorDetail,
    isFormSubmitted: false,
  },
  reducers: {
    setProfileDetail: (state, action) => {


      
     
      
      const temp= {
        ...state,
        profileDetail:{
          ...state.profileDetail,
          ...action.payload
        }
      }
      
      
      
      return temp
    },
    setProfileUpdated: (state, action) => {
      state.profileUpdated = action.payload;
    },
    setTempPersonalDetailForm: (state, action) => {
      state.tempPersonalDetailForm = action.payload;
    },
    setTempOrganizationDetailForm: (state, action) => {
      state.tempOrganizationDetailForm = action.payload;
    },
    setSelectedProfileTab: (state, action) => {
      state.selectedProfileTab = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setShowAddUserDialog: (state, action) => {
      state.showAddUserDialog = action.payload;
    },
    setPersonalDetailFormErrors: (state, action) => {
      state.tempPersonalDetailFormError = action.payload;
    },
    setOrganizationDetailFormErrors: (state, action) => {
      state.tempOrganizationDetailFormError = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    setInviteEmail: (state, action) => {
      state.inviteEmail = action.payload;
    },
    setShowChangePasswordMenu: (state, action) => {
      state.showChangePasswordMenu = action.payload;
    },
    setShowProfileDetailsMenu: (state, action) => {
      state.showProfileDetailsMenu = action.payload;
    },
    setInviteEmailFormError: (state, action) => {
      state.inviteEmailFormError = action.payload;
    },
    setFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    resetInviteEmailForm: (state) => {
      state.inviteEmail = "";
      state.inviteEmailFormError = "";
    },
    resetUserDetail: (state) => {
      state.profileDetail = initialUserDetail;
    },
    editKycData : (state, action) => {
      const { index, key, value } = action.payload;
    
      return {
        ...state,
        profileDetail: {
          ...state.profileDetail,
          kycDetail: {
            ...state.profileDetail.kycDetail,
            kycData: state?.profileDetail?.kycDetail?.kycData?.map((item, i) =>
              i === index
                ? { ...item, [key]: value }
                : item
            ),
          },
        },
      };
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(profileDetailThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(profileDetailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileDetail = action.payload as any;
        state.errorMessage = "";
      })
      .addCase(profileDetailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message ?? "";
      });

    builder
      .addCase(updateProfileThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(updateProfileThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(updateProfileThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message ?? "";
      });

    builder
      .addCase(updateContextTypeThunk.pending, (state, action) => {
        state.nextBtnLoading = true;
        state.errorMessage = "";
      })
      .addCase(updateContextTypeThunk.fulfilled, (state, action) => {
        state.nextBtnLoading = false;
        state.errorMessage = "";
      })
      .addCase(updateContextTypeThunk.rejected, (state, action) => {
        state.nextBtnLoading = false;
        state.errorMessage = action.error.message ?? "";
      });

    builder
      .addCase(updateOrganizationDetailThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(updateOrganizationDetailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(updateOrganizationDetailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.error.message ?? "";
      });

    builder
      .addCase(inviteMemberThunk.pending, (state, action) => {
        state.inviteMemberLoading = true;
        state.errorMessage = "";
      })
      .addCase(inviteMemberThunk.fulfilled, (state, action) => {
        state.inviteMemberLoading = false;
        state.errorMessage = "";
      })
      .addCase(inviteMemberThunk.rejected, (state, action) => {
        state.inviteMemberLoading = false;
        state.errorMessage = action.error.message ?? "";
      });

    builder
      .addCase(inviteMemberListThunk.pending, (state, action) => {
        state.errorMessage = "";
      })
      .addCase(inviteMemberListThunk.fulfilled, (state, action) => {
        state.errorMessage = "";
      })
      .addCase(inviteMemberListThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
      });
  },
});
export const {
  setProfileDetail,
  setProfileUpdated,
  setPersonalDetailFormErrors,
  setOrganizationDetailFormErrors,
  setSelectedTab,
  setUserList,
  setInviteEmail,
  setShowChangePasswordMenu,
  setShowProfileDetailsMenu,
  setInviteEmailFormError,
  resetInviteEmailForm,
  setTempPersonalDetailForm,
  setTempOrganizationDetailForm,
  setSelectedProfileTab,
  setSelectedRole,
  setShowAddUserDialog,
  setFormSubmitted,
  resetUserDetail,
  editKycData,
} = profileDetailSlice.actions;

export const userDetailThunk = createAsyncThunk(
  "/user-detail",
  async (uniqueId: string, thunkApi) => {
    return await UserService.apiUserDetails(uniqueId);
  }
);
export const userKycVerifyThunk = createAsyncThunk(
  "/user-kyc-verify",
  async (body: any, thunkApi) => {
    return await UserService.apiUserKycVerify(body);
  }
);
const initUserDetail = {
  kycDetail: {
    kyc: {},
    kycData: [
      {
        uniqueId: "",
        kycType: "",
        fieldValue: "",
        attachment: "",
        remark: "",
        status: "",
      },
    ],
  },
  user: {},
};
export const userDetailsSlice = createSlice({
  name: "user-details",
  initialState: {
    ...initUserDetail,
  },
  reducers: {
    editKycDataAdmin: (state, action) => {
      const { index, key, value } = action.payload;

      state.kycDetail.kycData[index] = {
        ...state.kycDetail?.kycData[index],
        [key]: value,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userDetailThunk.pending, () => {
        return { ...initUserDetail, isLoading: true };
      })
      .addCase(userDetailThunk.fulfilled, (state, action) => {
        const data = action.payload as any;
        return {
          ...data,
          isLoading: false,
        };
      })
      .addCase(userDetailThunk.rejected, () => {
        return { ...initUserDetail };
      });
  },
});
export const { editKycDataAdmin } = userDetailsSlice.actions;
