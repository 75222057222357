import React, { useEffect, useState } from 'react'
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, store } from '../../../../redux/store';
import ViewCaseResponseContent from './ViewCaseResponseContent';
import { caseResponseThunk, saveCaseFileResponseThunk, setResponse } from '../../../../redux/slice/caseResponseSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CASE_EXPAND, CASE_EXPAND_RESPONSE, CASE_LIST, EDIT_CASE } from '../../../../routes/routes';
import SecureStorage from '../../../../util/SecureStorage';
import { EDIT_CASE_DETAILS, EDIT_RESPONSE, IS_ADMIN, OPEN_EXPAND_CASE, SELECTED_RESPONDENT } from '../../../../constants/local-storage-constants';
import CustomLoader from '../../../../components/common/CustomLoader';
import { LoadingButton } from '@mui/lab';
import { SCRUTINY_REVIEW, SENT_FOR_RESCRUTINY, SEND_RESPONSE_TO_CLAIMANT, SCRUTINY_RESOLVED, SEND_BACK_TO_RESPONDENT, SENT_FOR_RAD_SCRUTINY, SENT_TO_RESPONDENT, DRAFT, LEGAL_HANDOVER, RESPONSE_TAB, RM_ARBITRATION, RM_CONCILIATION, RM_MEDIATION, AgreementType } from '../../../../constants/case-constants';
import { checkForRejected, revortBackRejected } from '../../../../util/case/checkForRejected';
import { useCustomDialogBox } from '../../../../context/CustomDialogContext';
import { useSnackbar } from '../../../../context/SnackbarContext';
import { Evidence, RejectStatus } from '../../../../model/caseDocument/synopsis';
import { SAVE_CHANGES_BTN } from '../../../../constants/btn-constants';
import { convertPermissionString } from '../../../../util/stringUtils';
import { checkForChangesResponse } from '../../../../util/case/checkForChangesResponse';
import { setUiState } from '../../../../redux/slice/uiSlice';
import VerifyAffidavitConsentForm from '../common/VerifyAffidavitConsentForm';
import { UserRole } from '../../../../model/userModel';


export interface Respondent {
  address: string;
  authorizationLetter: string;
  designation: string;
  eAadhaar: string;
  email: string;
  mobileNo: string;
  name: string;
  orgRepresentative: string;
  reason: null | string;
  rejected: null | string;
  respondentType: string;
}

interface ViewResponseProps {
  selectedRespondent: string | null,
  isExpanded?: boolean,
}


function ViewResponse({ selectedRespondent, isExpanded }: ViewResponseProps) {

  const [isSending, setIsSending] = useState(false);

  const [activeButton, setActiveButton] = useState<number | null>(null);

  const { isEdited, caseFile, isRespondent, isRespondentAdvocate, isAdmin, caseDetailTab, isLoading, saving } = useSelector((state: RootState) => state.case);

  const { caseFileResponse, caseFileResponseDetail, isResponseLoading } = useSelector((state: RootState) => state.caseResponse);
  const [openVerifyAffidavit, setOpenVerifyAffidavit] = useState(false);



  const { showSnackbar } = useSnackbar();

  const { showDialogBox } = useCustomDialogBox();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const editResponseId = SecureStorage.getItem(EDIT_RESPONSE);
    const isOpenExpand = SecureStorage.getItem(OPEN_EXPAND_CASE);



    if (isOpenExpand || editResponseId) {
      SecureStorage.removeItem(EDIT_RESPONSE); //? IF returned from edit-case no need to call api again
      SecureStorage.removeItem(OPEN_EXPAND_CASE);
      return
    } else {
      dispatch(caseResponseThunk({ uniqueId: selectedRespondent }));

    }
    // if (oldResponseId !==selectedRespondent&&selectedRespondent ) {
    //   SecureStorage.removeItem(EDIT_RESPONSE); //? IF returned from edit-case no need to call api again

    // }
  }, [selectedRespondent]);




  const handleExpandView = () => {

    if (caseDetailTab === RESPONSE_TAB) {
      navigate(CASE_EXPAND_RESPONSE + "/" + caseFile.uniqueId + "/" + caseFileResponse.uniqueId);
    } else {
      navigate(CASE_EXPAND + "/" + caseFile.uniqueId);
    }
  }






  const checkIfResolved = () => {
    let allow: boolean | undefined = false;

    allow =
      caseFileResponseDetail?.synopsis &&
      caseFileResponseDetail.synopsis.some((data: any) => {

        if (data.rejected === RejectStatus.REJECTED) {

          if (data.resolved) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });



    if (allow) {
      if (caseFileResponseDetail?.statementOfObjection) {
        if (caseFileResponseDetail?.statementOfObjection?.rejected === RejectStatus.REJECTED) {
          if (caseFileResponseDetail?.statementOfObjection?.resolved) {
            allow = true;
          } else {
            allow = false;
          }
        } else {
          allow = true;
        }
      }
    }


    return allow as boolean;
  };






  const sendCase = (dataToSend: any) => {
    setIsSending(true);
    const STATUS = dataToSend.caseFileResponse.status;

    SecureStorage.removeItem(EDIT_RESPONSE);
    SecureStorage.removeItem(OPEN_EXPAND_CASE);


    const submite = () => {
      dispatch(saveCaseFileResponseThunk(dataToSend))
        .unwrap()
        .then(() => {
          setIsSending(false);

          if (STATUS === SENT_FOR_RAD_SCRUTINY) {
            if (!isAdmin) {
              showDialogBox("Success", "Successfully sent for Scrutiny");
            }
          }
          if (STATUS === SENT_TO_RESPONDENT) {
            if (caseFile.resolutionMethod === RM_ARBITRATION) {
              showDialogBox("Success", "Successfully sent to Respondent/s");
            } else {
              showDialogBox("Success", "Successfully sent to Second Party")
            }

          }
          if (STATUS === SENT_FOR_RESCRUTINY) {
            if (!isAdmin) {
              showDialogBox("Success", "Successfully sent for Re-Scrutiny");
            }
          }
          // navigate(CASE_LIST);
          // window.location.reload();
          dispatch(caseResponseThunk({ uniqueId: selectedRespondent }));
        })
        .catch((e) => {
          setIsSending(false);
          console.error(e);
        });
    }


    let resAffidavit = null as any
    let resConsentForm = null as any

    if (caseFile.resolutionMethod === RM_ARBITRATION) {
      resAffidavit = caseFileResponse?.caseAgreementList?.filter(agreement => agreement.agreementType === AgreementType.RESPONDENT_AFFIDAVIT)[0]
    } else {
      resAffidavit = caseFileResponse?.caseAgreementList?.filter(agreement => agreement.agreementType === AgreementType.SECOND_PARTY_AFFIDAVIT)[0]
    }

    resConsentForm = caseFileResponse?.caseAgreementList?.filter(agreement => agreement.agreementType === AgreementType.CONSENT_FORM)[0]




    if (!(resConsentForm && resAffidavit) && (STATUS === SEND_RESPONSE_TO_CLAIMANT)) {
      setOpenVerifyAffidavit(true);
    } else {
      submite()
    }


  };


  const checkForReUploadResolved = () => {
    let allow = true; // Initialize as true
    caseFileResponseDetail?.synopsis.forEach((data: any) => {
      data.evidences.forEach((evidence: Evidence) => {
        if (evidence.reUpload === 1 && !evidence.tempReUpload) {
          allow = false; // Set to false if any evidence is not re-uploaded correctly
        }
      });
    });
    return allow;
  };

  return (
    <>
      <div
        className="d-flex flex-column bg-white h-100 pb-3"
        style={{ borderRadius: "5px" }}
      >

        {!selectedRespondent ?
          (
            <div
              className="d-flex flex-column bg-white h-100 pb-3"
              style={{ borderRadius: "5px" }}
            >
              No Response found
            </div>
          ) : isResponseLoading || isLoading || saving ? (
            <CustomLoader />
          ) : (
            <>
              <div className="d-flex align-items-center bg-light-blue2 px-3 py-1">
                <div className="fs-sm-lg-light flex-grow-1 p-0 m-0  ">
                  Case {caseFile.caseId} /
                  <span className="text-capitalize ms-1">
                    {caseFile?.resolutionMethod?.toLowerCase().replace(/_/g, " ")}

                  </span>
                </div>
                {!isExpanded ? (
                  <div
                    className="d-flex gap-2 align-items-center cursor-pointer"
                    onClick={handleExpandView}
                  >
                    <CropFreeRoundedIcon color="primary" fontSize="small" />
                    <p className="fs-normal-med text-primary p-0 m-0">Expand</p>
                  </div>
                ) : null}
              </div>
              <ViewCaseResponseContent showLoadBtn={true} />




              <div className="d-flex w-100 justify-content-end mb-5">
                <div className="mt-1 w-100 d-flex gap-4 p-3 justify-content-end border-1 border-top">
                  {isRespondent && (
                    <>
                      {caseFileResponse?.status === SCRUTINY_REVIEW && (
                        <>
                          {" "}
                          <LoadingButton
                            //loading={store.getState().case.saving && !saving}
                            loading={store.getState().caseResponse.isResponseLoading && !isSending}


                            disabled={isSending}
                            variant="outlined"
                            onClick={() => {
                              const dataToSend = {
                                uniqueId: caseFile.uniqueId,
                                caseFileResponse: {
                                  ...caseFileResponse,

                                  status: SENT_FOR_RESCRUTINY,

                                },
                                caseFileResponseDetail: caseFileResponseDetail,
                              };

                              if (
                                checkForRejected(caseFileResponseDetail, isRespondent) &&
                                checkForReUploadResolved()
                              ) {
                                const data = revortBackRejected(dataToSend, true)


                                sendCase(data);
                              } else {
                                showSnackbar("Resolve Scrutiny before sending!");
                              }
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            Resend for Scrutiny
                          </LoadingButton>
                          <LoadingButton
                            loading={store.getState().caseResponse.isResponseLoading}
                            disabled={isResponseLoading || isSending || caseFileResponseDetail.synopsis.some(data =>
                              data?.evidences.some(evidence => evidence.reUpload)
                            )}
                            variant="contained"
                            onClick={() => {
                              const dataToSend = {
                                uniqueId: caseFile.uniqueId,
                                caseFileResponse: {
                                  ...caseFileResponse,
                                  status: SEND_RESPONSE_TO_CLAIMANT,
                                },
                                caseFileResponseDetail: caseFileResponseDetail,
                              };

                              sendCase(dataToSend);
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {caseFile.resolutionMethod === RM_ARBITRATION ? "Approve all & Send to Claimant" : "Approve all & Send to First Party"}


                          </LoadingButton>
                        </>
                      )}

                      {(caseFileResponse?.status === SCRUTINY_RESOLVED ||
                        caseFileResponse?.status === SEND_BACK_TO_RESPONDENT) && (
                          <LoadingButton
                            loading={store.getState().caseResponse.isResponseLoading}

                            disabled={isResponseLoading}
                            variant="contained"
                            onClick={() => {
                              const dataToSend = {
                                uniqueId: caseFile.uniqueId,
                                caseFileResponse: {
                                  ...caseFileResponse,
                                  status: SEND_RESPONSE_TO_CLAIMANT,
                                },
                                caseFileResponseDetail: caseFileResponseDetail,
                              };
                              sendCase(dataToSend);
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {caseFile.resolutionMethod === RM_ARBITRATION ? "Send to Claimant" : "Send to First Party"}
                          </LoadingButton>
                        )}
                    </>
                  )}

                  {isRespondentAdvocate && (
                    <>
                      {caseFileResponse?.status === LEGAL_HANDOVER ||
                        (caseFileResponse?.status === DRAFT && (
                          <>
                            <LoadingButton
                              loading={store.getState().caseResponse.isResponseLoading}

                              disabled={isSending}
                              variant="outlined"
                              onClick={() => {
                                const dataToSend = {
                                  uniqueId: caseFile.uniqueId,
                                  caseFileResponse: {
                                    ...caseFileResponse,
                                  },
                                  resolutionAgreementVerified: caseFile.tempResolutionAgreementVerified,

                                  caseFileResponseDetail: caseFileResponseDetail,
                                };
                                sendCase(dataToSend);
                              }}
                              sx={{ textTransform: "none" }}
                            >
                              Save Changes
                            </LoadingButton>
                            <LoadingButton
                              loading={store.getState().caseResponse.isResponseLoading}

                              disabled={isSending}
                              variant="contained"
                              onClick={() => {
                                const dataToSend = {
                                  uniqueId: caseFile.uniqueId,
                                  caseFileResponse: {
                                    ...caseFileResponse,
                                    resolutionAgreementVerified: caseFile.tempResolutionAgreementVerified,

                                    status: SEND_BACK_TO_RESPONDENT,
                                  },
                                  caseFileResponseDetail: caseFileResponseDetail,
                                };

                                
                                sendCase(dataToSend);
                              }}
                              sx={{ textTransform: "none" }}
                            >
                              {caseFile.resolutionMethod === RM_ARBITRATION ? "Send back to Respondent" : "Send back to Second Party"}
                            </LoadingButton>
                          </>
                        ))}
                    </>
                  )}

                  {isAdmin && (
                    <>

                      {caseFileResponse?.status === SENT_FOR_RAD_SCRUTINY && (
                        <>

                          <LoadingButton
                            loading={
                              isResponseLoading && activeButton === SAVE_CHANGES_BTN
                                ? true
                                : false
                            }
                            disabled={isResponseLoading}
                            variant="outlined"
                            onClick={() => {
                              const dataToSend = {
                                uniqueId: caseFile.uniqueId,
                                caseFileResponse: {
                                  ...caseFileResponse,
                                },
                                caseFileResponseDetail: caseFileResponseDetail,
                              };
                              sendCase(dataToSend);
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            Save Changes
                          </LoadingButton>
                          <LoadingButton
                            loading={store.getState().caseResponse.isResponseLoading}

                            disabled={isSending}
                            variant="contained"
                            onClick={() => {
                              const dataToSend = {
                                uniqueId: caseFile.uniqueId,
                                caseFileResponse: {
                                  ...caseFileResponse,
                                  status: SCRUTINY_REVIEW,
                                },
                                caseFileResponseDetail: caseFileResponseDetail,
                              };

                              //TODO: if validation required
                              // const isValid=
                              // if(isValid){
                              sendCase(dataToSend);
                              // }else{
                              //   showSnackbar(" Invalid ")
                              // }


                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {caseFile.resolutionMethod === RM_ARBITRATION ? "Send Back to Respondent" : "Send Back to Second Party"}


                          </LoadingButton>
                        </>
                      )}
                      {caseFileResponse?.status === SENT_FOR_RESCRUTINY && (
                        <>
                          <LoadingButton
                            loading={store.getState().caseResponse.isResponseLoading}

                            disabled={isSending}
                            variant="contained"
                            onClick={() => {
                              const dataToSend = {
                                uniqueId: caseFile.uniqueId,
                                caseFileResponse: {
                                  ...caseFileResponse,
                                  status: SCRUTINY_RESOLVED,
                                },
                                caseFileResponseDetail: caseFileResponseDetail,
                              };
                              if (checkIfResolved()) {
                                sendCase(dataToSend);
                              } else {
                                showSnackbar("Resolve Rejection before sending!");
                              }
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {caseFile.resolutionMethod === RM_ARBITRATION ? "Send Back to Respondent" : "Send Back to Second Party"}
                          </LoadingButton>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )
        }


      </div>
      <VerifyAffidavitConsentForm
        open={openVerifyAffidavit}
        onClose={() => {
          setOpenVerifyAffidavit(false)
          setIsSending(false);
        }} role={UserRole.RESPONDENT} />
    </>
  );
}

export default ViewResponse