//respondent btns
export const SAVE_CHANGES_BTN = 1;
export const SCRUTINY_BTN = 2;
export const RESEND_FOR_SCRUTINY_BTN = 3;
export const APPROVE_ALL_BTN = 4;

export const SEND_TO_CLAIMANT_BTN = 5;
export const SEND_BACK_TO_CLAIMANT_BTN = 6;

export const SEND_TO_RESPONDENT_BTN = 7;
export const SEND_BACK_TO_RESPONDENT_BTN = 8;

export const SAVE_AS_DRAFT = 9;
export const SAVE_BUTTON = 10;
