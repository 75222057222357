import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../network/AuthService";

export const signInThunk = createAsyncThunk(
  "/login",
  async (body: any, thunkApi) => await AuthService.login(body)
);

export const signInInitialData = {
  email: "",
  password: "",
};

export const signInSlice = createSlice({
  name: "signIn",
  initialState: {
    isLoading: false,
    errorMessage: "",
    formData: signInInitialData,
    formErrors: signInInitialData,
    showPassword: false,
    isFormSubmitted: false,
  },
  reducers: {
    setLoginFormData: (state, action) => {
      state.formData = action.payload;
    },
    setLoginFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setLoginShowPassword: (state, action) => {
      state.showPassword = action.payload;
    },
    setLoginFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    resetLoginData: (state) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.formData = signInInitialData;
      state.formErrors = signInInitialData;
      state.showPassword = false;
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(signInThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(signInThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
        state.formData.password = "";
      });
  },
});
export const {
  setLoginFormData,
  setLoginFormErrors,
  setLoginFormSubmitted,
  setLoginShowPassword,
  resetLoginData,
} = signInSlice.actions;

export const signUpThunk = createAsyncThunk(
  "/sign-up",
  async (body: any, thunkApi) => await AuthService.register(body)
);

export const signUpInitialData = {
  fullName: "",
  mobileNo: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState: {
    data: null,
    isLoading: false,
    successMessage: "",
    errorMessage: "",
    formData: signUpInitialData,
    formErrors: signUpInitialData,
    showPassword: false,
    showConfirmPassword: false,
    isFormSubmitted: false,
  },
  reducers: {
    setSignUpFormData: (state, action) => {
      state.formData = action.payload;
    },
    setSignUpFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setSignUpShowPassword: (state, action) => {
      state.showPassword = action.payload;
    },
    setSignUpShowConfirmPassword: (state, action) => {
      state.showConfirmPassword = action.payload;
    },
    setSignUpFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    resetSignUpData: (state) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = "";
      state.formData = signUpInitialData;
      state.formErrors = signUpInitialData;
      state.showPassword = false;
      state.showConfirmPassword = false;
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.successMessage = "";
      })
      .addCase(signUpThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.isLoading = false;
        state.successMessage = "OTP sent successfully!";
        state.errorMessage = "";
      })
      .addCase(signUpThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
        state.successMessage = "";
      });
  },
});
export const {
  setSignUpFormData,
  setSignUpFormErrors,
  setSignUpShowPassword,
  setSignUpShowConfirmPassword,
  setSignUpFormSubmitted,
  resetSignUpData,
} = signUpSlice.actions;

export const verifyOtpThunk = createAsyncThunk(
  "/verify-otp",
  async (body: any, thunkApi) => await AuthService.verifyOtp(body)
);

export const verifyOtpInitialData = {
  otp: Array(5).fill(""),
};
export const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState: {
    data: null,
    isLoading: false,
    errorMessage: "",
    formData: verifyOtpInitialData,
    formErrors: verifyOtpInitialData,
    countdown: 600,
    isFormSubmitted: false,
  },
  reducers: {
    setVerifyOtpFormData: (state, action) => {
      state.formData = action.payload;
    },
    setVerifyOtpFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setVerifyOtpFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    setCountData: (state, action) => {
      state.countdown = action.payload;
    },
    resetVerifyOtpData: (state) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = "";
      state.formData = verifyOtpInitialData;
      state.formErrors = verifyOtpInitialData;
      state.countdown = 120;
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtpThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(verifyOtpThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(verifyOtpThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
      });
  },
});
export const {
  setVerifyOtpFormData,
  setVerifyOtpFormErrors,
  setVerifyOtpFormSubmitted,
  resetVerifyOtpData,
  setCountData,
} = verifyOtpSlice.actions;

export const resendOtpThunk = createAsyncThunk(
  "/resend-otp",
  async (body: any, thunkApi) => await AuthService.resendOtp(body)
);

export const resendOtpSlice = createSlice({
  name: "resendOtp",
  initialState: {
    data: null,
    resendLoading: false,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    setResendLoading: (state, action) => {
      state.resendLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendOtpThunk.pending, (state, action) => {
        state.resendLoading = true;
        state.errorMessage = "";
        state.successMessage = "";
      })
      .addCase(resendOtpThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.resendLoading = false;
        state.errorMessage = "";
        state.successMessage = "OTP sent successfully!";
      })
      .addCase(resendOtpThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.resendLoading = false;
        state.successMessage = "";
      });
  },
});
export const { setResendLoading } = resendOtpSlice.actions;

export const forgotPasswordThunk = createAsyncThunk(
  "/forgot-password",
  async (body: any, thunkApi) => await AuthService.forgotPassword(body)
);

export const forgotPasswordInitialData = {
  mobileOrEmail: "",
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    data: null,
    isLoading: false,
    successMessage: "",
    errorMessage: "",
    formData: forgotPasswordInitialData,
    formErrors: forgotPasswordInitialData,
    isFormSubmitted: false,
  },
  reducers: {
    setForgotPasswordFormData: (state, action) => {
      state.formData = action.payload;
    },
    setForgotPasswordFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    resetForgotPasswordData: (state) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = "";
      state.formData = forgotPasswordInitialData;
      state.formErrors = forgotPasswordInitialData;
      state.isFormSubmitted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswordThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.successMessage = "";
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.isLoading = false;
        state.errorMessage = "";
        state.successMessage = "OTP sent successfully!";
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
        state.successMessage = "";
      });
  },
});
export const {
  setForgotPasswordFormData,
  setForgotPasswordFormErrors,
  resetForgotPasswordData,
} = forgotPasswordSlice.actions;

export const validateInvitationThunk = createAsyncThunk(
  "/validate-invitation",
  async (body: any, thunkApi) => await AuthService.validateInvitation(body)
);

export const invitationSignUpThunk = createAsyncThunk(
  "/invitation-signup",
  async (body: any, thunkApi) => await AuthService.invitationSignUp(body)
);

const userDetail = {
  userRid: 0,
  fullName: "",
  email: "",
  mobileNo: "",
  address: "",
  password: "",
  confirmPassword: "",
};
const orgDetail = {
  orgRid: 0,
  name: "",
  orgType: "",
  address: "",
  orgRepName: "",
  orgRepDesignation: "",
  orgRepMobileNo: "",
  orgRepEmail: "",
};
export const validateInvitationSlice = createSlice({
  name: "validate-invitation",
  initialState: {
    data: null,
    isLoading: false,
    inviteLoading: false,
    successMessage: "",
    errorMessage: "",
    userDetail: userDetail,
    formErrors: {
      email: "",
      mobileNo: "",
      password: "",
      confirmPassword: "",
    },
    orgDetail: orgDetail,
  },

  reducers: {
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setOrgDetail: (state, action) => {
      state.orgDetail = action.payload;
    },
    resetInvitationForm: (state) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = "";
      state.userDetail = userDetail;
      state.orgDetail = orgDetail;
      state.formErrors = {
        email: "",
        mobileNo: "",
        password: "",
        confirmPassword: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateInvitationThunk.pending, (state, action) => {
        state.inviteLoading = true;
        state.errorMessage = "";
        state.successMessage = "";
      })
      .addCase(validateInvitationThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.inviteLoading = false;
        state.errorMessage = "";
      })
      .addCase(validateInvitationThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.inviteLoading = false;
        state.successMessage = "";
      });

    builder
      .addCase(invitationSignUpThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
        state.successMessage = "";
      })
      .addCase(invitationSignUpThunk.fulfilled, (state, action) => {
        state.data = action.payload as any;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(invitationSignUpThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
        state.successMessage = "";
      });
  },
});

export const {
  setUserDetail,
  setFormErrors,
  setOrgDetail,
  resetInvitationForm,
} = validateInvitationSlice.actions;
