import { combineReducers, configureStore, createAction } from "@reduxjs/toolkit";
import {
  forgotPasswordSlice,
  resendOtpSlice,
  signInSlice,
  signUpSlice,
  validateInvitationSlice,
  verifyOtpSlice,
} from "./slice/authSlice";
import { caseListSlice } from "./slice/caseListSlice";
import {
  changePasswordSlice,
  initEmailSlice,
  profileDetailSlice,
  resetPasswordSlice,
  userDetailsSlice,
} from "./slice/userSlice";
import caseReducer from "../redux/slice/caseSlice";
import { userListSlice } from "./slice/userListSlice";
import { orgDetailsSlice, orgListSlice } from "./slice/orgListSlice";
import { caseResponseSlice } from "./slice/caseResponseSlice";
import { dashboardSlice } from "./slice/dashboardSlice";
import { caseTimelineSlice } from "./slice/neutralTimelineSlice";
import { notificationSlice } from "./slice/notificationSlice";
import { uiStateSlice } from "./slice/uiSlice";
import { CLEAR_DATA } from "./actionTypes";
import {
  Action,
  configureStore as reduxConfigureStore,
  ThunkAction,
} from "@reduxjs/toolkit";



export const clearReduxSlice = createAction(CLEAR_DATA);

const appReducer = combineReducers({
  // Combine your reducers here
  signIn: signInSlice.reducer,
  signUp: signUpSlice.reducer,
  verifyOtp: verifyOtpSlice.reducer,
  resendOtp: resendOtpSlice.reducer,
  forgotPassword: forgotPasswordSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  changePassword: changePasswordSlice.reducer,
  profileDetail: profileDetailSlice.reducer,
  caseList: caseListSlice.reducer,
  case: caseReducer,
  caseResponse: caseResponseSlice.reducer,
  validateInvitation: validateInvitationSlice.reducer,
  userList: userListSlice.reducer,
  userDetails: userDetailsSlice.reducer,
  initEmail: initEmailSlice.reducer,
  orgList: orgListSlice.reducer,
  orgDetails: orgDetailsSlice.reducer,
  uiState: uiStateSlice.reducer,
  dashboard: dashboardSlice.reducer,
  caseTimeline: caseTimelineSlice.reducer,
  notification: notificationSlice.reducer,
  // Add other slice reducers here
});




const rootReducer = (state:any, action:any) => {

    if (action.type === CLEAR_DATA) {
      // Reset the state to initial state for relevant slices

      const newState: any = {};
      Object.keys(state).forEach((sliceKey) => {
        newState[sliceKey] = undefined;
      });
      state = newState;
    }


  return appReducer(state, action);
};



// // Thunk type for async actions
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;

// Configure the store with the rootReducer
export const store = reduxConfigureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Define RootState and AppDispatch types

