import React, { ChangeEvent, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { Claimant } from "../../../../model/fileCase";
import { Box } from "@mui/material";
import CardRole from "../../../../components/common/CardRole";
import IndividualIcon from "../../../../assets/Individual.png";
import OrganizationIcon from "../../../../assets/Organization.png";
import { MuiTextFieldBorderStyle } from "../../../../util/mui-helper";
import {
  CT_INDIVIDUAL,
  CT_ORGANIZATION,
  DRAFT,
  NEW_CASE,
} from "../../../../constants/case-constants";
import {
  editClaimant,
  updateClaimant,
} from "../../../../redux/slice/caseSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import FileService from "../../../../network/FileService";
import FileUploadLayout from "../../../../components/common/FileUploadLayout";
import { FileUploadApiType } from "../../../../constants/api-constants";
import { CaseAgreement } from "../../../../redux/slice/components/initCaseSliceState";
import { Evidence } from "../../../../model/caseDocument/synopsis";

interface ClaimantEditBoxProps {
  claimant: Claimant;
  index: number;
}

const ClaimantRow: React.FC<ClaimantEditBoxProps> = ({ claimant, index }) => {
  const dispatch: AppDispatch = useDispatch();

  const { caseFile } = useSelector((state: RootState) => state.case);
  const { email, fullName, dob, address, mobileNo, username, } = useSelector((state: RootState) => state.profileDetail.profileDetail.personalDetail);

  const roles = [
    { label: "Individual", value: CT_INDIVIDUAL, imgUrl: IndividualIcon },
    { label: "Organization", value: CT_ORGANIZATION, imgUrl: OrganizationIcon },
  ];

  const onRoleChange = (value: string) => {
    dispatch(editClaimant({ index, value, key: "claimantType" }));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    let value = e.target.value;

    if (key === "email") {
      value = value.toLowerCase();
    }
    if (key === "mobileNo") {
      value = value.replace(/\D/g, "");
    }
    dispatch(editClaimant({ index, value, key }));
    dispatch(updateClaimant({ index, key }));
  };

  const uploadCaseFile = async (file: File) => {
    try {
      if (!file) {
        throw Error("File list is null");
      }

      const args: any = [
        caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
        caseFile.uniqueId,
      ];

      const response =
        (await FileService.caseFileUpload(file, args)) as any;

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (index === 0 && !claimant.name) {
      if (!claimant?.address.length) {
        dispatch(editClaimant({ index, value: address, key: "address" }));
        dispatch(updateClaimant({ index, key: "address" }));
      }
      if (!claimant?.email.length) {
        dispatch(editClaimant({ index, value: email, key: "email" }));
        dispatch(updateClaimant({ index, key: "email" }));
      }
      if (!claimant?.name.length) {
        dispatch(editClaimant({ index, value: fullName, key: "name" }));
        dispatch(updateClaimant({ index, key: "name" }));
      }
      if (!claimant?.mobileNo.length) {
        dispatch(editClaimant({ index, value: mobileNo, key: "mobileNo" }));
        dispatch(updateClaimant({ index, key: "mobileNo" }));
      }
    }

  }, [])


  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    fileInfo: any
  ) => {
    e.preventDefault();

    let fileList: FileList | null;

    if ("dataTransfer" in e) {
      fileList = e.dataTransfer.files;
    } else {
      fileList = e.target.files;
    }

    if (fileList && fileList.length > 0) {
      const key = fileInfo?.key;

      const value = await uploadCaseFile(fileList[0]);


      dispatch(editClaimant({ index, value, key }));
    } else {
      throw new Error("File list is null");
    }
  };

  const addClaimantFile = async (file: CaseAgreement | Evidence, index: number, key: string) => {
    if (file) {
      dispatch(editClaimant({ index, value: file, key }));

    }
  }

  const handleRemoveFile = async (key: any) => {
    dispatch(editClaimant({ index, value: null, key }));
    dispatch(updateClaimant({ index, key }));
  };

  return (
    <div className="d-flex h-100 flex-column">
      <div className="d-flex gap-2 justify-content-center">
        {roles.map((role, index) => (
          <CardRole
            key={index}
            imgUrl={role.imgUrl}
            name={role.label}
            active={claimant.claimantType === role.value}
            onClick={() => {
              onRoleChange(role.value);
            }}
          />
        ))}
      </div>
      <div className="d-flex justify-content-center h-100 mt-1">

        <Box className="d-flex flex-column gap-2 px-4 py-2" width={400}>

          <TextField
            error={!!claimant.errors?.name}
            className="w-100 fs-sm-lg-light"
            type="text"
            name="name"
            label={claimant.claimantType === roles[0].value ? "Name" : "Organisation Name"}
            variant="standard"
            value={claimant.name ?? fullName}
            helperText={claimant.errors?.name}
            onChange={onInputChange}
            sx={MuiTextFieldBorderStyle}
          />

          {claimant.claimantType === roles[1].value ? (
            <TextField
              error={!!claimant.errors?.orgRepresentative}
              className="w-100 fs-sm-lg-light"
              type="text"
              label="Authorized Representative (as per ID Proof)"
              name="orgRepresentative"
              value={claimant.orgRepresentative}
              helperText={claimant.errors?.orgRepresentative}
              variant="standard"
              onChange={onInputChange}
              sx={MuiTextFieldBorderStyle}
            />


          ) : null}

          {claimant.claimantType === roles[1].value ? (
            <TextField
              error={!!claimant.errors?.designation}
              className="w-100 fs-sm-lg-light"
              type="text"
              label="Designation"
              name="designation"
              value={claimant?.designation}
              helperText={claimant.errors?.designation}
              variant="standard"
              onChange={onInputChange}
              sx={MuiTextFieldBorderStyle}
            />
          ) : null}

          <TextField
            error={!!claimant.errors?.mobileNo}
            className="w-100 fs-sm-lg-light"
            type="text"
            label="Mobile"
            name="mobileNo"
            variant="standard"
            value={claimant.mobileNo}
            inputProps={{
              minLength: 10,
              maxLength: 10,
            }}
            helperText={claimant.errors?.mobileNo}
            onChange={onInputChange}
            sx={MuiTextFieldBorderStyle}
          />
          <TextField
            error={!!claimant.errors?.email}
            className="w-100 fs-sm-lg-light"
            type="text"
            onChange={onInputChange}
            name="email"
            label="Email"
            value={claimant.email}
            helperText={claimant.errors?.email}
            variant="standard"
            sx={MuiTextFieldBorderStyle}
          />
          <TextField
            error={!!claimant.errors?.address}
            className="w-100 fs-sm-lg-light"
            type="text"
            value={claimant.address}
            helperText={claimant.errors?.address}
            label={claimant.claimantType === roles[1].value ? "Registered Office Address" : "Address for communication"}
            name="address"
            variant="standard"
            onChange={onInputChange}
            sx={MuiTextFieldBorderStyle}
          />

          {claimant.claimantType === roles[1].value ? (
            <>
              <p className="fs-normal-semibold text-primary p-0 m-0">
                Authorisation Letter
              </p>

              <FileUploadLayout
                fileToView={claimant?.authorizationLetter}
                fileUploadApiType={FileUploadApiType.CASE}
                addFile={(file: CaseAgreement | Evidence) => {
                  addClaimantFile(file, index, "authorizationLetter")
                }}
                removeFile={() => {
                  handleRemoveFile("authorizationLetter")
                }}
                uploadArguments={[
                  caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                  caseFile.uniqueId,
                ]}
                errorMessage={claimant.errors?.authorizationLetter
                  ? claimant.errors?.authorizationLetter
                  : ""}

              />
            </>
          ) : null}

          {claimant.claimantType === roles[1].value ? (
            <>
              <p className="fs-normal-semibold text-primary p-0 m-0">
                Authorised Representative ID Proof
              </p>

              <FileUploadLayout
                // fileType=".pdf"
                fileToView={claimant?.eAadhaar}
                fileUploadApiType={FileUploadApiType.CASE}
                addFile={(file: CaseAgreement | Evidence) => {
                  addClaimantFile(file, index, "eAadhaar")
                }}
                uploadArguments={[
                  caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                  caseFile.uniqueId,
                ]}
                removeFile={() => {
                  handleRemoveFile("eAadhaar")
                }}
                errorMessage={claimant.errors?.eAadhaar ? claimant.errors?.eAadhaar : ""}
              />
            </>
          ) : null}
        </Box>
      </div >
    </div >
  );
};

export default ClaimantRow;
