import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../network/UserService";
import {
  LEGAL_HANDOVER,
  LEGAL_REVOKE,
  LEGAL_REVOKE_REJECTED,
  SCRUTINY_REVIEW,
  SEND_BACK_TO_CLAIMANT,
  SENT_FOR_RAD_SCRUTINY,
  SENT_TO_RESPONDENT,
} from "../../constants/case-constants";
import { CaseFile } from "../../model/fileCase";

export const dashboardThunk = createAsyncThunk(
  "/user-dashboard",
  async (_params: any, thunkApi) => await UserService.apiDashboard(_params)
);

const dashboardDetails = {
  individualCount: 0,
  lawFirmCount: 0,
  orgCount: 0,
  neutralsCount: 0,
  notificationCount: 0,

  asArbitrator: 0,
  asMediator: 0,
  asConciliator: 0,

  cases: {
    totalCase: 0,
    newCaseCount: 0,
    totalSettledCase: 0,
    filledByMe: 0,
    filledAgainstMe: 0,
    representingClaimant: 0,
    representingRespondent: 0,
    caseByMonthAndYear: [
      {
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
    ],
    recentCaseByStatus: [
      {
        status: SENT_TO_RESPONDENT,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
      {
        status: LEGAL_REVOKE_REJECTED,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
      {
        status: SCRUTINY_REVIEW,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
      {
        status: SENT_FOR_RAD_SCRUTINY,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
      {
        status: SEND_BACK_TO_CLAIMANT,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
      {
        status: LEGAL_HANDOVER,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
      {
        status: LEGAL_REVOKE,
        year: 0,
        month: 0,
        unsettled: 0,
        settled: 0,
        count: 0,
      },
    ],
    newCases: [] as CaseFile[],
    recentCases: [],
    lastUpdated: [],
    underScrutiny: [],
    settled: [],
    due: [],
    delayed: [],
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardDetails: dashboardDetails,
    isLoading: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboardThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(dashboardThunk.fulfilled, (state, action) => {
        state.dashboardDetails = action.payload as any;
        state.isLoading = false;
        state.errorMessage = "";
      })
      .addCase(dashboardThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
      });
  },
});
