import { error } from "console";
import {
  CLAIMANT_DETAILS,
  DETAILED_FACTS,
  GROUNDS_REASONING,
  METHOD_OF_RESOLUTION,
  PRAYERS,
  REPRESENTATION_TYPE,
  RESPONDENT_DETAILS,
  SYNOPSIS,
} from "../../../constants/case-state";
import { LEGAL_PROFESSIONAL } from "../../../constants/nav-constants";
import { validateClaimantList } from "../../../util/case/claimantValidation";
import {
  validateGrounds,
  validatePrayers,
  validateSynopsis,
} from "../../../util/case/validateCaseDocuments";
import { validateCaseFile } from "../../../util/case/validateCaseFile";
import validateRespondentList from "../../../util/case/validateRespondentList";

export const caseSliceValidation = (state: any, saveAsDraft: boolean) => {
  const validateFlag = true; //this flag is used to disable validation if needed
  const currentSection = state.currentSection;
  if (validateFlag) {
    if (currentSection === CLAIMANT_DETAILS || saveAsDraft) {
      if (!state.claimantList.length) {
        return {
          ...state,
          valid: false,
          errorMessage: "Claimant is empty",
        };
      }

      const { tempList: claimantList, isValid, errorMessageList} = validateClaimantList(
        state.claimantList
      );

      return {
        ...state,
        claimantList,
        valid: isValid,
        errorMessage: errorMessageList.length?errorMessageList[0]:"",
      };
    }

    if (currentSection === METHOD_OF_RESOLUTION && !saveAsDraft) {
      if (!state.caseFile.resolutionAgreement) {
        return {
          ...state,
          valid: false,
          errorMessage: "Please upload the Agreement before proceeding!",
        };
      }
    }
    if (currentSection === RESPONDENT_DETAILS && !saveAsDraft) {
      if (!state.respondentList.length) {
        return {
          ...state,
          valid: false,
          errorMessage: "Respondent is empty",
        };
      }

      const { tempList: respondentList, isValid,errorMessageList } = validateRespondentList(
        state.respondentList,
        state.claimantList
      );

      return {
        ...state,
        respondentList,
        valid: isValid,
        errorMessage: errorMessageList.length?errorMessageList[0]:"",
      };
    }

    if (currentSection === SYNOPSIS && !saveAsDraft) {
      if (!state.caseFileDetail.synopsis.length) {
        return {
          ...state,
          valid: false,
          errorMessage: "Synopsis is empty",
        };
      }

      const { tempList: synopsis, isValid,errorMessageList ,synopsisErrIndex} = validateSynopsis(
        state.caseFileDetail.synopsis
      );

      const caseFileDetail = {
        ...state.caseFileDetail,
        
        synopsis,
      };

      return {
        ...state,
        caseFileDetail,
        valid: isValid,
        synopsisExpanded:synopsisErrIndex.length?synopsisErrIndex[0]:state.synopsisExpanded,
        errorMessage: errorMessageList.length?errorMessageList[0]:"",
      };
    }

    if (currentSection === DETAILED_FACTS && !saveAsDraft) {
      if (!state.caseFileDetail.detailedFacts.factsDetail?.length) {
        return {
          ...state,
          valid: false,
          errorMessage: "Please fill in the Detailed Facts before proceeding!",
        };
      }
    }
    if (currentSection === GROUNDS_REASONING && !saveAsDraft) {
      if (!state.caseFileDetail.grounds?.length) {
        return {
          ...state,
          valid: false,
          errorMessage: "Grounds is empty",
        };
      }

      const { tempList: grounds, isValid,errorMessageList } = validateGrounds(
        state.caseFileDetail.grounds
      );

      const caseFileDetail = {
        ...state.caseFileDetail,
        grounds,
      };

      return {
        ...state,
        caseFileDetail,
        valid: isValid,
        errorMessage: errorMessageList.length?errorMessageList[0]:"",
      };
    }
    if (currentSection === PRAYERS && !saveAsDraft) {
      if (!state.caseFileDetail.prayers.length) {
        return {
          ...state,
          valid: false,
          errorMessage: "Prayers is empty",
        };
      }

      const { tempList: prayers, isValid,errorMessageList } = validatePrayers(
        state.caseFileDetail.prayers
      );

      const caseFileDetail = {
        ...state.caseFileDetail,
        prayers,
      };

      return {
        ...state,
        caseFileDetail,
        valid: isValid,
        errorMessage: errorMessageList.length?errorMessageList[0]:""
      };
    }

    if (
      currentSection === REPRESENTATION_TYPE &&
      state.caseFile.representationType === LEGAL_PROFESSIONAL &&
      !saveAsDraft
    ) {
      const { tempCaseFile: caseFile, isValid,errorMessageList } = validateCaseFile(
        state.caseFile
      );
      return {
        ...state,
        caseFile,
        valid: isValid,
        errorMessage: errorMessageList.length?errorMessageList[0]:"",
      };
    } else {
      return {
        ...state,
        valid: true,
      };
    }
  } else {
    return {
      ...state,
      valid: true,
    };
  }
};
