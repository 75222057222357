import React, { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import { Respondent } from "../../../../model/fileCase";
import { Box } from "@mui/material";
import CardRole from "../../../../components/common/CardRole";

import IndividualIcon from "../../../../assets/Individual.png";
import OrganizationIcon from "../../../../assets/Organization.png";
import { MuiTextFieldBorderStyle } from "../../../../util/mui-helper";
import {
  DRAFT,
  NEW_CASE,
  RESPONDENT_TYPE_INDIVIDUAL,
  RESPONDENT_TYPE_ORGANIZATION,
} from "../../../../constants/case-constants";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  editRespondent,
  updateRespondent,
} from "../../../../redux/slice/caseSlice";
import FileService from "../../../../network/FileService";

interface RespondantEditBoxProps {
  respondant: Respondent;

  index: number;
}

const RespondentRow: React.FC<RespondantEditBoxProps> = ({
  respondant,

  index,
}) => {
  const roles = [
    {
      label: "Individual",
      value: RESPONDENT_TYPE_INDIVIDUAL,
      imgUrl: IndividualIcon,
    },
    {
      label: "Organization",
      value: RESPONDENT_TYPE_ORGANIZATION,
      imgUrl: OrganizationIcon,
    },
  ];

  const dispatch: AppDispatch = useDispatch();


  const { caseFile } = useSelector((state: RootState) => state.case);

  const onRoleChange = (value: string) => {
    dispatch(editRespondent({ index, value, key: "respondentType" }));
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name: key, value } = e.target;
    if (key === "email") {
      value = value.toLowerCase();
    }
    if (key === "mobileNo") {
      value = value.replace(/\D/g, "");
    }
    dispatch(editRespondent({ index, value, key }));
    dispatch(updateRespondent({ index, key }));
  };

  const uploadCaseFile = async (file: File) => {
    try {
      if (!file) {
        throw Error("File list is null");
      }

      const args: any = [
        caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
        caseFile.uniqueId,
      ];

      const { uploadedFileName } =
        (await FileService.caseFileUpload(file, args)) as any;

      return uploadedFileName;
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    fileInfo: any
  ) => {
    e.preventDefault();

    let fileList: FileList | null;

    if ("dataTransfer" in e) {
      fileList = e.dataTransfer.files;
    } else {
      fileList = e.target.files;
    }

    if (fileList && fileList.length > 0) {
      const key = fileInfo?.key;

      const value = await uploadCaseFile(fileList[0]);
      dispatch(editRespondent({ index, value, key }));
    } else {
      throw new Error("File list is null");
    }
  };
  const handleRemoveFile = async (key: any) => {
    dispatch(editRespondent({ index, value: "", key }));
    dispatch(updateRespondent({ index, key }));
  };

  return (
    <div className="d-flex h-100 flex-column">
      <div className="d-flex gap-2 justify-content-center">
        {roles.map((role, index) => (
          <CardRole
            key={index}
            imgUrl={role.imgUrl}
            name={role.label}
            active={respondant.respondentType === role.value}
            onClick={() => {
              onRoleChange(role.value);
            }}
          />
        ))}
      </div>

      <div className="d-flex justify-content-center h-100 mt-2">
        <Box className="d-flex flex-column gap-2 px-4 py-2" width={400}>
          <TextField
            error={!!respondant.errors?.name}
            className="w-100 fs-sm-lg-light"
            type="text"
            name="name"
            label={respondant.respondentType === roles[0].value ?"Name":"Organization Name"}
            helperText={respondant.errors?.name}
            variant="standard"
            value={respondant.name}
            onChange={onInputChange}
            sx={MuiTextFieldBorderStyle}
          />

          {/* Apply validation if Organization role is selected */}

          {respondant.respondentType === roles[1].value ? (
            <TextField
              error={!!respondant.errors?.orgRepresentative}
              className="w-100 fs-sm-lg-light"
              type="text"
              label="Authorised Representative"
              name="orgRepresentative"
              value={respondant.orgRepresentative}
              helperText={respondant.errors?.orgRepresentative}
              variant="standard"
              onChange={onInputChange}
              sx={MuiTextFieldBorderStyle}
            />
          ) : null}

          {respondant.respondentType === roles[1].value ? (
            <TextField
              error={!!respondant.errors?.designation}
              className="w-100 fs-sm-lg-light"
              type="text"
              label="Designation"
              name="designation"
              value={respondant?.designation}
              helperText={respondant.errors?.designation}
              variant="standard"
              onChange={onInputChange}
              sx={MuiTextFieldBorderStyle}
            />
          ) : null}

          <TextField
            error={!!respondant.errors?.mobileNo}
            className="w-100 fs-sm-lg-light"
            type="text"
            label="Mobile"
            name="mobileNo"
            inputProps={{
              minLength: 10,
              maxLength: 10,
            }}
            variant="standard"
            value={respondant.mobileNo}
            helperText={respondant.errors?.mobileNo}
            onChange={onInputChange}
            sx={MuiTextFieldBorderStyle}
          />
          <TextField
            error={!!respondant.errors?.email}
            className="w-100 fs-sm-lg-light"
            type="text"
            onChange={onInputChange}
            name="email"
            label="Email"
            helperText={respondant.errors?.email}
            value={respondant.email}
            variant="standard"
            sx={MuiTextFieldBorderStyle}
          />
          <TextField
            error={!!respondant.errors?.address}
            className="w-100 fs-sm-lg-light"
            type="text"
            value={respondant.address}
            label={respondant.respondentType === roles[0].value ?"Address for Communication":"Registered Office Address"}
            name="address"
            variant="standard"
            helperText={respondant.errors?.address}
            onChange={onInputChange}
            sx={MuiTextFieldBorderStyle}
          />

          {/* {respondant.respondentType === roles[1].value ? (
            <>
              <p className="fs-normal-semibold text-primary p-0 m-0">
                Authorisation Letter
              </p>
              <FileUpload
                name="authorizationLetter"
                fileName={respondant.authorizationLetter}
                addFile={handleFileInputChange}
                removeFile={handleRemoveFile}
                hintMessage={
                  respondant.errors?.authorizationLetter
                    ? respondant.errors?.authorizationLetter
                    : ""
                }
              />
            </>
          ) : null} */}

          {/* {respondant.respondentType === roles[1].value ? (
            <>
              <p className="fs-normal-semibold text-primary p-0 m-0">
                AR Aadhaar
              </p>
              <FileUpload
                name="eAadhaar"
                fileName={respondant.eAadhaar}
                addFile={handleFileInputChange}
                removeFile={handleRemoveFile}
                hintMessage={
                  respondant.errors?.eAadhaar ? respondant.errors?.eAadhaar : ""
                }
              />
            </>
          ) : null} */}
        </Box>
      </div>
    </div>
  );
};

export default RespondentRow;
