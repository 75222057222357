import { Box, Button, Divider, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReportIcon from "@mui/icons-material/Report";

import documentIcon from "../assets/icon/insert_drive_file_24px.png";
import { OrganizationDetail } from "../model/OrganizationDetail";
import {
  setInviteEmail,
  setInviteEmailFormError,
} from "../redux/slice/userSlice";
import { AppDispatch, RootState } from "../redux/store";
import { MuiOutlinedInputStyle } from "../util/mui-helper";
import { validateEmail } from "../util/validation";
import FileService from "../network/FileService";
import {
  KYC_PENDING,
  KYC_REJECTED,
  KYC_VERIFIED,
  ROLE_LAW_FIRM_ADMIN,
  ROLE_ORG_ADMIN,
} from "../constants/nav-constants";

interface UserProps {
  email: string;
}

interface OrganizationDetailsProps {
  organizationDetails: OrganizationDetail;
  inviteUser: (payload: any, userList: any) => void;
}

const OrganizationDetails = ({
  organizationDetails,
  inviteUser,
}: OrganizationDetailsProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    profileDetail,
    inviteMemberLoading,
    userList,
    inviteEmail,
    inviteEmailFormError,
  } = useSelector((state: RootState) => state.profileDetail);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setInviteEmailFormError(""));
    dispatch(setInviteEmail(event.target.value));
  };

  const onClickInviteUser = () => {
    const inValidEmail = validateEmail(inviteEmail);

    if (inviteEmail.trim() === "" || inValidEmail) {
      dispatch(setInviteEmailFormError("Invalid email"));
      return;
    }

    let userObj = { email: inviteEmail };

    let tempUserList: any = [...userList];
    if (tempUserList?.length) {
      tempUserList.push({ ...userObj });
    } else {
      tempUserList = [userObj];
    }

    inviteUser(userObj, tempUserList);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClickInviteUser();
    }
  };

  const downloadFile = async (fileName: string) => {
    try {
      const res: ArrayBuffer = (await FileService.downloadUserFile([
        profileDetail?.personalDetail?.uniqueId,
        'kyc',
        fileName,
      ])) as ArrayBuffer;
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };

  return (
    <Box className="my-3">
      <div className="d-flex justify-content-between my-2 p-2 br-5 bg-light-pink">
        <div>
          <div className="fs-sm-light text-grey">
            {profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN &&
              "Organization Details"}
            {profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN &&
              "Law Firm Details"}
          </div>
          <span className="fs-normal-light">
            {organizationDetails?.name ? organizationDetails?.name : "N/A"}
          </span>
        </div>
        {/* <div>
          <div className="fs-sm-light text-grey">GSTIN</div>
          <span className="fs-normal-light">{organizationDetails?.GSTIn}</span>
        </div> */}
      </div>
      <div className="outer-border br-5 my-4">
        <span className="fs-sm-lg-semibold label">
          {profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN &&
            "Organization "}
          {profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN &&
            "Law Firm "}
          authorized representative
        </span>

        <div className="d-flex gap-4 inner-content">
          <div className="d-flex flex-column gap-3 w-100">
            <div>
              <div className="fs-sm-light text-grey  my-1">
                Representative Name
              </div>
              <span className="fs-normal-light">
                {organizationDetails?.orgRepName
                  ? organizationDetails?.orgRepName
                  : "N/A"}
              </span>
            </div>
            <div>
              <div className="fs-sm-light text-grey  my-1">Designation</div>
              <span className="fs-normal-light">
                {organizationDetails?.orgRepDesignation
                  ? organizationDetails?.orgRepDesignation
                  : "N/A"}
              </span>
            </div>
            <div>
              <div className="fs-sm-light text-grey my-1">Address</div>
              <span className="fs-normal-light">
                {organizationDetails?.address
                  ? organizationDetails?.address
                  : "N/A"}
              </span>
            </div>
            <div>
              <div className="fs-sm-light text-grey  my-1">Mobile</div>
              <span className="fs-normal-light">
                {organizationDetails?.orgRepMobileNo
                  ? organizationDetails?.orgRepMobileNo
                  : "N/A"}
              </span>
            </div>
            <div>
              <div className="fs-sm-light text-grey  my-1">Email</div>
              <span className="fs-normal-light">
                {organizationDetails?.orgRepEmail
                  ? organizationDetails?.orgRepEmail
                  : "N/A"}
              </span>
            </div>
          </div>

          <div className="d-flex gap-4 flex-column w-100">
            {profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN &&
              [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(
                profileDetail?.kycDetail?.kycStatus
              ) && (
                <div className="position-relative w-100 mb-3">
                  <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                    <div className="text-grey fs-sm-light text-center">
                      Certificate Of Incorporation
                    </div>
                    <div className="text-center bg-white py-3 px-5">
                      <img src={documentIcon} alt="Documents" />
                    </div>
                    {profileDetail?.kycDetail?.kycData[0]?.status ===
                      KYC_REJECTED && (
                      <div className="d-flex gap-2 align-items-center">
                        <ReportIcon fontSize="small" color="error" />
                        <p className="fs-sm-med text-danger p-0 m-0">
                          {profileDetail?.kycDetail?.kycData[0]?.remark}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="position-absolute top-100 end-0">
                    <Button
                      className={`text-primary fs-sm-med ${
                        (!profileDetail?.kycDetail?.kycData[0]?.attachment || !profileDetail?.kycDetail?.kycData[0]?.attachment?.trim()) && "d-none"
                      }`}
                      variant="text"
                      onClick={() =>
                        downloadFile(
                          profileDetail?.kycDetail?.kycData[0]?.attachment
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              )}

            {profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN &&
              [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(
                profileDetail?.kycDetail?.kycStatus
              ) && (
                <div className="position-relative w-100 mb-3">
                  <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                    <div className="text-grey fs-sm-light text-center">
                      Letter Of Authorisation
                    </div>
                    <div className="text-center bg-white py-3 px-5">
                      <img src={documentIcon} alt="Documents" />
                    </div>
                    {profileDetail?.kycDetail?.kycData[1]?.status ===
                      KYC_REJECTED && (
                      <div className="d-flex gap-2 align-items-center">
                        <ReportIcon fontSize="small" color="error" />
                        <p className="fs-sm-med text-danger p-0 m-0">
                          {profileDetail?.kycDetail?.kycData[1]?.remark}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="position-absolute top-100 end-0">
                    <Button
                      className={`text-primary fs-sm-med ${
                        (!profileDetail?.kycDetail?.kycData[1]?.attachment || !profileDetail?.kycDetail?.kycData[1]?.attachment?.trim()) && "d-none"
                      }`}
                      variant="text"
                      onClick={() =>
                        downloadFile(
                          profileDetail?.kycDetail?.kycData[1]?.attachment
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              )}

            {profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN &&
              [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(
                profileDetail?.kycDetail?.kycStatus
              ) && (
                <div className="position-relative w-100 mb-3">
                  <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                    <div className="text-grey fs-sm-light text-center">
                      Authorised Person's ID proof
                    </div>
                    <div className="text-center bg-white py-3 px-5">
                      <img src={documentIcon} alt="Documents" />
                    </div>
                    {profileDetail?.kycDetail?.kycData[2]?.status ===
                      KYC_REJECTED && (
                      <div className="d-flex gap-2 align-items-center">
                        <ReportIcon fontSize="small" color="error" />
                        <p className="fs-sm-med text-danger p-0 m-0">
                          {profileDetail?.kycDetail?.kycData[2]?.remark}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="position-absolute top-100 end-0">
                    <Button
                      className={`text-primary fs-sm-med ${
                        (!profileDetail?.kycDetail?.kycData[2]?.attachment || !profileDetail?.kycDetail?.kycData[2]?.attachment?.trim()) && "d-none"
                      }`}
                      variant="text"
                      onClick={() =>
                        downloadFile(
                          profileDetail?.kycDetail?.kycData[2]?.attachment
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              )}

            {profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN &&
              [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(
                profileDetail?.kycDetail?.kycStatus
              ) && (
                <div className="position-relative w-100 mb-3">
                  <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                    <div className="text-grey fs-sm-light text-center">
                      BCI Enrollment Number /{" "}
                      {profileDetail?.kycDetail?.kycData[0]?.fieldValue ||
                        "N/A"}
                    </div>
                    <div className="text-center bg-white py-3 px-5">
                      <img src={documentIcon} alt="Documents" />
                    </div>
                    {profileDetail?.kycDetail?.kycData[0]?.status ===
                      KYC_REJECTED && (
                      <div className="d-flex gap-2 align-items-center">
                        <ReportIcon fontSize="small" color="error" />
                        <p className="fs-sm-med text-danger p-0 m-0">
                          {profileDetail?.kycDetail?.kycData[0]?.remark}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="position-absolute top-100 end-0">
                    <Button
                      className={`text-primary fs-sm-med ${
                        (!profileDetail?.kycDetail?.kycData[0]?.attachment || !profileDetail?.kycDetail?.kycData[0]?.attachment?.trim()) && "d-none"
                      }`}
                      variant="text"
                      onClick={() =>
                        downloadFile(
                          profileDetail?.kycDetail?.kycData[0]?.attachment
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              )}

            {profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN &&
              [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(
                profileDetail?.kycDetail?.kycStatus
              ) && (
                <div className="position-relative w-100 mb-3">
                  <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                    <div className="text-grey fs-sm-light text-center">
                      GST /{" "}
                      {profileDetail?.kycDetail?.kycData[1]?.fieldValue ||
                        "N/A"}
                    </div>
                    <div className="text-center bg-white py-3 px-5">
                      <img src={documentIcon} alt="Documents" />
                    </div>
                    {profileDetail?.kycDetail?.kycData[1]?.status ===
                      KYC_REJECTED && (
                      <div className="d-flex gap-2 align-items-center">
                        <ReportIcon fontSize="small" color="error" />
                        <p className="fs-sm-med text-danger p-0 m-0">
                          {profileDetail?.kycDetail?.kycData[1]?.remark}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="position-absolute top-100 end-0">
                    <Button
                      className={`text-primary fs-sm-med ${
                        (!profileDetail?.kycDetail?.kycData[1]?.attachment || !profileDetail?.kycDetail?.kycData[1]?.attachment?.trim()) && "d-none"
                      }`}
                      variant="text"
                      onClick={() =>
                        downloadFile(
                          profileDetail?.kycDetail?.kycData[1]?.attachment
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              )}

            {profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN &&
              [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(
                profileDetail?.kycDetail?.kycStatus
              ) && (
                <div className="position-relative w-100 mb-3">
                  <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                    <div className="text-grey fs-sm-light text-center">
                      PAN /{" "}
                      {profileDetail?.kycDetail?.kycData[2]?.fieldValue ||
                        "N/A"}
                    </div>
                    <div className="text-center bg-white py-3 px-5">
                      <img src={documentIcon} alt="Documents" />
                    </div>
                    {profileDetail?.kycDetail?.kycData[2]?.status ===
                      KYC_REJECTED && (
                      <div className="d-flex gap-2 align-items-center">
                        <ReportIcon fontSize="small" color="error" />
                        <p className="fs-sm-med text-danger p-0 m-0">
                          {profileDetail?.kycDetail?.kycData[2]?.remark}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="position-absolute top-100 end-0">
                    <Button
                      className={`text-primary fs-sm-med ${
                        (!profileDetail?.kycDetail?.kycData[2]?.attachment || !profileDetail?.kycDetail?.kycData[2]?.attachment?.trim()) && "d-none"
                      }`}
                      variant="text"
                      onClick={() =>
                        downloadFile(
                          profileDetail?.kycDetail?.kycData[2]?.attachment
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </div>
        {profileDetail?.kycDetail?.kycStatus === KYC_REJECTED && (
          <div className="d-flex gap-2 align-items-center p-2">
            <ReportIcon fontSize="small" color="error" />
            <p className="fs-sm-med text-danger p-0 m-0">
              Your KYC request has been rejected.
            </p>
          </div>
        )}
      </div>
      <Box>
        <div className="text-primary heading-medium fs-sm-lg-semibold mt-3">
          Invite User
        </div>
        <Divider className="border-1 border-black" />
        <div className=" my-3 gap-2">
          <div>
            <TextField
              error={inviteEmailFormError ? true : false}
              type="text"
              label={"Email"}
              value={inviteEmail}
              sx={{ ...MuiOutlinedInputStyle, width: "100%" }}
              onChange={handleChange}
              helperText={
                inviteEmailFormError ? "Please enter valid email" : ""
              }
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="mt-3 text-end">
            <Button
              disabled={inviteMemberLoading}
              className="fs-normal-med"
              sx={{ textTransform: "none" }}
              variant="contained"
              onClick={onClickInviteUser}
            >
              Invite User
            </Button>
          </div>
        </div>

        <div>
          <Box className="d-flex flex-column pb-4 gap-3 overflow-auto  rounded-3">
            {userList?.length
              ? userList.map((user: any, index: number) => (
                  <div
                    key={index}
                    className="p-2 d-flex justify-content-between gap-3 bg-light-white d-flex align-items-center shadow-sm"
                  >
                    <div className="d-flex gap-2">
                      <Box
                        className="rounded-circle d-flex align-items-center fs-sm-lg-semibold bg-light-blue1 justify-content-center"
                        width={40}
                        height={40}
                      >
                        {user.email[0].toUpperCase()}
                      </Box>
                      <div>
                        <div className="text-primary fw-bold">
                          user {index + 1}
                        </div>
                        <div className="fs-normal-light text-grey">
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default OrganizationDetails;
